import Cookies from "js-cookie";
import {ApiServiceClass} from "./api-service";

// Custom hook to check cookies and return data synchronously
const GetCookies = () => {
	// Synchronously fetch cookies
	const loginDataCookie = Cookies.get("reward-space-login-data");
	const companyDataCookie = Cookies.get("reward-space-company-data");

	if (!loginDataCookie || !companyDataCookie) {
		ApiServiceClass.logout("Session expired, please login again", "No cookies found");
		return null; // Return null to indicate cookies are not ready
	}
	const loginData = JSON.parse(loginDataCookie);
	const companyData = JSON.parse(companyDataCookie);

	//console.log("Fetched cookies: ", { loginData, companyData });
	return {
		loginData: loginData,
		companyData: companyData,
	};
};

export default GetCookies;