import {Link, useRouteError} from "react-router-dom";
import {ReactComponent as RocketIcon} from "../tools/svgs/rocket.svg";

export default function ErrorPage() {
	const error = useRouteError();
	console.error(error);

	const styles = {
		container: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			height: "100vh",
			textAlign: "center"
		},
		icon: {
			width: "200px",
			height: "200px"
		},
		title: {
			fontSize: "30px",
			fontWeight: "bold",
			marginTop: "1rem"
		},
		message: {
			fontSize: "20px",
			marginTop: "0.5rem"
		},
		errorText: {
			fontSize: "20px",
			color: "gray",
			marginTop: "0.25rem"
		},
		link: {
			marginTop: "1rem",
			fontSize: "18px",
			color: "blue",
			textDecoration: "underline"
		}
	};

	return (
		<div id="error-page" style={styles.container}>
			<RocketIcon style={styles.icon}/>
			<div style={styles.title}>Oops!</div>
			<div style={styles.message}>Page not found</div>
			<div style={styles.errorText}>
				<Link to="/home" style={styles.link}>Back to Home</Link>
			</div>
		</div>
	);
}
