import React, {useEffect, useState} from "react";
import Navbar from "./navbar.js";
import {useNavigate} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Getcookies from "../components/getcookies";
import LoadingPage from "./loadingpage";


export default function MarketingVouchers() {
	const cookies = Getcookies();
	const [loading, setLoading] = useState(false);

	if (loading) {
		return <LoadingPage></LoadingPage>
	}

	return (
		<Navbar name="MarketingVouchers">
			<div style={{paddingBottom: "20px"}}></div>
		</Navbar>
	);
}
