import Cookies from 'js-cookie';
import {toast} from "react-toastify";

class ApiServiceClass {
	static baseUrl = process.env.REACT_APP_API_BASE_URL;

	static getKey() {
		try {
			const loginDataCookie = Cookies.get("reward-space-login-data");
			if (loginDataCookie) {
				const loginData = JSON.parse(loginDataCookie);
				return loginData.ApiKey;
			} else {
				ApiServiceClass.logout("Invalid session, please login again", "No API key");
			}
		} catch (error) {}
	}

	static async checkTokenExpired() {
		try {
			const loginDataCookie = Cookies.get("reward-space-login-data");
			const loginData = JSON.parse(loginDataCookie);
			if (loginData !== null) {
				const jwtExpiry = loginData.JwtTokenExpireDateTime;
				const refreshExpiry = loginData.RefreshTokenExpireDateTime;
				//console.log("JWT expiry: ", jwtExpiry, " Refresh expiry: ", refreshExpiry);
				if (jwtExpiry == null || refreshExpiry == null) {
					ApiServiceClass.logout("Invalid session, please login again", "No cookies found");
					return null;
				}
				const jwtExpiryDate = new Date(jwtExpiry);
				const refreshExpiryDate = new Date(refreshExpiry);
				const now = new Date();

				const jwtExpired = now >= jwtExpiryDate;
				const refreshExpired = now >= refreshExpiryDate;

				const jwtDiffInHours = ((jwtExpiryDate - now) / (1000 * 60 * 60)).toFixed(2);  // Divide by 1000 for seconds, 60 for minutes, and 60 for hours
				const refreshDiffInHours = ((refreshExpiryDate - now) / (1000 * 60 * 60)).toFixed(2);  // Same conversion for refresh token
				console.log("JWT token expiry in hours: ", jwtDiffInHours);
				console.log("Refresh token expiry in hours: ", refreshDiffInHours);

				if (refreshExpired) {
					ApiServiceClass.logout("Session expired, please login again", "Refresh token expired");
					return null;
				}
				if (jwtExpired) {
					console.log("JWT token expired, refreshing token");
					const refreshTokenResponse = await ApiServiceClass.RefreshJWTToken();
					if (refreshTokenResponse.status === 200) {
						loginData.JwtTokenExpireDateTime = refreshTokenResponse.body.JwtTokenExpireDateTime;
						loginData.RefreshTokenExpireDateTime = refreshTokenResponse.body.RefreshTokenExpireDateTime;
						Cookies.set('reward-space-login-data', JSON.stringify(loginData), {
							expires: 60,
							secure: true,
							sameSite: 'Strict'
						});
					} else {
						ApiServiceClass.logout("Session expired, please login again", "Refresh token failed");
						return null;
					}
				}
			} else {
				ApiServiceClass.logout("Invalid session, please login again", "No cookies, logging out");
			}
		} catch (error) {}
	}


	static logout(toastMessage, debugMessage, showToast = true) {
		if(showToast) {
			toast.error(toastMessage);
		}
		console.log("Logging out user, reason: " + debugMessage);
		localStorage.removeItem("companyDetails");
		localStorage.removeItem("loginData");
		Cookies.remove('reward-space-login-data');
		Cookies.remove('reward-space-company-data');
		window.location.href = "/login";
	}


	static async sendRequest(
		endpoint,
		requestBody,
		queryParams = {}
	) {
		if (!this.baseUrl) {
			console.error(
				"Base URL is not set. Check environment variable REACT_APP_API_BASE_URL."
			);
			return;
		}
		if (endpoint !== "/RefreshJwtToken") {
			await ApiServiceClass.checkTokenExpired();
		}
		const url = new URL(`${this.baseUrl}${endpoint}`);
		Object.keys(queryParams).forEach((key) =>
			url.searchParams.append(key, queryParams[key])
		);
		const apiUrl = url.toString();
		let defaultHeaders = {
			"Content-Type": "application/json",
		};
		if (endpoint !== "/Login") {
			const apiKey = this.getKey();
			if (!apiKey) {
				console.error("API key is not available.");
				return;
			}
			defaultHeaders = {
				...defaultHeaders,
				"x-functions-key": apiKey,
			};
		}
		console.log("Request: ", apiUrl, " Headers: " + JSON.stringify(defaultHeaders), +" Request Body: ", requestBody);
		try {
			const response = await fetch(apiUrl, {
				method: "POST",
				credentials: "include",
				headers: defaultHeaders,
				body: JSON.stringify(requestBody),
			});

			const responseBody = await response.text();
			if (!response.ok || response.status >= 300) {
				console.error("API Request Failed:", {
					status: response.status,
					statusText: response.statusText,
					url: apiUrl,
					responseText: responseBody,
				});
				return {status: response.status, statusText: response.statusText, url: apiUrl, body: responseBody};
			} else {
				console.log("Response: ", response.status);
				try {
					return {status: response.status, body: JSON.parse(responseBody)};
				} catch (jsonError) {
					return {status: response.status, body: responseBody};
				}
			}
		} catch (error) {
			console.error("Request Error: ", error);
		}
	}


	static async sendRequestWithoutKey(
		endpoint,
		requestBody,
		queryParams = {}
	) {
		if (!this.baseUrl) {
			console.error(
				"Base URL is not set. Check environment variable REACT_APP_API_BASE_URL."
			);
			return;
		}
		const url = new URL(`${this.baseUrl}${endpoint}`);
		const apiUrl = url.toString();
		console.log("Request : ", apiUrl, " Body: ", requestBody);
		try {
			const response = await fetch(apiUrl, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(requestBody),
			});
			const responseBody = await response.text();
			if (!response.ok || response.status >= 300) {
				console.error("API Request Failed:", {
					status: response.status,
					statusText: response.statusText,
					url: apiUrl,
					responseText: responseBody,
				});
				return {status: response.status, statusText: response.statusText, url: url, body: responseBody};
			} else {
				console.log("Response Code: ", response.status, " Body: ", responseBody);
				try {
					return {status: response.status, body: JSON.parse(responseBody)};
				} catch (jsonError) {
					return {status: response.status, body: responseBody};
				}
			}
		} catch (error) {
			console.log(error);
		}
	}

	static async CancelSubscription(requestBody) {
		return await this.sendRequest("/CancelSubscriptionHook", requestBody);
	}

	static async CancelPushNotification(requestBody) {
		return await this.sendRequest(
			"/CancelPushNotification",
			requestBody
		);
	}

	static async ContactUs(requestBody) {
		return await this.sendRequestWithoutKey("/ContactUs", requestBody);
	}

	static async Register(requestBody) {
		return await this.sendRequestWithoutKey("/RegisterCompany", requestBody);
	}

	static async Login(requestBody) {
		return await this.sendRequest("/Login", requestBody);
	}

	static async CreateCheckoutSession(requestBody) {
		return await this.sendRequest("/CreateCheckoutSession", requestBody);
	}

	static async CreatePushNotification(requestBody) {
		return await this.sendRequest(
			"/CreatePushNotification",
			requestBody
		);
	}

	static async CreateStampReward(requestBody) {
		return await this.sendRequest("/CreateStampReward", requestBody);
	}

	static async DeleteAccount(requestBody) {
		return await this.sendRequest(
			"/DeleteRewardSpaceAccount",
			requestBody
		);
	}

	static async DeleteStaffMember(requestBody) {
		return await this.sendRequest("/DeleteStaffMember", requestBody);
	}

	static async EditCompanyDetails(requestBody) {
		return await this.sendRequest("/EditCompanyDetails", requestBody);
	}

	static async EditStaffMember(requestBody) {
		return await this.sendRequest("/EditStaffMember", requestBody);
	}

	static async EditPaymentDetails(requestBody) {
		return await this.sendRequest("/ChangePaymentDetails", requestBody);
	}

	static async EditStampReward(requestBody) {
		return await this.sendRequest("/EditStampReward", requestBody);
	}

	static async ForgotPassword(requestBody) {
		return await this.sendRequest(
			"/ForgotCompanyUserPassword",
			requestBody
		);
	}

	static async GetAvailableFonts() {
		return await this.sendRequest("/GetAvailableFonts", {});
	}

	static async CheckEmailUse(requestBody) {
		return await this.sendRequest("/CheckEmailUse", requestBody);
	}

	static async GetCreatePushNotifCost(requestBody) {
		return await this.sendRequest("/GetCreatePushNotifCost", requestBody);
	}

	static async GetCustomers(requestBody) {
		return await this.sendRequest("/GetCustomers", requestBody);
	}

	static async GetPushNotifications(requestBody) {
		return await this.sendRequest("/GetPushNotifications", requestBody);
	}

	static async GetBasicStatistics(requestBody) {
		return await this.sendRequest("/GetBasicStatistics", requestBody);
	}

	static async GetDashboardInformation(requestBody) {
		return await this.sendRequest("/GetDashboardInformation", requestBody);
	}

	static async GetDashboardGraph(requestBody) {
		return await this.sendRequest("/GetDashboardGraph", requestBody);
	}

	static async GetDetailedGraph(requestBody) {
		return await this.sendRequest("/GetDetailedGraph", requestBody);
	}

	static async GetCompanyDetails(requestBody) {
		return await this.sendRequest("/GetCompanyDetails", requestBody);
	}

	static async GetCompanyBasicTheme(requestBody) {
		return await this.sendRequest("/GetCompanyBasicTheme", requestBody);
	}

	static async GetCompanyMainTheme(requestBody) {
		return await this.sendRequest("/GetCompanyMainTheme", requestBody);
	}

	static async GetMarketingCredits(requestBody) {
		return await this.sendRequest("/GetMarketingCredits", requestBody);
	}

	static async GetRewardDetails(requestBody) {
		return await this.sendRequest("/GetRewardDetails", requestBody);
	}

	static async GetStaffMembers(requestBody) {
		return await this.sendRequest("/GetStaffMembers", requestBody);
	}

	static async GetSubscription(requestBody) {
		return await this.sendRequest("/GetPaymentInformation", requestBody);
	}

	static async ResetCompanyUserPasswordCheckCode(requestBody) {
		return await this.sendRequest(
			"/ResetCompanyUserPasswordCheckCode",
			requestBody
		);
	}

	static async ResetPassword(requestBody) {
		return await this.sendRequest(
			"/ForgotPasswordChangePassword",
			requestBody
		);
	}

	static async SendInviteEmployeeEmail(requestBody) {
		return await this.sendRequest(
			"/SendInviteEmployeeEmail",
			requestBody
		);
	}

	static async SetupCompanyBasicTheme(requestBody) {
		return await this.sendRequest("/SetupCompanyBasicTheme", requestBody);
	}

	static async SetupCompanyMainTheme(requestBody) {
		return await this.sendRequest("/SetupCompanyMainTheme", requestBody);
	}

	static async VerifyCaptcha(requestBody) {
		const apiUrl = `${this.baseUrl}/VerifyCaptcha`;
		try {
			const response = await fetch(apiUrl, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": ApiServiceClass.apiKey,
				},
				body: JSON.stringify(requestBody),
			});
			const text = await response.text();
			return {status: response.status, text};
		} catch (error) {
			console.error("Error:", error);
			throw error;
		}
	}

	static async GetMenuNavigationTheme(requestBody) {
		return await this.sendRequest(
			"/GetNavigationMenuTheme",
			requestBody
		);
	}

	static async SaveMenuNavigationTheme(requestBody) {
		return await this.sendRequest(
			"/ApplyNavigationMenuTheme",
			requestBody
		);
	}

	static async GetLoyaltyCardTheme(requestBody) {
		return await this.sendRequest(
			"/GetLoyaltyCardTheme",
			requestBody
		);
	}

	static async SaveLoyaltyCardTheme(requestBody) {
		return await this.sendRequest(
			"/ApplyLoyaltyCardTheme",
			requestBody
		);
	}

	static async GetCompanyLogo(requestBody) {
		return await this.sendRequest("/GetCompanyLogo", requestBody);
	}

	static async SaveCompanyLogo(requestBody) {
		return await this.sendRequest(
			"/ApplyCompanyLogo",
			requestBody
		);
	}

	static async GetSplashTheme(requestBody) {
		return await this.sendRequest("/GetSplashTheme", requestBody);
	}

	static async SaveSplashTheme(requestBody) {
		return await this.sendRequest(
			"/ApplySplashTheme",
			requestBody
		);
	}

	static async GetFontsTheme(requestBody) {
		return await this.sendRequest("/GetThemeFonts", requestBody);
	}

	static async SaveFontsTheme(requestBody) {
		return await this.sendRequest(
			"/ApplyThemeFonts",
			requestBody
		);
	}

	static async GetButtonsTheme(requestBody) {
		return await this.sendRequest(
			"/GetThemeButtons",
			requestBody
		);
	}

	static async SaveButtonsTheme(requestBody) {
		return await this.sendRequest(
			"/ApplyThemeButtons",
			requestBody
		);
	}

	static async GetColorsTheme(requestBody) {
		return await this.sendRequest(
			"/GetThemeColours",
			requestBody
		);
	}

	static async SaveColorsTheme(requestBody) {
		return await this.sendRequest(
			"/ApplyThemeColours",
			requestBody
		);
	}

	static async GetRewardSchemeTheme(requestBody) {
		return await this.sendRequest(
			"/GetRewardSchemeTheme",
			requestBody
		);
	}

	static async SaveRewardSchemeTheme(requestBody) {
		return await this.sendRequest(
			"/ApplyRewardSchemeTheme",
			requestBody
		);
	}

	static async GetSampleThemes(requestBody) {
		return await this.sendRequest(
			"/GetSampleThemes",
			requestBody
		);
	}

	static async SaveFullTheme(requestBody) {
		return await this.sendRequest("/ApplyFullTheme", requestBody);
	}

	static async GetMiscTheme(requestBody) {
		return await this.sendRequest(
			"/GetMiscellaneousTheme",
			requestBody
		);
	}

	static async SaveMiscTheme(requestBody) {
		return await this.sendRequest(
			"/ApplyMiscellaneousTheme",
			requestBody
		);
	}

	static async GetPublishInfo(requestBody) {
		return await this.sendRequest(
			"/GetPublishInfo",
			requestBody
		);
	}

	static async SavePublishInfo(requestBody) {
		return await this.sendRequest(
			"/ApplyPublishInfo",
			requestBody
		);
	}

	static async RefreshJWTToken(requestBody) {
		return await this.sendRequest(
			"/RefreshJwtToken",
			requestBody
		);
	}
}

export {ApiServiceClass};
