import React, {useState} from "react";
import Navbar from "./navbar";
import {styled} from "@mui/system";
import {Box, Button, Typography} from "@mui/material";

import {prices} from "../components/price-plans";
import PricingCard from "../components/pricing-card";
import useIsMobile from "../components/mobile";
import Checkout from "./checkout";

export default function Upgrade() {
	const [activeIndex, setActiveIndex] = useState(0);
	const isMobile = useIsMobile();
	const [selectedPrice, setSelectedPrice] = useState(null);

	const handleNext = () => {
		setActiveIndex((prevIndex) =>
			prevIndex === prices.length - 1 ? 0 : prevIndex + 1
		);
	};

	const handleProductSelected = (product) => {
		console.log("product selected: ", product);
		setSelectedPrice(product);
	};

	const handlePrevious = () => {
		setActiveIndex((prevIndex) =>
			prevIndex === 0 ? prices.length - 1 : prevIndex - 1
		);
	};

	const getTier = (prices, indent) => {
		if (prices.length === 0) return null;
		const normalizedIndent = (activeIndex + indent) % prices.length;
		const index =
			normalizedIndent >= 0 ? normalizedIndent : prices.length + normalizedIndent;
		return prices[index];
	};

	const ArrowButton = styled(Button)(({dir}) => ({
		width: "50px",
		height: "75px",
		opacity: "75%",
		transition: "opacity 0.3s ease",
		position: "absolute",
		top: "65%",
		transform: `translateY(-160%) translateX(${dir === "right" ? "250px" : "-250px"})`,
		zIndex: 10,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	}));

	if (!prices || !Array.isArray(prices)) {
		console.error("Prices array is not defined or not an array");
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					height: "100vh",
				}}
			>
				<Typography variant="h6" color="error">
					Unable to load pricing information.
				</Typography>
			</div>
		);
	}

	return (
		<Navbar>
			{selectedPrice !== null ? (
				<div style={{
					display: "flex",
					justifyContent: "center",
					width: "100%",
					backgroundColor: "white",
					height: "100%"
				}}>

					<div style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						marginTop: "20px",
						width: "100%",
					}}>
						<div style={{
							width: "53%",
							fontSize: "13px",
							textAlign: "center",
							backgroundColor: "#F4F4F4",
							borderRadius: "10px",
							borderWidth: "4px",
							padding: 8,
							color: "black",
							maxWidth: "600px",
							minWidth: "300px"
						}}>
							You can upgrade, downgrade, or cancel your plan at any time with no hidden fees.
							Changes to your plan take effect immediately, and any adjustments to your billing will be
							reflected in your next payment cycle.
							If you have any questions, please contact us at <a
							href="mailto:support@reward-space.co.uk">support@reward-space.co.uk</a>.
						</div>
						<Button
							type="button"
							onClick={() => setSelectedPrice(null)}
							sx={{
								width: "200px",
								textAlign: "center",
								marginTop: "15px",
							}}
						>
							Change Plan
						</Button>
						<div style={{
							width: "70%",
							padding: 1,
							marginTop: "15px",
							maxWidth: "800px",
							minWidth: "300px",
							paddingBottom: "40px"
						}}>
							<Checkout selectedPrice={selectedPrice}/>
						</div>
					</div>
				</div>
			) : (
				<Box
					sx={{
						width: "100%",
						height: "auto", // Changed from 100vh to auto
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						py: 4, // Padding top and bottom
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: { xs: "column", md: "row" }, // Stack on mobile, row on desktop
							justifyContent: "center",
							alignItems: "center",
							gap: "100px",
							width: "100%",
							maxWidth: "1200px",
							px: 2, // Padding left and right
							marginTop:"5%"
						}}
					>
						<Box sx={{ width: { xs: "100%", sm: "70%", md: "50%" }, maxWidth: "350px", mb: { xs: 3, md: 0 } }}>
							<PricingCard
								tier={getTier(prices, 0)}
								width="390px"
								height="550px"
								handleProductSelected={handleProductSelected}
							/>
						</Box>
						<Box sx={{ width: { xs: "100%", sm: "70%", md: "50%" }, maxWidth: "350px", mb: { xs: 3, md: 0 } }}>
							<PricingCard
								tier={getTier(prices, 1)}
								width="390px"
								height="550px"
								handleProductSelected={handleProductSelected}
							/>
						</Box>
						<Box sx={{ width: { xs: "90%", sm: "70%", md: "50%" }, maxWidth: "350px" }}>
							<PricingCard
								tier={getTier(prices, 2)}
								width="390px"
								height="550px"
								handleProductSelected={handleProductSelected}
							/>
						</Box>
					</Box>
				</Box>
			)}
		</Navbar>
	)
}