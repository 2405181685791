import React, {useState} from 'react';
import {Box, Button, Dialog, DialogTitle, Divider, IconButton, Paper, Step, StepLabel, Stepper} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {ReactComponent as SaveIcon} from "../../../tools/svgs/save.svg";
import {ReactComponent as Navigationicon} from "../../../tools/svgs/navigation.svg";
import {ReactComponent as BrushIcon} from "../../../tools/svgs/brush.svg";
import {ReactComponent as Rocket} from "../../../tools/svgs/rocket.svg";
import {ReactComponent as LayerIcon} from "../../../tools/svgs/12-layers.svg";
import {ReactComponent as GoogleStore} from "../../../tools/svgs/google-play.svg";
import {ReactComponent as IosStore} from "../../../tools/svgs/ios-store.svg";
import navigationIcon from "../resources/navigation.png";

const GettingStarted = ({isOpen, onClose, onStepChange}) => {
	const [activeStep, setActiveStep] = useState(0);
	const steps = ['Welcome', 'Navigation', 'Design Panel', 'Using The Editor', 'Save & Publish'];

	const handleNext = () => {
		const newStep = activeStep + 1;
		setActiveStep(newStep);
		onStepChange(newStep);
	};

	const handleBack = () => {
		const newStep = activeStep - 1;
		setActiveStep(newStep);
		onStepChange(newStep);
	};

	const handleClose = () => {
		setActiveStep(0); // Reset to first step when closing
		onClose();
	};

	const styles = {
		helpButton: {
			height: "45px",
			width: "45px",
			backgroundColor: "#0361fe",
			borderRadius: "14px",
			marginRight: "10px",
		},
		icon: {
			height: "30px",
			width: "30px",
			backgroundColor: "#0361fe",
			borderRadius: "10px",
		},
	};

	const renderStepContent = (step) => {
		switch (step) {
			case 0:
				return (
					<Box>
						<div style={{fontSize: "18px", fontWeight: "bold"}}>
							Welcome to the app designer!
						</div>
						<br/>
						<div>
							This guide will help you get started using the Reward Space app design platform to create
							your own unique mobile app. You will learn how to:
						</div>
						<br/>
						<ul style={{paddingLeft: "25px", listStylePosition: "outside"}}>
							<li style={{marginBottom: "3px"}}>Navigate between different app screens</li>
							<li style={{marginBottom: "3px"}}>Customize colors, fonts, layouts & more</li>
							<li style={{marginBottom: "3px"}}>Preview your changes in real-time</li>
							<li style={{marginBottom: "3px"}}>Save and publish your design</li>
						</ul>
					</Box>

				);

			case 1:
				return (
					<Box>
						<div style={{fontSize: "18px", fontWeight: "bold"}}>
							Navigation
						</div>
						<br/>
						<ul style={{paddingLeft: "20px", listStylePosition: "outside"}}>

							<li style={{marginBottom: "3px"}}>You can navigate your app by selecting a tab on the
								navigation bar at the bottom of the
								phone
							</li>
							<li style={{marginBottom: "3px"}}>To view the splash screen and login screen you can select
								the <IconButton
									style={styles.icon}>
									<img
										src={navigationIcon}
										alt="Navigation Icon"
										style={styles.icon}
									/>
								</IconButton> icon at the bottom left of the screen.
							</li>
							<li style={{marginBottom: "3px"}}>
								When you select a card from the design panel, the app visualizer will
								automatically navigate to the relevant page.
								For example, selecting "Digital Loyalty Card" will take you to the Card tab on the app.
							</li>

						</ul>

					</Box>
				)
					;

			case 2:
				return (
					<Box>
						<div style={{fontSize: "18px", fontWeight: "bold"}}>
							Design Side Panel
						</div>
						<br/>

						<ul style={{paddingLeft: "20px", listStylePosition: "outside"}}>
							<li style={{marginBottom: "3px"}}>The design panel to the right is where you will find all
								the customization for your app.
								Simply click on a card, for example "Buttons"
								and customize the look of your buttons.
							</li>
							<li style={{marginBottom: "3px"}}>Most cards will contain elements that are related to that
								card. Some
								cards such as "Colours" will hold all the colours
								for the whole app.
							</li>
						</ul>

					</Box>
				);

			case 3:
				return (
					<Box>
						<div style={{fontSize: "18px", fontWeight: "bold"}}>
							Using The Editor
						</div>
						<br/>

						<ul style={{paddingLeft: "20px", listStylePosition: "outside"}}>
							<li style={{marginBottom: "3px"}}>Edit your app in the design panel and preview your changes
								live on the app.
							</li>
							<li style={{marginBottom: "3px"}}>Once you are happy with your changes, select the green
								save
								button at the bottom right of the panel before going back.
							</li>
							<li style={{marginBottom: "3px"}}>Reset will revert to your previous settings before saving.
							</li>
						</ul>

					</Box>
				);

			case 4:
				return (
					<Box>
						<div style={{fontSize: "18px", fontWeight: "bold"}}>
							Save & Publish
						</div>
						<br/>

						<ul style={{paddingLeft: "20px", listStylePosition: "outside"}}>
							<li style={{marginBottom: "3px"}}> Be sure to test your
								app on your own device before publishing.
							</li>
							<li style={{marginBottom: "3px"}}>When you've finished your design, visit the Publish Center
								to get your app published.

							</li>
							<li style={{marginBottom: "3px"}}>To preview your app, download the Reward Space customer
								app using the buttons
								below. Once downloaded, open the app and enter the demo code found at the bottom of the
								design panel.
								<div style={{
									display: "flex",
									alignItems: "center",
									gap: "10px", // Adjust spacing between icons
									marginTop: "10px",

								}}>
									<a href="https://play.google.com/store/apps/details?id=co.uk.reward_space.demoapp&pcampaignid=web_share"
									   target="_blank" rel="noopener noreferrer">
										<GoogleStore style={{width: "150px", height: "auto", display: "block"}}/>
									</a>
									<a href="https://apps.apple.com/gb/app/reward-space-customer-app/id6736945229"
									   target="_blank" rel="noopener noreferrer">
										<IosStore style={{width: "150px", height: "auto", display: "block"}}/>
									</a>
								</div>
							</li>


						</ul>

					</Box>
				);
			default:
				return null;
		}
	};

	return (
		<Dialog
			open={isOpen}
			onClose={handleClose}
			maxWidth="md"
			fullWidth
			PaperProps={{
				style: {
					borderRadius: '12px',
					padding: '16px',
					left: "-90px",
					height: 'min(80vh, 650px)', // Set maximum height with viewport units
					display: 'flex',
					flexDirection: 'column'
				}
			}}
		>
			<DialogTitle>
				<Box display="flex" alignItems="center" justifyContent="space-between">
					<Box display="flex" alignItems="center">
						<div>Getting Started Guide</div>
					</Box>
					<IconButton onClick={handleClose} size="medium">
						<CloseIcon/>
					</IconButton>
				</Box>
			</DialogTitle>
			<Divider/>

			<Box sx={{
				display: 'flex',
				flexDirection: 'column',
				flexGrow: 1,
				minHeight: 0 // Important for flex child scrolling
			}}>
				<Box sx={{p: 3, flexShrink: 0}}>
					<Stepper activeStep={activeStep} alternativeLabel>
						{steps.map((label, index) => (
							<Step key={label}>
								<StepLabel>
									<Box
										display="flex"
										whiteSpace="nowrap"
										alignItems="center"
										gap={1}
										sx={{
											minWidth: '120px',
											justifyContent: 'center'
										}}
									>
										<div style={{fontSize: "14px"}}>
											{label}
										</div>
										<div style={{
											width: "34px",
											height: "34px",
											display: "flex",
											alignItems: "center"
										}}>
											{index === 0 && <Rocket style={{width: "100%", height: "100%"}}/>}
											{index === 1 &&
												<Navigationicon style={{width: "100%", height: "100%"}}/>}
											{index === 2 &&
												<LayerIcon style={{width: "100%", height: "100%"}}/>}
											{index === 3 &&
												<BrushIcon style={{width: "100%", height: "100%"}}/>}
											{index === 4 && <SaveIcon style={{width: "80%", height: "80%"}}/>}
										</div>
									</Box>
								</StepLabel>
							</Step>
						))}
					</Stepper>
				</Box>
				<Divider/>
				<Box sx={{
					p: 3,
					flexGrow: 1,
					overflow: 'hidden',
					display: 'flex',
					flexDirection: 'column'
				}}>
					<Paper
						elevation={5}
						sx={{
							p: 2,
							borderRadius: '8px',
							flexGrow: 1,
							overflow: 'auto'
						}}
					>
						{renderStepContent(activeStep)}
					</Paper>
				</Box>

				<Box sx={{
					p: 3,
					pt: 1,
					display: 'flex',
					justifyContent: 'space-between',
					flexShrink: 0
				}}>
					<Button
						onClick={handleBack}
						disabled={activeStep === 0}
						startIcon={<ArrowBackIcon/>}
						variant="outlined"
					>
						Back
					</Button>

					<Button
						variant="contained"
						color="primary"
						onClick={activeStep === steps.length - 1 ? handleClose : handleNext}
						endIcon={activeStep === steps.length - 1 ? <CheckCircleIcon/> : <ArrowForwardIcon/>}
					>
						{activeStep === steps.length - 1 ? 'Finish' : 'Next'}
					</Button>
				</Box>
			</Box>
		</Dialog>
	);
};

export default GettingStarted;