import {Button, Card, CardContent, CardHeader, Divider, Stack,} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {ReactComponent as Icon} from "../tools/icons/subscription-icon.svg";

export const SettingsSubscriptionPanel = ({
											  title,
											  subheader,
											  handleUpgradeClick,
											  handleCancelSubscription,
												SubscriptionType,
											  SubscriptionPlan,
											  hasBeenCancelled,
											  SubscriptionEndDateTime,
											  IsSubscriptionActive,
											  LastInvoiceDate,
										  }) => {
	const moment = require("moment-timezone");
	const navigate = useNavigate();

	const buildSubscriptionString = () => {
		const formattedDate = formatDateAndCheckAfter(SubscriptionEndDateTime);
		const formattedInvoiceDate = formatDate2(LastInvoiceDate);

		if (SubscriptionType === 0) {
			return (
				<div>
					Your Subscription: <strong>Demo</strong>
				</div>
			);
		} else if (!hasBeenCancelled) {
			return (
				<div>
					Your Subscription: <strong>{SubscriptionPlan}</strong>
					<br/>
					Last Invoice Date: <strong>{formattedInvoiceDate}</strong>
				</div>
			);
		} else if (hasBeenCancelled && formattedDate != null) {
			return (
				<div>
					Your Subscription: <strong>{SubscriptionPlan} [Cancelled]</strong>
					<br/>
					Last Invoice Date: <strong>{formattedInvoiceDate}</strong>
					<br/>
					Subscription Ending: <strong>{formattedDate}</strong>
				</div>
			);
		} else if (hasBeenCancelled && SubscriptionType === 0) {
			return (
				<div>
					Your Subscription: <strong>{SubscriptionPlan} (Expired)</strong>
					<br/>
					Last Invoice Date: <strong>{formattedInvoiceDate}</strong>
				</div>
			);
		}
		return <div>test</div>;
	};

	function formatDateAndCheckAfter(dateString) {
		const expiryDate = moment.tz(dateString, "Europe/London");
		//const expiryDate = moment.tz("2000-01-01T00:00:00", "Europe/London");
		const currentDateInUK = moment.tz(new Date(), "Europe/London");
		//console.log("currentDateInUK", currentDateInUK);
		if (expiryDate.isAfter(currentDateInUK)) {
			const day = String(expiryDate.date()).padStart(2, "0");
			const month = String(expiryDate.month() + 1).padStart(2, "0"); // Months are zero-based
			const year = expiryDate.year();
			return `${day}/${month}/${year}`;
		} else {
			return null;
		}
	}

	function formatDate2(dateString) {
		const date = new Date(dateString);
		const day = String(date.getDate()).padStart(2, "0");
		const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
		const year = date.getFullYear();

		return `${day}/${month}/${year}`;
	}

	const handleEditPaymentDetails = () => {
		navigate("/editpayment"); // Change '/edit-payment-details' to the desired path
	};

	return (
		<div style={{padding: "10px"}}>
			<Card sx={{height: "1%"}}>
				<CardHeader
					title={title}
					subheader={subheader}
					avatar={<Icon style={{width: "50px", height: "40px"}}/>}
				/>
				<Divider/>
				<CardContent>
					<Stack
						direction="column"
						spacing={2}
						sx={{maxWidth: "100%", justifyContent: "left"}}
					>
						<div>{buildSubscriptionString()}</div>
						<Stack direction="row" spacing={2} sx={{justifyContent: "left"}}>
							<Button
								type="submit"
								variant="contained"
								sx={{
									width: "250px",
									textAlign: "center",
								}}
								onClick={handleUpgradeClick}
							>
								Change plan
							</Button>
							<Button
								type="submit"
								disabled={hasBeenCancelled || !IsSubscriptionActive} // Disable the button
								variant="contained"
								sx={{
									width: "250px",
									textAlign: "center",
								}}
								onClick={handleEditPaymentDetails}
							>
								Edit payment details
							</Button>
							<Button
								disabled={hasBeenCancelled || !IsSubscriptionActive} // Disable the button
								type="submit"
								variant="contained"
								sx={{
									//mb: 4,
									width: "250px",
									textAlign: "center",
								}}
								onClick={handleCancelSubscription}
							>
								Cancel Subscription
							</Button>
						</Stack>

					</Stack>
				</CardContent>

				<Divider/>
				{/* <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button variant="contained">Update</Button>
        </CardActions> */}
			</Card>
		</div>
	);
};
