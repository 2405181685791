import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle,} from "@mui/material";
import {styled} from "@mui/system";

const CenteredDialogActions = styled(DialogActions)({
	display: "flex",
	justifyContent: "center",
});

const WarningBox = ({isOpen, onYes, onClose}) => {
	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			PaperProps={{style: {borderRadius: "5px", width: "440px"}}}
		>
			<DialogTitle style={{paddingBottom: "5px", textAlign: "center"}}>
				Warning
			</DialogTitle>
			<DialogContent
				style={{
					paddingBottom: "15px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					textAlign: "center", // Ensure text is centered
				}}
			>
				You have unsaved changes. <br></br>
				<br></br> Do you wish you go back without saving?
			</DialogContent>
			<CenteredDialogActions
				style={{
					paddingBottom: "20px",
					paddingTop: "10px",
					alignItems: "center",
					justifyContent: "center",
					gap: "8%",
				}}
			>
				<Button
					onClick={onYes}
					style={{
						width: "30%",
						marginRight: "35px",
						borderRadius: "5px",
						backgroundColor: "#dc3545",
					}}
				>
					Yes
				</Button>
				<Button
					onClick={onClose}
					style={{
						width: "30%",
						borderRadius: "5px",
						backgroundColor: "#28a745",
					}}
				>
					No
				</Button>
			</CenteredDialogActions>
		</Dialog>
	);
};
export default WarningBox;
