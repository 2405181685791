import React, {useCallback} from "react";
import {loadStripe} from "@stripe/stripe-js";
import {EmbeddedCheckout, EmbeddedCheckoutProvider,} from "@stripe/react-stripe-js";
import Navbar from "./navbar.js";
import {ApiServiceClass} from "../components/api-service.js";

const stripePromise = loadStripe(
	"pk_test_51Oc7yxKYFd22hVPvVglZgEhzzn3OB7A5K8cPrsV051daN4dxG6rIkNdGI32quSINOJ280o0VXn7iLQ5hZm7tXhmu00kO3f7KHF"
);
const CreateCheckoutSession = async () => {
	try {
		const requestBody = {
			CustomerId: "cus_QMylUXbFzJZA8p",
			SubscriptionId: "sub_1PWEo9KYFd22hVPvdoKLnboI",
		};

		const response = await ApiServiceClass.EditPaymentDetails(requestBody);
		console.log("Checkout Session Data:", response.body);

		if (!response.body.ClientSecret) {
			throw new Error("FAIL 2: Missing clientSecret in response data");
		}
		return response.body;
	} catch (error) {
		console.error("FAIL 4", error);
		throw error;
	}
};

const CheckoutForm = () => {
	const fetchClientSecret = useCallback(async () => {
		try {
			const sessionData = await CreateCheckoutSession();
			return sessionData.ClientSecret;
		} catch (error) {
			console.error("Error fetching client secret:", error);
			throw error; // Rethrow the error to be caught by the caller
		}
	}, []);

	const options = {fetchClientSecret};

	return (
		<div id="checkout" style={{width: "70%"}}>
			<EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
				<EmbeddedCheckout/>
			</EmbeddedCheckoutProvider>
		</div>
	);
};

const EditPayment = () => {
	return (
		<Navbar>
			<div style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
				backgroundColor: "white",
				width: "100%"
			}}>
				<div style={{
					width: "80%",
					marginTop: "1%",
					display: "flex",
					justifyContent: "center"
				}}>
					<CheckoutForm/>
				</div>
			</div>
		</Navbar>

	);
};

export default EditPayment;
