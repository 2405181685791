import React from "react";
import {Button} from "@mui/material";
import Base64Image from "../cards/sharedcomponents/Base64Image";
import battery from "../resources/ios-battery-full-3.svg";
import wifi from "../resources/ios-wifi-2.svg";

const LoginContent = ({designManager, setActiveTab}) => {
	const handleSignInClick = () => {
		setActiveTab("home"); // Redirect to the 'home' tab after sign in
	};
	return (
		<div
			style={{
				width: "100%",
				height: "650px",
				backgroundColor: designManager.getFieldValue("primaryBackgroundColor"),
				color: designManager.getFieldValue("primaryFontColor"),
				fontFamily: designManager.getFieldValue("primaryThemeFont"),
				fontSize: designManager.getFieldValue("primaryThemeFontSize"),
			}}
		>
			<div style={{position: "relative"}}>
				<img
					src={wifi}
					alt="wifi"
					style={{
						width: "18px",
						height: "18px",
						position: "absolute",
						top: "6px",
						right: "55px",
					}}
				/>
				<img
					src={battery}
					alt="battery"
					style={{
						width: "20px",
						height: "20px",
						position: "absolute",
						top: "6px",
						right: "28px",
					}}
				/>
			</div>
			<div
				style={{
					draggable: "false",
					width: "100%",
					height: "68px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					backgroundColor: designManager.getFieldValue(
						"appHeaderBackgroundColor"
					),
					marginTop: "5px",
					zIndex: 2,
				}}
			>
				<div style={{
					fontSize: designManager.getFieldValue("appHeaderFontSize") - 1,
					marginTop: "33px",
					fontFamily: designManager.getFieldValue("appHeaderFont"),
					color: designManager.getFieldValue("appHeaderFontColor"),
				}}>Login
				</div>
			</div>
			<div
				style={{
					width: "100%",
					marginTop: "25%",
					display: "flex", // Flexbox for centering
					flexDirection: "column", // Arrange content vertically
					alignItems: "center", // Center horizontally
					justifyContent: "center", // Center vertically
				}}
			>
				<Base64Image
					image={designManager.getFieldValue("companyLogo")}
					fileType={designManager.getFieldValue("companyLogoFileType")}
					style={{
						width: "95%",
						maxWidth: "240px",
						height: "auto", // Ensures the aspect ratio is maintained
						maxHeight: "110px", // Limit the maximum height
						objectFit: "contain", // Ensures the image scales properly within the bounds
						marginBottom: "40px",
						marginTop: "0px",
					}}
				/>

				{/* Email Label */}
				<div
					style={{
						fontSize: designManager.getFieldValue("primaryThemeFontSize") - 1,
						color: designManager.getFieldValue("primaryFontColor"),
						alignSelf: "flex-start",
						paddingLeft: "40px",
						paddingBottom: "5px",
						fontFamily: designManager.getFieldValue("primaryThemeFont"),
					}}
				>
					Email Address
				</div>

				{/* Email Input */}
				<input
					type="text"
					placeholder=""
					autoComplete="new-password"
					style={{
						width: "210px",
						height: "35px",
						padding: "10px",
						fontSize: "16px",
						borderRadius: "5px",
						border: "1px solid #ccc",
						marginBottom: "15px", // Added margin for spacing
					}}
				/>

				{/* Password Label */}
				<div
					style={{
						fontSize: designManager.getFieldValue("primaryThemeFontSize") - 1,
						color: designManager.getFieldValue("primaryFontColor"),
						alignSelf: "flex-start",
						paddingLeft: "40px",
						marginTop: "1%",
						paddingBottom: "5px",
						fontFamily: designManager.getFieldValue("primaryThemeFont"),
					}}
				>
					Password
				</div>

				{/* Password Input */}
				<input
					type="password"
					placeholder=""
					autoComplete="new-password"
					style={{
						width: "210px",
						height: "35px",
						padding: "10px",
						fontSize: "16px",
						borderRadius: "5px",
						border: "1px solid #ccc",
						marginBottom: "15px", // Added margin for spacing
					}}
				/>

				{/* Sign In Button */}
				<Button
					style={{
						height: "30px",
						backgroundColor: designManager.getFieldValue(
							"buttonBackgroundColor"
						),
						color: designManager.getFieldValue("buttonFontColor"),
						width: "210px",
						borderRadius: "15px",
						marginTop: "5px",
						fontSize: designManager.getFieldValue("buttonsFontSize") - 1,
						fontFamily: designManager.getFieldValue("buttonsFont"),
					}}
					onClick={handleSignInClick}
				>
					Sign In
				</Button>

				{/* Register Link */}
				<div
					style={{
						fontSize: designManager.getFieldValue("primaryThemeFontSize") - 3,
						color: designManager.getFieldValue("primaryFontColor"),
						textDecoration: "underline",
						marginTop: "15px",
						fontFamily: designManager.getFieldValue("primaryThemeFont"),
					}}
				>
					Don't have an Account? Register Here!
				</div>
			</div>
		</div>
	);
};

export default LoginContent;
