import React, {useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
//import { ReactComponent as CardIcon } from "./resources/card.svg"; // Importing the SVG as a React component
import QrCodeIcon from "@mui/icons-material/CardGiftcard";
import SettingsIcon from "@mui/icons-material/Settings";
import cursorImage from "../../components/myapp/resources/cursor.png";
import HomeRenderContent from "./phonerendercontent/HomeRenderContent";
import CardRenderContent from "./phonerendercontent/CardRenderContent";
import OffersRenderContent from "./phonerendercontent/OffersRenderContent";
import SettingsRenderContent from "./phonerendercontent/SettingsRenderContent";
import SplashRenderContent from "./phonerendercontent/SplashRenderContent";
import LoginRenderContent from "./phonerendercontent/LoginRenderContent";
import MiscRenderContent from "./phonerendercontent/MiscRenderContent";

const AppDesignRenderer = ({
							   activeTab,
							   setActiveTab,
							   designManager,
							   isLargeScreen,
						   }) => {
	//const [activeTab, setActiveTab] = useState("home");
	const [design, setDesign] = useState(designManager.getCurrentDesign());

	useEffect(() => {
		const updateDesign = () => {
			const currentDesign = designManager.getUnsavedDesign(); // Use unsaved design here
			setDesign(currentDesign);
		};

		// Subscribe to design manager changes
		designManager.addChangeListener(updateDesign);

		// Cleanup listener on unmount
		return () => {
			designManager.removeChangeListener(updateDesign);
		};
	}, [designManager]); // Runs effect when designManager changes

	// Function to render content based on the active tab using components
	const getSelectedTabContent = () => {
		console.log("active tab: ", activeTab);
		switch (activeTab) {
			case "home":
				return <HomeRenderContent designManager={designManager}/>;
			case "card":
				return <CardRenderContent designManager={designManager}/>;
			case "offers":
				return <OffersRenderContent designManager={designManager}/>;
			case "settings":
				return <SettingsRenderContent designManager={designManager}/>;
			case "splash":
				return <SplashRenderContent designManager={designManager}/>;
			case "login":
				return (
					<LoginRenderContent
						designManager={designManager}
						setActiveTab={setActiveTab}
					/>
				);
			case "misc":
				return <MiscRenderContent designManager={designManager}/>;
			default:
				return <div>unknown tab</div>;
		}
	};

	// Function to render the bottom navbar with MUI icons
	const renderNavBar = () => {
		const tabs = [
			{name: "home", icon: <HomeIcon/>},
			{
				name: "card",
				icon: <QrCodeIcon/>,
			},
			{name: "offers", icon: <LocalOfferIcon/>},
			{name: "settings", icon: <SettingsIcon/>},
		];

		return (
			<Box
				sx={{
					height: "60px",
					backgroundColor: designManager.getFieldValue("navBarBackgroundColor"),
					width: "100%",
					position: "absolute",
					bottom: 3,
					left: 0,
					display: "flex",
					justifyContent: "space-around",
					zIndex: "55",
					alignItems: "center",
					borderRadius: "0 0 30px 30px",
					padding: "0px 15px",
				}}
			>
				{tabs.map((tab) => (
					<Box
						key={tab.name}
						onClick={() => setActiveTab(tab.name)}
						sx={{
							display: "flex",
							padding: "8px 15px", // Add padding for larger touch target
							//backgroundColor: "#000000",
							flexDirection: "column",
							alignItems: "center",
							color:
								activeTab === tab.name
									? designManager.getFieldValue("navBarSelectedColor")
									: designManager.getFieldValue("navBarDeselectedColor"),
						}}
					>
						{tab.icon}
						<div
							style={{
								color:
									activeTab === tab.name
										? designManager.getFieldValue("navBarSelectedColor")
										: designManager.getFieldValue("navBarDeselectedColor"),
								fontFamily: designManager.getFieldValue("primaryThemeFont"),
								fontSize: designManager.getFieldValue("primaryThemeFontSize") - 3,
								marginTop: "4px",
							}}
						>
							{tab.name.charAt(0).toUpperCase() + tab.name.slice(1)}
						</div>
					</Box>
				))}
			</Box>
		);
	};

	const ScreenContainer = () => {
		return (
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					borderRadius: isLargeScreen ? "40px" : "30px",
					transform: `translate(-50%, -50%) scale(${
						isLargeScreen ? 1.2 : 0.9
					})`,
					height: isLargeScreen ? "640px" : "620px",
					width: isLargeScreen ? "290px" : "285px",
					overflow: "hidden",
					transition: "all 0.1s ease",
					display: "flex",
					userSelect: "none",
					flexDirection: "column",
					draggable: "false",
					justifyContent: "space-between",
					pointerEvents: "auto", // Ensure pointer events are enabled
					cursor: `url(${cursorImage}) 8 8, auto`, // Set the custom cursor with a hotspot
				}}
			>
				<div
					style={{
						paddingTop: "0%",
					}}
				>
					{getSelectedTabContent()}
				</div>
				{activeTab !== "splash" && activeTab !== "login" && renderNavBar()}
			</div>
		);
	};
	return <ScreenContainer/>;
};

export default AppDesignRenderer;
