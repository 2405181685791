import {ReactComponent as Arcade_Joystick_Wired} from "./wired/1472-apple-arcade-joistick.svg";
import {ReactComponent as User_Avatar_Wired} from "./wired/44-avatar-user-in-circle.svg";
import {ReactComponent as Balloon_Glass_Wired} from "./wired/2999-balloon-glass.svg";
import {ReactComponent as Barber_Scissors_Wired} from "./wired/1569-barber-scissors.svg";
import {ReactComponent as Basketball_Wired} from "./wired/432-basketball-ball.svg";
import {ReactComponent as Best_Seller_Wired} from "./wired/1368-best-seller.svg";
import {ReactComponent as Beverages_Wired} from "./wired/1910-beverages.svg";
import {ReactComponent as Bicycle_Wired} from "./wired/488-bicycle.svg";
import {ReactComponent as Boombox_Radio_Wired} from "./wired/477-boombox-radio.svg";
import {ReactComponent as Bowl_Of_Soup_Wired} from "./wired/2372-bowl-of-soup.svg";
import {ReactComponent as Brush_Wired} from "./wired/118-brush.svg";
import {ReactComponent as Burger_Wired} from "./wired/1916-burger-alt.svg";
import {ReactComponent as Butterfly_Wired} from "./wired/1151-butterfly.svg";
import {ReactComponent as Casino_Chip_Wired} from "./wired/1454-casino-gambling-chip.svg";
import {ReactComponent as Camera_Wired} from "./wired/61-camera.svg";
import {ReactComponent as Candy_Cane_Wired} from "./wired/2230-candy-cane.svg";
import {ReactComponent as Car_Wired} from "./wired/846-car-site-3.svg";
import {ReactComponent as Chef_Knife_Wired} from "./wired/1606-professional-chef-knife.svg";
import {ReactComponent as Christmas_Tree_Wired} from "./wired/2232-christmas-tree.svg";
import {ReactComponent as Hanger_Wired} from "./wired/1721-cloakroom.svg";
import {ReactComponent as Coffee_Beans_Wired} from "./wired/622-coffee-beans.svg";
import {ReactComponent as Coffee_TakeAway_Wired} from "./wired/238-coffee-take-away.svg";
import {ReactComponent as Coffee_Wired} from "./wired/239-coffee.svg";
import {ReactComponent as Computer_Display_Wired} from "./wired/478-computer-display.svg";
import {ReactComponent as Confetti_Wired} from "./wired/1103-confetti.svg";
import {ReactComponent as Crown_Wired} from "./wired/407-crown-king-lord.svg";
import {ReactComponent as Cupcake_Heart_Wired} from "./wired/3023-cupcake-heart.svg";
import {ReactComponent as Dog_Walking_Wired} from "./wired/1164-dog-walking.svg";
import {ReactComponent as Dispenser_Wired} from "./wired/1625-dispenser.svg";
import {ReactComponent as Earphone_Wired} from "./wired/140-earphone-telephone.svg";
import {ReactComponent as Environment_Eco_Wired} from "./wired/437-environment-eco-care.svg";
import {ReactComponent as Delivery_Truck_Wired} from "./wired/757-delivery.svg";
import {ReactComponent as Dropper_Wired} from "./wired/1433-example.svg";
import {ReactComponent as F1_Car_Wired} from "./wired/890-f-1-car.svg";
import {ReactComponent as Female_Wired} from "./wired/269-avatar-female.svg";
import {ReactComponent as Fish_Wired} from "./wired/522-fish.svg";
import {ReactComponent as Football_Wired} from "./wired/431-football-soccer-ball.svg";
import {ReactComponent as Galaxy_Wired} from "./wired/1874-galaxy.svg";
import {ReactComponent as Gift_Wired} from "./wired/412-gift.svg";
import {ReactComponent as Golf_Wired} from "./wired/1815-golf.svg";
import {ReactComponent as Graduation_Wired} from "./wired/406-study-graduation.svg";
import {ReactComponent as Gym_Weight_Wired} from "./wired/429-weight-gym-fitness.svg";
import {ReactComponent as Hair_Brush_Wired} from "./wired/1591-hair-brush.svg";
import {ReactComponent as Hair_Dryer_Wired} from "./wired/1570-hair-dryer.svg";
import {ReactComponent as Hammer_Wired} from "./wired/1733-hammer.svg";
import {ReactComponent as Hamster_Wired} from "./wired/1188-hamster.svg";
import {ReactComponent as Hand_Gift_Wired} from "./wired/2968-hand-gift.svg";
import {ReactComponent as Lips_Wired} from "./wired/1597-lips.svg";
import {ReactComponent as Love_Heart_Wired} from "./wired/20-love-heart.svg";
import {ReactComponent as Home_Wired} from "./wired/63-home.svg";
import {ReactComponent as Horseshoe_Wired} from "./wired/1456-horseshoe.svg";
import {ReactComponent as Horse_Wired} from "./wired/1169-trotting-horse.svg";
import {ReactComponent as Ice_Cream_Wired} from "./wired/549-ice-cream-scoops.svg";
import {ReactComponent as Jewellery_Wired} from "./wired/944-jewellery.svg";
import {ReactComponent as Kettle_Wired} from "./wired/1756-kettle.svg";
import {ReactComponent as Magic_Wand_Wired} from "./wired/2844-magic-wand.svg";
import {ReactComponent as Makeup_Wired} from "./wired/1598-makeup.svg";
import {ReactComponent as Male_Doctor_Wired} from "./wired/671-male-doctor-care.svg";
import {ReactComponent as Male_Wired} from "./wired/702-beard-barber.svg";
import {ReactComponent as Margarita_Wired} from "./wired/2993-margarita-glass.svg";
import {ReactComponent as Medical_Insurance_Wired} from "./wired/1267-medical-insurancea.svg";
import {ReactComponent as Nail_Polish_Wired} from "./wired/1592-nail-polish.svg";
import {ReactComponent as Paintball_Wired} from "./wired/1900-paintball-gun.svg";
import {ReactComponent as Paste_Plate_Wired} from "./wired/603-plate-pasta.svg";
import {ReactComponent as Paws_Animal_Wired} from "./wired/448-paws-animal.svg";
import {ReactComponent as Pedicure_Wired} from "./wired/1571-pedicure.svg";
import {ReactComponent as Ping_Pong_Wired} from "./wired/1810-ping-pong-table-tennis.svg";
import {ReactComponent as Pizza_Wired} from "./wired/13-pizza.svg";
import {ReactComponent as Raised_Hand_Wired} from "./wired/2527-rised-hand.svg";
import {ReactComponent as Scissors_Wired} from "./wired/114-scissors.svg";
import {ReactComponent as Skateboard_Wired} from "./wired/1785-skateboard.svg";
import {ReactComponent as Skates_Wired} from "./wired/2943-skates.svg";
import {ReactComponent as Smartphone_Wired} from "./wired/2805-smartphone-2.svg";
import {ReactComponent as Spa_Flower_Wired} from "./wired/1574-spa-flower.svg";
import {ReactComponent as Sponge_Wired} from "./wired/1698-sponge.svg";
import {ReactComponent as Sparkles_Wired} from "./wired/2474-sparkles-glitter.svg";
import {ReactComponent as Star_Rating_Wired} from "./wired/237-star-rating.svg";
import {ReactComponent as Stamp_Wired} from "./wired/460-stamp.svg";
import {ReactComponent as Strawberry_Wired} from "./wired/578-strawberry.svg";
import {ReactComponent as Teddy_Bear_Wired} from "./wired/1546-teddy-bear-toy.svg";
import {ReactComponent as Thumbs_Up_Wired} from "./wired/267-like-thumb-up.svg";
import {ReactComponent as Tree_Planting_Wired} from "./wired/1850-tree-planting.svg";
import {ReactComponent as Treble_Clef_Wired} from "./wired/1497-treble-clef.svg";
import {ReactComponent as Truck_Wired} from "./wired/849-truck.svg";
import {ReactComponent as UFO_Wired} from "./wired/492-ufo-aliens.svg";
import {ReactComponent as Umbrella_Wired} from "./wired/422-umbrella.svg";
import {ReactComponent as Watering_Can_Wired} from "./wired/1849-watering-can.svg";

// Lineal imports
import {ReactComponent as Arcade_Joystick_Lineal} from "./lineal/1472-apple-arcade-joistick-lineal.svg";
import {ReactComponent as Balloon_Glass_Lineal} from "./lineal/2999-balloon-glass-lineal.svg";
import {ReactComponent as Barber_Scissors_Lineal} from "./lineal/1569-barber-scissors-lineal.svg";
import {ReactComponent as Basketball_Ball_Lineal} from "./lineal/432-basketball-ball-lineal.svg";
import {ReactComponent as Best_Seller_Lineal} from "./lineal/1368-best-seller-lineal.svg";
import {ReactComponent as Beverages_Lineal} from "./lineal/1910-beverages-lineal.svg";
import {ReactComponent as Bicycle_Lineal} from "./lineal/488-bicycle-lineal.svg";
import {ReactComponent as Boombox_Radio_Lineal} from "./lineal/477-boombox-radio-lineal.svg";
import {ReactComponent as Bowl_Of_Soup_Lineal} from "./lineal/2372-bowl-of-soup-lineal.svg";
import {ReactComponent as Brush_Lineal} from "./lineal/118-brush-lineal.svg";
import {ReactComponent as Burger_Lineal} from "./lineal/1916-burger-alt-lineal.svg";
import {ReactComponent as Butterfly_Lineal} from "./lineal/1151-butterfly-lineal.svg";
import {ReactComponent as Camera_Lineal} from "./lineal/61-camera-lineal.svg";
import {ReactComponent as Candy_Cane_Lineal} from "./lineal/2230-candy-cane-lineal.svg";
import {ReactComponent as Casino_Chip_Lineal} from "./lineal/1454-casino-gambling-chip-lineal.svg";
import {ReactComponent as Car_Lineal} from "./lineal/846-car-site-3-lineal.svg";
import {ReactComponent as Christmas_Tree_Lineal} from "./lineal/2232-christmas-tree-lineal.svg";
import {ReactComponent as Hanger_Lineal} from "./lineal/1721-cloakroom-lineal.svg";
import {ReactComponent as Coffee_Beans_Lineal} from "./lineal/622-coffee-beans-lineal.svg";
import {ReactComponent as Coffee_Lineal} from "./lineal/239-coffee-lineal.svg";
import {ReactComponent as Coffee_To_Go_Lineal} from "./lineal/238-coffee-take-away-lineal.svg";
import {ReactComponent as Computer_Display_Lineal} from "./lineal/478-computer-display-lineal.svg";
import {ReactComponent as Confetti_Lineal} from "./lineal/1103-confetti-lineal.svg";
import {ReactComponent as Crown_King_Lord_Lineal} from "./lineal/407-crown-king-lord-lineal.svg";
import {ReactComponent as Cupcake_Heart_Lineal} from "./lineal/3023-cupcake-heart-lineal.svg";
import {ReactComponent as Dog_Walking_Lineal} from "./lineal/1164-dog-walking-lineal.svg";
import {ReactComponent as Delivery_Truck_Lineal} from "./lineal/757-delivery-lineal.svg";
import {ReactComponent as Dispenser_Lineal} from "./lineal/1625-dispenser-lineal.svg";
import {ReactComponent as Earphone_Lineal} from "./lineal/140-earphone-telephone-lineal.svg";
import {ReactComponent as Environment_Eco_Care_Lineal} from "./lineal/437-environment-eco-care-lineal.svg";
import {ReactComponent as Dropper_Lineal} from "./lineal/1433-example-lineal.svg";
import {ReactComponent as F1_Car_Lineal} from "./lineal/890-f-1-car-lineal.svg";
import {ReactComponent as Female_Lineal} from "./lineal/269-avatar-female-lineal.svg";
import {ReactComponent as Fish_Lineal} from "./lineal/522-fish-lineal.svg";
import {ReactComponent as Football_Lineal} from "./lineal/431-football-soccer-ball-lineal.svg";
import {ReactComponent as Galaxy_Lineal} from "./lineal/1874-galaxy-lineal.svg";
import {ReactComponent as Gift_Lineal} from "./lineal/412-gift-lineal.svg";
import {ReactComponent as Golf_Lineal} from "./lineal/1815-golf-lineal.svg";
import {ReactComponent as Hair_Brush_Lineal} from "./lineal/1591-hair-brush-lineal.svg";
import {ReactComponent as Hair_Dryer_Lineal} from "./lineal/1570-hair-dryer-lineal.svg";
import {ReactComponent as Hammer_Lineal} from "./lineal/1733-hammer-lineal.svg";
import {ReactComponent as Hamster_Lineal} from "./lineal/1188-hamster-lineal.svg";
import {ReactComponent as Hand_Gift_Lineal} from "./lineal/2968-hand-gift-lineal.svg";
import {ReactComponent as Home_Lineal} from "./lineal/63-home-lineal.svg";
import {ReactComponent as Horse_Lineal} from "./lineal/1169-trotting-horse-lineal.svg";
import {ReactComponent as Horseshoe_Lineal} from "./lineal/1456-horseshoe-lineal.svg";
import {ReactComponent as Ice_Cream_Lineal} from "./lineal/549-ice-cream-scoops-lineal.svg";
import {ReactComponent as Jewellery_Lineal} from "./lineal/944-jewellery-lineal.svg";
import {ReactComponent as Kettle_Lineal} from "./lineal/1756-kettle-lineal.svg";
import {ReactComponent as Love_Heart_Lineal} from "./lineal/20-love-heart-lineal.svg";
import {ReactComponent as Lips_Lineal} from "./lineal/1597-lips-lineal.svg";
import {ReactComponent as Magic_Wand_Lineal} from "./lineal/2844-magic-wand-lineal.svg";
import {ReactComponent as Makeup_Lineal} from "./lineal/1598-makeup-lineal.svg";
import {ReactComponent as Male_Doctor_Lineal} from "./lineal/671-male-doctor-care-lineal.svg";
import {ReactComponent as Male_Lineal} from "./lineal/702-beard-barber-lineal.svg";
import {ReactComponent as Margarita_Glass_Lineal} from "./lineal/2993-margarita-glass-lineal.svg";
import {ReactComponent as Medical_Insurance_Lineal} from "./lineal/1267-medical-insurancea-lineal.svg";
import {ReactComponent as Nail_Polish_Lineal} from "./lineal/1592-nail-polish-lineal.svg";
import {ReactComponent as Paintball_Gun_Lineal} from "./lineal/1900-paintball-gun-lineal.svg";
import {ReactComponent as Paws_Animal_Lineal} from "./lineal/448-paws-animal-lineal.svg";
import {ReactComponent as Pedicure_Lineal} from "./lineal/1571-pedicure-lineal.svg";
import {ReactComponent as Ping_Pong_Lineal} from "./lineal/1810-ping-pong-table-tennis-lineal.svg";
import {ReactComponent as Pizza_Lineal} from "./lineal/13-pizza-lineal.svg";
import {ReactComponent as Plate_Pasta_Lineal} from "./lineal/603-plate-pasta-lineal.svg";
import {ReactComponent as Chef_Knife_Lineal} from "./lineal/1606-professional-chef-knife-lineal.svg";
import {ReactComponent as Raised_Hand_Lineal} from "./lineal/2527-rised-hand-lineal.svg";
import {ReactComponent as Scissors_Lineal} from "./lineal/114-scissors-lineal.svg";
import {ReactComponent as Skates_Lineal} from "./lineal/2943-skates-lineal.svg";
import {ReactComponent as Skateboard_Lineal} from "./lineal/1785-skateboard-lineal.svg";
import {ReactComponent as Sparkles_Glitter_Lineal} from "./lineal/2474-sparkles-glitter-lineal.svg";
import {ReactComponent as Spa_Flower_Lineal} from "./lineal/1574-spa-flower-lineal.svg";
import {ReactComponent as Sponge_Lineal} from "./lineal/1698-sponge-lineal.svg";
import {ReactComponent as Star_Rating_Lineal} from "./lineal/237-star-rating-lineal.svg";
import {ReactComponent as Stamp_Lineal} from "./lineal/460-stamp-lineal.svg";
import {ReactComponent as Strawberry_Lineal} from "./lineal/578-strawberry-lineal.svg";
import {ReactComponent as Graduation_Lineal} from "./lineal/406-study-graduation-lineal.svg";
import {ReactComponent as Teddy_Bear_Lineal} from "./lineal/1546-teddy-bear-toy-lineal.svg";
import {ReactComponent as Thumbs_Up_Lineal} from "./lineal/267-like-thumb-up-lineal.svg";
import {ReactComponent as Tree_Planting_Lineal} from "./lineal/1850-tree-planting-lineal.svg";
import {ReactComponent as Treble_Clef_Lineal} from "./lineal/1497-treble-clef-lineal.svg";
import {ReactComponent as Truck_Lineal} from "./lineal/849-truck-lineal.svg";
import {ReactComponent as UFO_Aliens_Lineal} from "./lineal/492-ufo-aliens-lineal.svg";
import {ReactComponent as Umbrella_Lineal} from "./lineal/422-umbrella-lineal.svg";
import {ReactComponent as User_Avatar_Lineal} from "./lineal/44-avatar-user-in-circle-lineal.svg";
import {ReactComponent as Watering_Can_Lineal} from "./lineal/1849-watering-can-lineal.svg";
import {ReactComponent as Gym_Weight_Lineal} from "./lineal/429-weight-gym-fitness-lineal.svg";

export const stampDefinitions = {
	Arcade_Joystick_Wired: {
		component: Arcade_Joystick_Wired,
		search: ["gaming", "controller", "arcade"],
	},
	Balloon_Glass_Wired: {
		component: Balloon_Glass_Wired,
		search: ["drink", "wine", "celebration"],
	},
	Barber_Scissors_Wired: {
		component: Barber_Scissors_Wired,
		search: ["haircut", "salon", "barber"],
	},
	Basketball_Wired: {
		component: Basketball_Wired,
		search: ["sports", "ball", "game"],
	},
	Best_Seller_Wired: {
		component: Best_Seller_Wired,
		search: ["award", "achievement", "badge"],
	},
	Beverages_Wired: {
		component: Beverages_Wired,
		search: ["drink", "cup", "liquid"],
	},
	Bicycle_Wired: {
		component: Bicycle_Wired,
		search: ["bike", "cycling", "transport"],
	},
	Boombox_Radio_Wired: {
		component: Boombox_Radio_Wired,
		search: ["music", "radio", "stereo"],
	},
	Bowl_Of_Soup_Wired: {
		component: Bowl_Of_Soup_Wired,
		search: ["food", "soup", "meal"],
	},
	Brush_Wired: {component: Brush_Wired, search: ["paint", "art", "drawing"]},
	Burger_Wired: {
		component: Burger_Wired,
		search: ["food", "hamburger", "fast-food"],
	},
	Butterfly_Wired: {
		component: Butterfly_Wired,
		search: ["insect", "nature", "flying"],
	},
	Casino_Chip_Wired: {
		component: Casino_Chip_Wired,
		search: ["gambling", "poker", "betting"],
	},
	Camera_Wired: {
		component: Camera_Wired,
		search: ["photo", "picture", "photography"],
	},
	Candy_Cane_Wired: {
		component: Candy_Cane_Wired,
		search: ["christmas", "sweet", "holiday"],
	},
	Car_Wired: {
		component: Car_Wired,
		search: ["car", "vehicle"],
	},
	Chef_Knife_Wired: {
		component: Chef_Knife_Wired,
		search: ["cooking", "kitchen", "culinary"],
	},
	Christmas_Tree_Wired: {
		component: Christmas_Tree_Wired,
		search: ["holiday", "christmas", "decoration"],
	},
	Hanger_Wired: {
		component: Hanger_Wired,
		search: ["wardrobe", "clothes", "storage", "cleaning"],
	},
	Coffee_Beans_Wired: {
		component: Coffee_Beans_Wired,
		search: ["coffee", "beans", "cafe"],
	},
	Coffee_TakeAway_Wired: {
		component: Coffee_TakeAway_Wired,
		search: ["coffee", "takeout", "drink"],
	},
	Coffee_Wired: {
		component: Coffee_Wired,
		search: ["coffee", "drink", "cafe"],
	},
	Computer_Display_Wired: {
		component: Computer_Display_Wired,
		search: ["computer", "screen", "monitor"],
	},
	Confetti_Wired: {
		component: Confetti_Wired,
		search: ["celebration", "party", "festive"],
	},
	Crown_Wired: {component: Crown_Wired, search: ["royal", "king", "queen"]},
	Cupcake_Heart_Wired: {
		component: Cupcake_Heart_Wired,
		search: ["dessert", "sweet", "bakery"],
	},
	Dog_Walking_Wired: {
		component: Dog_Walking_Wired,
		search: ["pet", "dog", "walking"],
	},
	Earphone_Wired: {
		component: Earphone_Wired,
		search: ["music", "audio", "headphones"],
	},
	Environment_Eco_Wired: {
		component: Environment_Eco_Wired,
		search: ["environment", "eco", "nature"],
	},
	Delivery_Truck_Wired: {
		component: Delivery_Truck_Wired,
		search: ["truck", "delivery"],
	},
	Dropper_Wired: {
		component: Dropper_Wired,
		search: ["sample", "demo", "example", "droppper"],
	},
	Dispenser_Wired: {
		component: Dispenser_Wired,
		search: ["soap", "cleaning", "dispenser"],
	},
	F1_Car_Wired: {
		component: F1_Car_Wired,
		search: ["racing", "car", "formula"],
	},
	Female_Wired: {
		component: Female_Wired,
		search: ["female", "human", "people"],
	},
	Fish_Wired: {component: Fish_Wired, search: ["animal", "sea", "aquatic"]},
	Football_Wired: {
		component: Football_Wired,
		search: ["soccer", "sports", "ball"],
	},
	Galaxy_Wired: {
		component: Galaxy_Wired,
		search: ["space", "stars", "universe"],
	},
	Gift_Wired: {
		component: Gift_Wired,
		search: ["present", "gift", "birthday"],
	},
	Golf_Wired: {component: Golf_Wired, search: ["sport", "golf", "club"]},
	Graduation_Wired: {
		component: Graduation_Wired,
		search: ["education", "graduation", "academic", "study"],
	},
	Gym_Weight_Wired: {
		component: Gym_Weight_Wired,
		search: ["fitness", "exercise", "gym", "sports"],
	},
	Hair_Brush_Wired: {
		component: Hair_Brush_Wired,
		search: ["grooming", "hair", "beauty"],
	},
	Hair_Dryer_Wired: {
		component: Hair_Dryer_Wired,
		search: ["hair", "beauty", "salon"],
	},
	Hammer_Wired: {
		component: Hammer_Wired,
		search: ["tool", "construction", "repair"],
	},
	Hamster_Wired: {
		component: Hamster_Wired,
		search: ["pet", "animal", "rodent"],
	},
	Hand_Gift_Wired: {
		component: Hand_Gift_Wired,
		search: ["present", "giving", "gift"],
	},
	Love_Heart_Wired: {
		component: Love_Heart_Wired,
		search: ["love", "heart", "romance"],
	},
	Home_Wired: {component: Home_Wired, search: ["house", "home", "building"]},
	Horseshoe_Wired: {
		component: Horseshoe_Wired,
		search: ["luck", "horse", "fortune"],
	},
	Horse_Wired: {
		component: Horse_Wired,
		search: ["animal", "horse", "riding"],
	},
	Ice_Cream_Wired: {
		component: Ice_Cream_Wired,
		search: ["dessert", "ice-cream", "sweet"],
	},
	Jewellery_Wired: {
		component: Jewellery_Wired,
		search: ["jewelry", "accessory", "fashion"],
	},
	Kettle_Wired: {
		component: Kettle_Wired,
		search: ["kitchen", "tea", "cooking"],
	},
	Lips_Wired: {
		component: Lips_Wired,
		search: ["lips", "lipstick", "makeup"],
	},
	Magic_Wand_Wired: {
		component: Magic_Wand_Wired,
		search: ["magic", "fantasy", "wand"],
	},
	Makeup_Wired: {
		component: Makeup_Wired,
		search: ["beauty", "cosmetics", "makeup"],
	},
	Male_Doctor_Wired: {
		component: Male_Doctor_Wired,
		search: ["medical", "doctor", "health", "male"],
	},
	Male_Wired: {
		component: Male_Wired,
		search: ["human", "male", "people"],
	},
	Margarita_Wired: {
		component: Margarita_Wired,
		search: ["drink", "cocktail", "beverage"],
	},
	Medical_Insurance_Wired: {
		component: Medical_Insurance_Wired,
		search: ["medical", "insurance", "healthcare"],
	},
	Nail_Polish_Wired: {
		component: Nail_Polish_Wired,
		search: ["beauty", "nails", "cosmetics"],
	},
	Paintball_Wired: {
		component: Paintball_Wired,
		search: ["sport", "paintball", "game"],
	},
	Paste_Plate_Wired: {
		component: Paste_Plate_Wired,
		search: ["food", "pasta", "meal"],
	},
	Paws_Animal_Wired: {
		component: Paws_Animal_Wired,
		search: ["pet", "paw", "animal"],
	},
	Pedicure_Wired: {
		component: Pedicure_Wired,
		search: ["beauty", "feet", "spa"],
	},
	Ping_Pong_Wired: {
		component: Ping_Pong_Wired,
		search: ["sport", "table-tennis", "game"],
	},
	Pizza_Wired: {component: Pizza_Wired, search: ["food", "pizza", "meal"]},
	Raised_Hand_Wired: {
		component: Raised_Hand_Wired,
		search: ["hand", "gesture", "volunteer"],
	},
	Scissors_Wired: {
		component: Scissors_Wired,
		search: ["cut", "tool", "craft"],
	},
	Skateboard_Wired: {
		component: Skateboard_Wired,
		search: ["sport", "skateboard", "skating"],
	},
	Skates_Wired: {
		component: Skates_Wired,
		search: ["sport", "skating", "ice"],
	},
	Smartphone_Wired: {
		component: Smartphone_Wired,
		search: ["phone", "mobile", "device"],
	},
	Spa_Flower_Wired: {
		component: Spa_Flower_Wired,
		search: ["spa", "relaxation", "wellness"],
	},
	Sponge_Wired: {
		component: Sponge_Wired,
		search: ["sponge", "cleaning", "soap"],
	},
	Sparkles_Wired: {
		component: Sparkles_Wired,
		search: ["sparkle", "glitter", "shine"],
	},
	Star_Rating_Wired: {
		component: Star_Rating_Wired,
		search: ["rating", "review", "star"],
	},
	Stamp_Wired: {
		component: Stamp_Wired,
		search: ["mail", "postage", "postal"],
	},
	Strawberry_Wired: {
		component: Strawberry_Wired,
		search: ["fruit", "food", "berry"],
	},
	Teddy_Bear_Wired: {
		component: Teddy_Bear_Wired,
		search: ["toy", "bear", "children"],
	},
	Thumbs_Up_Wired: {
		component: Thumbs_Up_Wired,
		search: ["thumbs", "up", "like"],
	},
	Tree_Planting_Wired: {
		component: Tree_Planting_Wired,
		search: ["nature", "gardening", "environment"],
	},
	Treble_Clef_Wired: {
		component: Treble_Clef_Wired,
		search: ["music", "note", "musical"],
	},
	Truck_Wired: {
		component: Truck_Wired,
		search: ["truck", "vehicle", "van"],
	},
	UFO_Wired: {component: UFO_Wired, search: ["space", "alien", "ufo"]},
	Umbrella_Wired: {
		component: Umbrella_Wired,
		search: ["rain", "weather", "protection"],
	},
	User_Avatar_Wired: {
		component: User_Avatar_Wired,
		search: ["profile", "person", "account", "user"],
	},
	Watering_Can_Wired: {
		component: Watering_Can_Wired,
		search: ["gardening", "watering", "plants"],
	},
	// Lineal Components
	Arcade_Joystick_Lineal: {
		component: Arcade_Joystick_Lineal,
		search: ["gaming", "controller", "arcade"],
	},
	Balloon_Glass_Lineal: {
		component: Balloon_Glass_Lineal,
		search: ["drink", "wine", "celebration"],
	},
	Barber_Scissors_Lineal: {
		component: Barber_Scissors_Lineal,
		search: ["haircut", "salon", "barber"],
	},
	Basketball_Ball_Lineal: {
		component: Basketball_Ball_Lineal,
		search: ["sports", "ball", "game"],
	},
	Best_Seller_Lineal: {
		component: Best_Seller_Lineal,
		search: ["award", "achievement", "badge"],
	},
	Beverages_Lineal: {
		component: Beverages_Lineal,
		search: ["drink", "cup", "liquid"],
	},
	Bicycle_Lineal: {
		component: Bicycle_Lineal,
		search: ["bike", "cycling", "transport"],
	},
	Boombox_Radio_Lineal: {
		component: Boombox_Radio_Lineal,
		search: ["music", "radio", "stereo"],
	},
	Bowl_Of_Soup_Lineal: {
		component: Bowl_Of_Soup_Lineal,
		search: ["food", "soup", "meal"],
	},
	Brush_Lineal: {
		component: Brush_Lineal,
		search: ["paint", "art", "drawing"],
	},
	Burger_Lineal: {
		component: Burger_Lineal,
		search: ["food", "hamburger", "fast-food"],
	},
	Butterfly_Lineal: {
		component: Butterfly_Lineal,
		search: ["insect", "nature", "flying"],
	},
	Camera_Lineal: {
		component: Camera_Lineal,
		search: ["photo", "picture", "photography"],
	},
	Candy_Cane_Lineal: {
		component: Candy_Cane_Lineal,
		search: ["christmas", "sweet", "holiday"],
	},
	Casino_Chip_Lineal: {
		component: Casino_Chip_Lineal,
		search: ["gambling", "poker", "betting"],
	},
	Car_Lineal: {
		component: Car_Lineal,
		search: ["car", "vehicle"],
	},
	Christmas_Tree_Lineal: {
		component: Christmas_Tree_Lineal,
		search: ["holiday", "christmas", "decoration"],
	},
	Hanger_Lineal: {
		component: Hanger_Lineal,
		search: ["wardrobe", "clothes", "storage", "cleaning"],
	},
	Coffee_Beans_Lineal: {
		component: Coffee_Beans_Lineal,
		search: ["coffee", "beans", "cafe"],
	},
	Coffee_Lineal: {
		component: Coffee_Lineal,
		search: ["coffee", "drink", "cafe"],
	},
	Coffee_To_Go_Lineal: {
		component: Coffee_To_Go_Lineal,
		search: ["coffee", "takeout", "drink"],
	},
	Computer_Display_Lineal: {
		component: Computer_Display_Lineal,
		search: ["computer", "screen", "monitor"],
	},
	Confetti_Lineal: {
		component: Confetti_Lineal,
		search: ["celebration", "party", "festive"],
	},
	Crown_King_Lord_Lineal: {
		component: Crown_King_Lord_Lineal,
		search: ["royal", "king", "queen"],
	},
	Cupcake_Heart_Lineal: {
		component: Cupcake_Heart_Lineal,
		search: ["dessert", "sweet", "bakery"],
	},
	Dog_Walking_Lineal: {
		component: Dog_Walking_Lineal,
		search: ["pet", "dog", "walking"],
	},
	Delivery_Truck_Lineal: {
		component: Delivery_Truck_Lineal,
		search: ["delivery", "truck", "van"],
	},
	Earphone_Lineal: {
		component: Earphone_Lineal,
		search: ["music", "audio", "headphones"],
	},
	Environment_Eco_Care_Lineal: {
		component: Environment_Eco_Care_Lineal,
		search: ["environment", "eco", "nature"],
	},
	Dropper_Lineal: {
		component: Dropper_Lineal,
		search: ["sample", "demo", "example", "dropper"],
	},
	Dispenser_Lineal: {
		component: Dispenser_Lineal,
		search: ["soap", "cleaning", "dispenser"],
	},
	F1_Car_Lineal: {
		component: F1_Car_Lineal,
		search: ["racing", "car", "formula"],
	},
	Female_Lineal: {
		component: Female_Lineal,
		search: ["female", "human", "people"],
	},
	Fish_Lineal: {component: Fish_Lineal, search: ["animal", "sea", "aquatic"]},
	Football_Lineal: {
		component: Football_Lineal,
		search: ["soccer", "sports", "ball"],
	},
	Galaxy_Lineal: {
		component: Galaxy_Lineal,
		search: ["space", "stars", "universe"],
	},
	Gift_Lineal: {
		component: Gift_Lineal,
		search: ["present", "gift", "birthday"],
	},
	Golf_Lineal: {component: Golf_Lineal, search: ["sport", "golf", "club"]},
	Hair_Brush_Lineal: {
		component: Hair_Brush_Lineal,
		search: ["grooming", "hair", "beauty"],
	},
	Hair_Dryer_Lineal: {
		component: Hair_Dryer_Lineal,
		search: ["hair", "beauty", "salon"],
	},
	Hammer_Lineal: {
		component: Hammer_Lineal,
		search: ["tool", "construction", "repair"],
	},
	Hamster_Lineal: {
		component: Hamster_Lineal,
		search: ["pet", "animal", "rodent"],
	},
	Hand_Gift_Lineal: {
		component: Hand_Gift_Lineal,
		search: ["present", "giving", "gift"],
	},
	Home_Lineal: {
		component: Home_Lineal,
		search: ["house", "residence", "dwelling"],
	},
	Horse_Lineal: {
		component: Horse_Lineal,
		search: ["equestrian", "stallion", "animal"],
	},
	Horseshoe_Lineal: {
		component: Horseshoe_Lineal,
		search: ["fortune", "lucky", "charm"],
	},
	Ice_Cream_Lineal: {
		component: Ice_Cream_Lineal,
		search: ["dessert", "frozen", "treat"],
	},
	Jewellery_Lineal: {
		component: Jewellery_Lineal,
		search: ["accessories", "gems", "ornaments"],
	},
	Kettle_Lineal: {
		component: Kettle_Lineal,
		search: ["teapot", "brewing", "kitchen"],
	},
	Lips_Lineal: {
		component: Lips_Lineal,
		search: ["lips", "lipstick", "makeup"],
	},
	Love_Heart_Lineal: {
		component: Love_Heart_Lineal,
		search: ["romance", "affection", "emotion"],
	},
	Magic_Wand_Lineal: {
		component: Magic_Wand_Lineal,
		search: ["spell", "wizard", "enchantment"],
	},
	Makeup_Lineal: {
		component: Makeup_Lineal,
		search: ["cosmetics", "beauty", "grooming"],
	},
	Male_Doctor_Lineal: {
		component: Male_Doctor_Lineal,
		search: ["physician", "healthcare", "medical"],
	},
	Male_Lineal: {
		component: Male_Lineal,
		search: ["male", "human", "people"],
	},
	Margarita_Glass_Lineal: {
		component: Margarita_Glass_Lineal,
		search: ["cocktail", "beverage", "drink"],
	},
	Medical_Insurance_Lineal: {
		component: Medical_Insurance_Lineal,
		search: ["healthcare", "coverage", "protection"],
	},
	Nail_Polish_Lineal: {
		component: Nail_Polish_Lineal,
		search: ["manicure", "beauty", "cosmetics"],
	},
	Paintball_Gun_Lineal: {
		component: Paintball_Gun_Lineal,
		search: ["sport", "recreation", "game"],
	},
	Paws_Animal_Lineal: {
		component: Paws_Animal_Lineal,
		search: ["pet", "tracks", "footprints"],
	},
	Pedicure_Lineal: {
		component: Pedicure_Lineal,
		search: ["feet", "spa", "treatment"],
	},
	Ping_Pong_Lineal: {
		component: Ping_Pong_Lineal,
		search: ["sport", "paddle", "game"],
	},
	Pizza_Lineal: {
		component: Pizza_Lineal,
		search: ["food", "italian", "meal"],
	},
	Plate_Pasta_Lineal: {
		component: Plate_Pasta_Lineal,
		search: ["food", "italian", "dinner"],
	},
	Chef_Knife_Lineal: {
		component: Chef_Knife_Lineal,
		search: ["cooking", "kitchen", "culinary"],
	},
	Raised_Hand_Lineal: {
		component: Raised_Hand_Lineal,
		search: ["volunteer", "participation", "gesture"],
	},
	Scissors_Lineal: {
		component: Scissors_Lineal,
		search: ["cutting", "craft", "tool"],
	},
	Skates_Lineal: {
		component: Skates_Lineal,
		search: ["ice", "winter", "sport"],
	},
	Skateboard_Lineal: {
		component: Skateboard_Lineal,
		search: ["extreme", "ride", "sport"],
	},
	Sparkles_Glitter_Lineal: {
		component: Sparkles_Glitter_Lineal,
		search: ["shimmer", "sparkle", "shine"],
	},
	Spa_Flower_Lineal: {
		component: Spa_Flower_Lineal,
		search: ["wellness", "relaxation", "beauty"],
	},
	Sponge_Lineal: {
		component: Sponge_Lineal,
		search: ["sponge", "cleaning", "soap"],
	},
	Star_Rating_Lineal: {
		component: Star_Rating_Lineal,
		search: ["review", "feedback", "rating"],
	},
	Stamp_Lineal: {
		component: Stamp_Lineal,
		search: ["postal", "mail", "postage"],
	},
	Strawberry_Lineal: {
		component: Strawberry_Lineal,
		search: ["fruit", "berry", "food"],
	},
	Graduation_Lineal: {
		component: Graduation_Lineal,
		search: ["education", "diploma", "academic", "study"],
	},
	Teddy_Bear_Lineal: {
		component: Teddy_Bear_Lineal,
		search: ["plush", "toy", "children"],
	},
	Thumbs_Up_Lineal: {
		component: Thumbs_Up_Lineal,
		search: ["thumbs", "up", "like"],
	},
	Tree_Planting_Lineal: {
		component: Tree_Planting_Lineal,
		search: ["gardening", "nature", "growth"],
	},
	Treble_Clef_Lineal: {
		component: Treble_Clef_Lineal,
		search: ["music", "notation", "symphony"],
	},
	Truck_Lineal: {
		component: Truck_Lineal,
		search: ["truck", "van", "vehicle"],
	},
	UFO_Aliens_Lineal: {
		component: UFO_Aliens_Lineal,
		search: ["spaceship", "extraterrestrial", "flying"],
	},
	Umbrella_Lineal: {
		component: Umbrella_Lineal,
		search: ["rain", "shelter", "protection"],
	},
	User_Avatar_Lineal: {
		component: User_Avatar_Lineal,
		search: ["profile", "account", "person", "user"],
	},
	Watering_Can_Lineal: {
		component: Watering_Can_Lineal,
		search: ["gardening", "plant", "water"],
	},
	Gym_Weight_Lineal: {
		component: Gym_Weight_Lineal,
		search: ["exercise", "workout", "strength", "sports"],
	},
};

export const stamps = {
	// Create stamps.component with full entries
	component: Object.fromEntries(
		Object.entries(stampDefinitions).map(([key, {component}]) => [
			key,
			component,
		])
	),
	// Create stamps.search with full entries (preserving search terms)
	search: Object.fromEntries(
		Object.entries(stampDefinitions).map(([key, {search}]) => [key, search])
	),
};
