import React, {useEffect, useState} from "react";
import Navbar from "./navbar";
import LoadingPage from "./loadingpage.js";
import {
	Box,
	Button,
	Card,
	Container,
	Divider,
	Grid,
	Paper,
	Rating,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material";
import {ApiServiceClass} from "../components/api-service";
import {toast} from "react-toastify";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Getcookies from "../components/getcookies";
import Base64Image from "../components/myapp/cards/sharedcomponents/Base64Image";

export default function PublishCenter() {
	const [saveDisabled, setSaveDisabled] = useState(false);
	const [loading, setLoading] = useState(true);
	const [logo, setLogo] = useState();
	const [logoFileType, setLogoFileType] = useState();
	const cookies = Getcookies();
	// App store preview state
	const [appName, setAppName] = useState("Your App Name");
	const [iosSubtitle, setIosSubtitle] = useState("Add your subtitle here.");
	const [shortDesc, setShortDesc] = useState("Add your subtitle here.");
	const [fullDesc, setFullDesc] = useState(
		"Add your full app description here."
	);
	const [rating, setRating] = useState(5.0);
	const [ratingCount, setRatingCount] = useState(60);

	// Validation errors
	const [appNameError, setAppNameError] = useState("");
	const [iosSubtitleError, setIosSubtitleError] = useState("");
	const [shortDescError, setShortDescError] = useState("");
	const [fullDescError, setFullDescError] = useState("");


	const handleSubmit = () => {
		// Validate all fields before submission
		let isValid = true;

		if (!appName || appName.length > 30) {
			setAppNameError("App name is required and must be 30 characters or less");
			isValid = false;
		}

		if (!iosSubtitle || iosSubtitle.length > 30) {
			setIosSubtitleError("iOS subtitle is required and must be 30 characters or less");
			isValid = false;
		}

		if (!shortDesc || shortDesc.length > 80) {
			setShortDescError("Short description is required and must be 80 characters or less");
			isValid = false;
		}

		if (!fullDesc || fullDesc.length > 4000) {
			setFullDescError("Full description is required and must be 4000 characters or less");
			isValid = false;
		}
		if (isValid) {
			handleSave();
		}
	};

	const handleSave = async () => {
		try {
			const requestBody = {
				AppName: appName,
				IosSubtitle: iosSubtitle,
				ShortDescription: shortDesc,
				FullDescription: fullDesc,
			};
			setSaveDisabled(true);
			const response = await ApiServiceClass.SavePublishInfo(requestBody);
			if (response.status === 200) {
				toast.success("App Published Successfully", {autoClose: 1500});
			}
		} catch (error) {
			// Consolidated error handling
			const statusCode = error.response?.status || error.status || "Unknown";
			const errorMessage = `Error Publishing App: ${statusCode}`;

			console.error(errorMessage, error);
			toast.error(errorMessage, {autoClose: 1500});
		}
	};

	// Validation functions
	const validateAppName = (value) => {
		if (value.length > 30) {
			setAppNameError("App name must be 30 characters or less");
		} else {
			setAppNameError("");
		}
		setAppName(value);
		setSaveDisabled(false);
	};

	const validateIosSubtitle = (value) => {
		if (value.length > 30) {
			setIosSubtitleError("iOS subtitle must be 30 characters or less");
		} else {
			setIosSubtitleError("");
		}
		setIosSubtitle(value);
		setSaveDisabled(false);
	};

	const validateShortDesc = (value) => {
		if (value.length > 80) {
			setShortDescError("Android subtitle must be 80 characters or less");
		} else {
			setShortDescError("");
		}
		setShortDesc(value);
		setSaveDisabled(false);
	};

	const validateFullDesc = (value) => {
		if (value.length > 4000) {
			setFullDescError("Full description must be 4000 characters or less");
		} else {
			setFullDescError("");
		}
		setFullDesc(value);
		setSaveDisabled(false);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				const requestBody = {
					CompanyId: cookies.companyData.Id,
				};
				console.log("Getting publishing info: ", requestBody);
				const publishResponse = await ApiServiceClass.GetPublishInfo(requestBody);
				const logoRequestBody = {
					...requestBody,  // retain the original request body
					FromWeb: true,    // add a new property
				};
				const logoResponse = await ApiServiceClass.GetCompanyLogo(logoRequestBody);
				if (publishResponse.status === 200) {
					setAppName(publishResponse.body.AppName);
					setIosSubtitle(publishResponse.body.IosSubtitle);
					setShortDesc(publishResponse.body.ShortDescription);
					setFullDesc(publishResponse.body.FullDescription);
					setLoading(false);
					if (logoResponse.status === 200 && logoResponse.body.CompanyLogo !== null) {
						setLogo(logoResponse.body.CompanyLogo);
						setLogoFileType(logoResponse.body.LogoFileType);
					}
				} else {
					toast.error("Error fetching publish information");
				}
			} catch (error) {
				console.log(error);
			}
		};
		fetchData();
	}, []);

	if (loading) {
		return <LoadingPage></LoadingPage>
	}

	return (
		<Navbar name="Publish Center">
			<div style={{padding: "2%"}}>
				<Container maxWidth="xl" sx={{py: 2}}>
					<Grid container spacing={3}>
						{/* App Store Preview (Left Side) */}
						<Grid item xs={12} md={5}>
							<Paper
								elevation={3}
								sx={{

									height: "100%",
									display: "flex",
									flexDirection: "column"
								}}
							>
								<Box sx={{bgcolor: "#f9f9f9", p: 3, borderRadius: 1.5, flexGrow: 1}}>
									{/* App Header */}
									<div variant="subtitle2"
										 style={{fontSize: "18px", marginBottom: 14, marginTop: -8, color: "#666"}}>
										App Store Preview
									</div>
									<Box sx={{display: "flex", mb: 2}}>

										<Card
											sx={{
												width: 110,
												height: 110,
												borderRadius: 2,
												mr: 2,
												//background: "linear-gradient(135deg, #301562, #0260fe)",
												display: "flex",
												alignItems: "center",
												justifyContent: "center"
											}}
										>
											<Base64Image
												image={logo}
												fileType={logoFileType}
												style={{
													width: "100%",
													maxWidth: "350px",
													height: "auto", // Ensures the aspect ratio is maintained
													maxHeight: "150px", // Limit the maximum height
													objectFit: "contain", // Ensures the image scales properly within the bounds
												}}
											/>
										</Card>

										<Box sx={{marginTop: "5px"}}>
											<Typography variant="h7" sx={{fontWeight: "bold"}}>
												{appName} <Box component="span" sx={{
												fontSize: 12,
												bgcolor: "#eee",
												p: 0.5,
												marginTop: 2,
												borderRadius: 1
											}}>4+</Box>
											</Typography>
											<Typography variant="body2" color="text.secondary">
												{iosSubtitle}
											</Typography>
											{/*<Typography variant="body2" color="primary">*/}
											{/*	{loginData.CompanyName}*/}
											{/*</Typography>*/}
											<Box sx={{display: "flex", alignItems: "center", mt: 0.5}}>
												<Rating value={rating} precision={0.1} readOnly size="small"/>
												<Typography variant="body2" color="text.secondary" sx={{ml: 0.5}}>
													{rating} • {ratingCount} Ratings
												</Typography>
											</Box>
											<Typography variant="body2">
												Free
											</Typography>
										</Box>
									</Box>

									<Divider sx={{my: 2}}/>

									{/* Screenshots Section - Reduced size */}
									<div style={{marginBottom: 5}}>
										App Screenshots
										<Tooltip
											title={
												<span style={{display: "block", textAlign: "center"}}>
                You wont need to worry about uploading screenshots of the app, we will handle this!
              </span>
											}
											arrow
										>
											<HelpOutlineIcon
												style={{
													marginLeft: "4px",
													fontSize: "18px",
													cursor: "pointer",
													position: "relative",
													top: "3px" // Adjust this value as needed
												}}
											/>

										</Tooltip>
									</div>

									{/*<Box sx={{display: "flex", mb: 2}}>*/}
									{/*	{[1].map((item) => (*/}
									{/*		<Card key={item} sx={{padding: 1, mr: 1, borderRadius: 2, overflow: "hidden"}}>*/}
									{/*			<CardMedia*/}
									{/*				component="img"*/}
									{/*				height="200"*/}
									{/*				image={preview}*/}
									{/*				alt="Screenshot"*/}
									{/*			/>*/}
									{/*		</Card>*/}
									{/*	))}*/}
									{/*</Box>*/}
									<Box sx={{display: "flex", mb: 2}}>
										<Card
											sx={{
												width: 130,
												height: 210,
												borderRadius: 2,
												mr: 4,
												background: "#0260fe",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												textAlign: "center",
											}}
										>
											<Typography sx={{fontSize: "16px", color: "white"}}>
												Screenshot 1
											</Typography>
										</Card>
										<Card
											sx={{
												width: 130,
												height: 210,
												borderRadius: 2,
												mr: 2,
												background: "#0260fe",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												textAlign: "center",
											}}
										>
											<Typography sx={{fontSize: "16px", color: "white"}}>
												Screenshot 2
											</Typography>
										</Card>
									</Box>
									<Divider sx={{my: 2}}/>

									{/* Description - Shortened to fit without scroll */}
									<div style={{mb: 2, minHeight: "140px", fontSize: "13px"}}>
										{fullDesc.length > 650 ? `${fullDesc.substring(0, 650)}...` : fullDesc}
									</div>

									<Divider sx={{my: 1}}/>

									{/* Ratings and Reviews */}
									<Typography variant="subtitle1" sx={{mb: 1, mt: 4}}>
										Ratings and Reviews
									</Typography>
									<Box sx={{display: "flex", alignItems: "center"}}>
										<Typography variant="h3" sx={{mr: 2}}>
											{rating}
										</Typography>
										<Box sx={{flexGrow: 1}}>
											<Typography variant="body2" color="text.secondary">
												out of 5
											</Typography>
											<Typography variant="body2" color="text.secondary">
												{ratingCount} Ratings
											</Typography>
										</Box>
									</Box>
								</Box>
							</Paper>
						</Grid>

						{/* Input Fields (Right Side) */}
						<Grid item xs={12} md={7}>
							<Paper elevation={3} sx={{p: 2, height: "100%"}}>
								<div variant="h6" style={{fontSize: "18px"}}>
									Edit App Store Listing
								</div>

								<Box component="form" noValidate
									 sx={{
										 mt: 0,
										 height: "calc(100% - 72px)",
										 display: "flex",
										 flexDirection: "column"
									 }}>
									<TextField
										margin="normal"
										required
										fullWidth
										id="appName"
										label="App Name (max 30 chars)"
										name="appName"
										value={appName}
										onChange={(e) => validateAppName(e.target.value)}
										error={!!appNameError}
										helperText={appNameError || `${appName.length}/30 characters`}
										InputProps={{
											inputProps: {maxLength: 30}
										}}
									/>

									<TextField
										margin="normal"
										required
										fullWidth
										id="iosSubtitle"
										label="iOS Subtitle (max 30 chars)"
										name="iosSubtitle"
										value={iosSubtitle}
										onChange={(e) => validateIosSubtitle(e.target.value)}
										error={!!iosSubtitleError}
										helperText={iosSubtitleError || `${iosSubtitle.length}/30 characters`}
										InputProps={{
											inputProps: {maxLength: 30}
										}}
									/>

									<TextField
										margin="normal"
										required
										fullWidth
										id="shortDesc"
										label="Android Subtitle (max 80 chars)"
										name="shortDesc"
										value={shortDesc}
										onChange={(e) => validateShortDesc(e.target.value)}
										error={!!shortDescError}
										helperText={shortDescError || `${shortDesc.length}/80 characters`}
										InputProps={{
											inputProps: {maxLength: 80}
										}}
									/>

									<TextField
										margin="normal"
										required
										fullWidth
										id="fullDesc"
										label="Full Description (max 4000 chars)"
										name="fullDesc"
										value={fullDesc}
										onChange={(e) => validateFullDesc(e.target.value)}
										error={!!fullDescError}
										helperText={fullDescError || `${fullDesc.length}/4000 characters`}
										multiline
										rows={12}
										InputProps={{
											inputProps: {maxLength: 4000}
										}}
										sx={{flexGrow: 1}}
									/>

									<Box sx={{
										display: "flex",
										justifyContent: "flex-start",
										alignItems: "flex-end",
										mt: 2,
										height: "100%",
									}}>
										<Button
											type="button"
											onClick={handleSubmit}
											disabled={saveDisabled}
											sx={{minWidth: "150px", marginTop: 2}}
										>
											Submit
										</Button>
									</Box>
								</Box>
							</Paper>
						</Grid>
					</Grid>
				</Container>
			</div>
		</Navbar>
	)
		;
}