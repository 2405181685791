import {ApiServiceClass} from '../components/api-service.js';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import CircularProgress from "@mui/material/CircularProgress";
import Navbar from "./navbar.js";
import {useNavigate} from "react-router-dom";
import RewardsLeftPanel from "../sections/rewards-left-panel.js";
import RewardsRightPanel from "../sections/rewards-right-panel.js";


export default function Rewards() {
	const [rewards, setRewards] = useState({});
	const [isReward, setIsReward] = useState(false);

	const rawLoginData = localStorage.getItem("loginData");
	const loginData = JSON.parse(rawLoginData);
	const rawCompanyDetails = localStorage.getItem("companyData");
	const companyDetails = JSON.parse(rawCompanyDetails);

	const [stampName, setStampName] = useState('star');
	const [stampColor, setStampColor] = useState("#00000");
	const [stampBackgroundColor, setStampBackgroundColor] = useState("#ff000");
	const [rewardName, setRewardName] = useState('Your title here');
	const [rewardSubtitle, setRewardSubtitle] = useState('Your subtitle here');
	const [noPurchases, setNoPurchases] = useState(8);
	const [backgroundColor, setBackgroundColor] = useState('ffffff');

	let navigate = useNavigate();
	useEffect(() => {
		if (companyDetails === null || loginData === null) {
			console.log("redirecting to /Login");
			navigate("/Login");
		}
		fetchData();
	}, []);

	const stamps = {
		scissors: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
			<path
				d="M256 192l-39.5-39.5c4.9-12.6 7.5-26.2 7.5-40.5C224 50.1 173.9 0 112 0S0 50.1 0 112s50.1 112 112 112c14.3 0 27.9-2.7 40.5-7.5L192 256l-39.5 39.5c-12.6-4.9-26.2-7.5-40.5-7.5C50.1 288 0 338.1 0 400s50.1 112 112 112s112-50.1 112-112c0-14.3-2.7-27.9-7.5-40.5L499.2 76.8c7.1-7.1 7.1-18.5 0-25.6c-28.3-28.3-74.1-28.3-102.4 0L256 192zm22.6 150.6L396.8 460.8c28.3 28.3 74.1 28.3 102.4 0c7.1-7.1 7.1-18.5 0-25.6L342.6 278.6l-64 64zM64 112a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm48 240a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/>
		</svg>,
		coffee: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
			<path
				d="M88 0C74.7 0 64 10.7 64 24c0 38.9 23.4 59.4 39.1 73.1l1.1 1C120.5 112.3 128 119.9 128 136c0 13.3 10.7 24 24 24s24-10.7 24-24c0-38.9-23.4-59.4-39.1-73.1l-1.1-1C119.5 47.7 112 40.1 112 24c0-13.3-10.7-24-24-24zM32 192c-17.7 0-32 14.3-32 32V416c0 53 43 96 96 96H288c53 0 96-43 96-96h16c61.9 0 112-50.1 112-112s-50.1-112-112-112H352 32zm352 64h16c26.5 0 48 21.5 48 48s-21.5 48-48 48H384V256zM224 24c0-13.3-10.7-24-24-24s-24 10.7-24 24c0 38.9 23.4 59.4 39.1 73.1l1.1 1C232.5 112.3 240 119.9 240 136c0 13.3 10.7 24 24 24s24-10.7 24-24c0-38.9-23.4-59.4-39.1-73.1l-1.1-1C231.5 47.7 224 40.1 224 24z"/>
		</svg>,
		heart: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
			<path
				d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"/>
		</svg>,
		star: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
			<path
				d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
		</svg>,
		gift: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
			<path
				d="M190.5 68.8L225.3 128H224 152c-22.1 0-40-17.9-40-40s17.9-40 40-40h2.2c14.9 0 28.8 7.9 36.3 20.8zM64 88c0 14.4 3.5 28 9.6 40H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H480c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32H438.4c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88h-2.2c-31.9 0-61.5 16.9-77.7 44.4L256 85.5l-24.1-41C215.7 16.9 186.1 0 154.2 0H152C103.4 0 64 39.4 64 88zm336 0c0 22.1-17.9 40-40 40H288h-1.3l34.8-59.2C329.1 55.9 342.9 48 357.8 48H360c22.1 0 40 17.9 40 40zM32 288V464c0 26.5 21.5 48 48 48H224V288H32zM288 512H432c26.5 0 48-21.5 48-48V288H288V512z"/>
		</svg>,
		pen: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
			<path
				d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/>
		</svg>,
		ticket: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
			<path
				d="M64 64C28.7 64 0 92.7 0 128v64c0 8.8 7.4 15.7 15.7 18.6C34.5 217.1 48 235 48 256s-13.5 38.9-32.3 45.4C7.4 304.3 0 311.2 0 320v64c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V320c0-8.8-7.4-15.7-15.7-18.6C541.5 294.9 528 277 528 256s13.5-38.9 32.3-45.4c8.3-2.9 15.7-9.8 15.7-18.6V128c0-35.3-28.7-64-64-64H64zm64 112l0 160c0 8.8 7.2 16 16 16H432c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H144c-8.8 0-16 7.2-16 16zM96 160c0-17.7 14.3-32 32-32H448c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V160z"/>
		</svg>,
		truck: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
			<path
				d="M48 0C21.5 0 0 21.5 0 48V368c0 26.5 21.5 48 48 48H64c0 53 43 96 96 96s96-43 96-96H384c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V288 256 237.3c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7H416V48c0-26.5-21.5-48-48-48H48zM416 160h50.7L544 237.3V256H416V160zM112 416a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm368-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/>
		</svg>,
		car: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
			<path
				d="M171.3 96H224v96H111.3l30.4-75.9C146.5 104 158.2 96 171.3 96zM272 192V96h81.2c9.7 0 18.9 4.4 25 12l67.2 84H272zm256.2 1L428.2 68c-18.2-22.8-45.8-36-75-36H171.3c-39.3 0-74.6 23.9-89.1 60.3L40.6 196.4C16.8 205.8 0 228.9 0 256V368c0 17.7 14.3 32 32 32H65.3c7.6 45.4 47.1 80 94.7 80s87.1-34.6 94.7-80H385.3c7.6 45.4 47.1 80 94.7 80s87.1-34.6 94.7-80H608c17.7 0 32-14.3 32-32V320c0-65.2-48.8-119-111.8-127zM434.7 368a48 48 0 1 1 90.5 32 48 48 0 1 1 -90.5-32zM160 336a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/>
		</svg>,
		dumbbell: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
			<path
				d="M96 64c0-17.7 14.3-32 32-32h32c17.7 0 32 14.3 32 32V224v64V448c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V384H64c-17.7 0-32-14.3-32-32V288c-17.7 0-32-14.3-32-32s14.3-32 32-32V160c0-17.7 14.3-32 32-32H96V64zm448 0v64h32c17.7 0 32 14.3 32 32v64c17.7 0 32 14.3 32 32s-14.3 32-32 32v64c0 17.7-14.3 32-32 32H544v64c0 17.7-14.3 32-32 32H480c-17.7 0-32-14.3-32-32V288 224 64c0-17.7 14.3-32 32-32h32c17.7 0 32 14.3 32 32zM416 224v64H224V224H416z"/>
		</svg>,
		hands: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
			<path
				d="M544 160l-.1 72.6c-.1 52.2-24 101-64 133.1c.1-1.9 .1-3.8 .1-5.7v-8c0-71.8-37-138.6-97.9-176.7l-60.2-37.6c-8.6-5.4-17.9-8.4-27.3-9.4L248.7 48.8c-6.6-11.5-2.7-26.2 8.8-32.8s26.2-2.7 32.8 8.8l78 135.1c3.3 5.7 10.7 7.7 16.4 4.4s7.7-10.7 4.4-16.4l-62-107.4c-6.6-11.5-2.7-26.2 8.8-32.8S362 5 368.6 16.5l68 117.8 0 0 0 0 43.3 75L480 160c0-17.7 14.4-32 32-32s32 14.4 32 32zM243.9 88.5L268.5 131c-13.9 4.5-26.4 13.7-34.7 27c-.9 1.4-1.7 2.9-2.5 4.4l-28.9-50c-6.6-11.5-2.7-26.2 8.8-32.8s26.2-2.7 32.8 8.8zm-46.4 63.7l26.8 46.4c.6 6 2.1 11.8 4.3 17.4H224 210.7l0 0H179l-23-39.8c-6.6-11.5-2.7-26.2 8.8-32.8s26.2-2.7 32.8 8.8zM260.9 175c9.4-15 29.1-19.5 44.1-10.2l60.2 37.6C416.7 234.7 448 291.2 448 352v8c0 83.9-68.1 152-152 152H120c-13.3 0-24-10.7-24-24s10.7-24 24-24h92c6.6 0 12-5.4 12-12s-5.4-12-12-12H88c-13.3 0-24-10.7-24-24s10.7-24 24-24H212c6.6 0 12-5.4 12-12s-5.4-12-12-12H56c-13.3 0-24-10.7-24-24s10.7-24 24-24H212c6.6 0 12-5.4 12-12s-5.4-12-12-12H88c-13.3 0-24-10.7-24-24s10.7-24 24-24H224l0 0 0 0h93.2L271 219.1c-15-9.4-19.5-29.1-10.2-44.1z"/>
		</svg>,
		rainbow: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
			<path
				d="M320 96C178.6 96 64 210.6 64 352v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V352C0 175.3 143.3 32 320 32s320 143.3 320 320v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V352C576 210.6 461.4 96 320 96zm0 192c-35.3 0-64 28.7-64 64v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V352c0-70.7 57.3-128 128-128s128 57.3 128 128v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V352c0-35.3-28.7-64-64-64zM160 352v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V352c0-123.7 100.3-224 224-224s224 100.3 224 224v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V352c0-88.4-71.6-160-160-160s-160 71.6-160 160z"/>
		</svg>,
		dogbone: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
			<path
				d="M153.7 144.8c6.9 16.3 20.6 31.2 38.3 31.2H384c17.7 0 31.4-14.9 38.3-31.2C434.4 116.1 462.9 96 496 96c44.2 0 80 35.8 80 80c0 30.4-17 56.9-42 70.4c-3.6 1.9-6 5.5-6 9.6s2.4 7.7 6 9.6c25 13.5 42 40 42 70.4c0 44.2-35.8 80-80 80c-33.1 0-61.6-20.1-73.7-48.8C415.4 350.9 401.7 336 384 336H192c-17.7 0-31.4 14.9-38.3 31.2C141.6 395.9 113.1 416 80 416c-44.2 0-80-35.8-80-80c0-30.4 17-56.9 42-70.4c3.6-1.9 6-5.5 6-9.6s-2.4-7.7-6-9.6C17 232.9 0 206.4 0 176c0-44.2 35.8-80 80-80c33.1 0 61.6 20.1 73.7 48.8z"/>
		</svg>,
	}

	if (companyDetails == null) {
		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					height: '100vh',
				}}
			>
				<CircularProgress/>
			</div>
		);
	}

	const fetchData = async () => {
		try {
			var requestBody = {
				"CompanyId": companyDetails.Id,
				"RequesterUserId": loginData.UserId
			}
			const response = await ApiServiceClass.GetRewardDetails(requestBody);
			setRewards(response.body);
			setIsReward(true)

			setNoPurchases(response.body.PurchasesRequired)
			setRewardName(response.body.Name)
			setRewardSubtitle(response.body.SubTitle)
			setBackgroundColor(response.body.BackgroundColor)
			setStampBackgroundColor(response.body.StampBackgroundColor)
			setStampColor(response.body.StampColor)
			setStampName(response.body.SelectedStampName)

		} catch (error) {
			// Handle error
			console.error('Error fetching data:', error);
		}
	};

	const saveReward = async () => {
		console.log('isReward' + isReward)
		try {
			if (isReward) {
				var requestBody = {
					"RewardId": rewards.Id,
					"CompanyId": companyDetails.Id,
					"Description": rewardName,
					"NumberOfPurchasesRequired": noPurchases,
					"RequesterUserId": loginData.UserId,
					"SubTitle": rewardSubtitle
				};
				const response = await ApiServiceClass.EditStampReward(requestBody);
			} else {
				var requestBody = {
					"CompanyId": companyDetails.Id,
					"Description": rewardName,
					"NumberOfPurchasesRequired": noPurchases,
					"RequesterUserId": loginData.UserId,
					"SubTitle": rewardSubtitle
				}
				console.log(requestBody)
				const response = await ApiServiceClass.CreateStampReward(requestBody);
			}
		} catch (error) {
			console.error('Error fetching data:', error);
		}
		fetchData();
	};

	return (
		<Navbar name="Your Rewards">
			<Box component="main"
				 sx={{
					 flexGrow: 1,
					 marginTop: '20px',
					 width: "100%",
					 backgroundColor: "#FFFFFF",
					 borderRadius: "50px",
					 overflow: "auto",
				 }}>
				<Box
					component="div"
					sx={{
						// mt: 8,
						mb: 4,
						maxWidth: '1200px',
						display: "flex",
						alignItems: "center",
						width: "100%",
					}}>
					<Box
						sx={{display: "flex", justifyContent: "center", width: '40%',}}>
						<RewardsLeftPanel
							stamp={stamps[stampName]}
							stampName={stampName}
							stampColor={stampColor}
							stampBackgroundColor={stampBackgroundColor}
							backgroundColor={backgroundColor}
							rewardName={rewardName}
							rewardSubtitle={rewardSubtitle}
							noPurchases={noPurchases}
						/>
					</Box>
					<Box sx={{width: '60%',}}>
						<RewardsRightPanel
							stamps={stamps}
							stampName={stampName}
							setStampName={setStampName}
							stampColor={stampColor}
							setStampColor={setStampColor}
							rewardName={rewardName}
							setRewardName={setRewardName}
							rewardSubtitle={rewardSubtitle}
							setRewardSubtitle={setRewardSubtitle}
							backgroundColor={backgroundColor}
							setBackgroundColor={setBackgroundColor}
							stampBackgroundColor={stampBackgroundColor}
							setStampBackgroundColor={setStampBackgroundColor}
							noPurchases={noPurchases}
							setNoPurchases={setNoPurchases}
							saveReward={saveReward}
						/>
					</Box>
				</Box>
			</Box>
		</Navbar>
	);
}
