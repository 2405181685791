import React, {useState} from "react";
import {Box, Collapse, ListItemButton, ListItemText} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import logoSvg from "../tools/icons/logo.svg";
import ArrowLeft from "../tools/icons/arrow-left-stop.svg";
import ArrowRight from "../tools/icons/arrow-right-stop.svg";
import DashboardIcon from "../tools/icons/dashboard-icon.svg";
import EmployeeIcon from "../tools/icons/employee-icon.svg";
import CustomerIcon from "../tools/icons/customer-icon.svg";
import ReportsIcon from "../tools/icons/reports-icon.svg";
import DesignIcon from "../tools/icons/brand-icon.svg";
import SettingIcon from "../tools/icons/settings-icon.svg";
import UpgradeIcon from "../tools/icons/upgrade-icon.svg";
import HelpIcon from "../tools/icons/help-icon.svg";
import MarketIcon from "../tools/icons/satellite-dish.svg";
import RocketIcon from "../tools/icons/rocket.svg";
import VpnKeyIcon from "../tools/icons/voucher.svg";
import NotificationsIcon from "../tools/icons/bell.svg";
import SmsIcon from "../tools/icons/comments.svg";
import LogoutButton from "./logout-button";
import useIsMobile from "../components/mobile";

const NavBarItems = ({name, toggleDrawer, navOpen = true}) => {
	const [marketingOpen, setMarketingOpen] = useState(false);
	const isMobile = useIsMobile();

	const handleMarketingClick = () => {
		setMarketingOpen(!marketingOpen);
	};

	const [menuItems] = useState([
		{href: "/Dashboard", icon: DashboardIcon, label: "Dashboard"},
		{href: "/Employees", icon: EmployeeIcon, label: "Employees"},
		{href: "/Customers", icon: CustomerIcon, label: "Customers"},
		{href: "/Statistics", icon: ReportsIcon, label: "Statistics"},
		{href: "/Publish", icon: RocketIcon, label: "Publish Center", visible: true},
		{href: "/myapp", icon: DesignIcon, label: "My App"},
		{
			href: "#",
			icon: MarketIcon,
			label: "Marketing",
			submenu: [
				{href: "/Marketing/Vouchers", icon: VpnKeyIcon, label: "Vouchers"},
				{href: "/Marketing/PushNotifications", icon: NotificationsIcon, label: "Notifications"},
				{href: "/Marketing/SMS", icon: SmsIcon, label: "SMS"},
			],
		},
		{href: "/Help", icon: HelpIcon, label: "Help & Support"},
	]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				height: "100%", // Full height of the parent
				overflow: "hidden", // Prevent overflow
			}}
		>
			{/* Header Section */}
			<a href="/Dashboard" style={{textDecoration: "none", color: "inherit"}}>
				<Box
					sx={{
						padding: 0,
						width: "100%",
						marginLeft: "13px",
						paddingRight: 3,
						position: "relative",
						marginTop: "3%",
						maxWidth: "100%",
						marginBottom: "0%",
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="32"
						height="32"
						style={{marginRight: "8px"}}
					>
						<image href={logoSvg} width="32" height="32" alt="Logo"/>
					</svg>

					{navOpen ? (
						<span
							style={{
								fontFamily: "Poppins-SemiBold",
								fontSize: "22px",
								color: "#0260FE",
								whiteSpace: "nowrap",
							}}
						>
              Reward Space
            </span>
					) : null}
				</Box>
			</a>

			{/* Mobile Toggle Button */}
			{isMobile ? (
				<a style={{textDecoration: "none", color: "inherit"}}>
					<Box
						sx={{
							padding: 0,
							width: "100%",
							marginLeft: "13px",
							position: "relative",
							marginTop: "10%",
							maxWidth: "100%",
							display: "flex",
							justifyContent: navOpen ? "flex-end" : "left",
							flexDirection: "row",
							alignItems: "center",
							cursor: "pointer",
						}}
						onClick={toggleDrawer}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="30"
							height="30"
							style={{marginRight: "8px"}}
						>
							{navOpen ? (
								<image href={ArrowLeft} width="30" height="30" alt="Close Nav"/>
							) : (
								<image href={ArrowRight} width="30" height="30" alt="Open Nav"/>
							)}
						</svg>
					</Box>
				</a>
			) : null}

			{/* Main Content Section */}
			<Box
				sx={{
					flexGrow: 1,
					overflowY: "auto", // Make this section scrollable
					marginTop: "10%",
					minHeight: "375px",
					overflow: "hidden", // Prevent overflow
				}}
			>
				{menuItems
					.filter((item) => item.visible !== false)
					.map((item, index) => (
						<React.Fragment key={index}>
							<ListItemButton
									href={item.href}
								variant="outlined"
								onClick={item.submenu ? handleMarketingClick : undefined}
								sx={{
									background: name === item.label ? "#f6f6f6" : "initial",
								}}
							>
								<Box
									sx={{
										width: "100%",
										maxWidth: "100%",
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
									}}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										style={{display: "flex", alignItems: "center"}}
									>
										<image href={item.icon} width="20" height="20" alt={item.label}/>
									</svg>
									{navOpen ? (
										<ListItemText
											primary={item.label}
											primaryTypographyProps={{
												fontFamily: "Poppins-Medium",
												color: name === item.label ? "#0260FE" : "#171663",
												marginLeft: "20px",
												fontSize: "15.5px",
												alignItems: "center",
											}}
										/>
									) : null}

									{item.label === "Marketing" &&
										navOpen &&
										(marketingOpen ? <ExpandLess/> : <ExpandMore/>)}
								</Box>
							</ListItemButton>
							{item.submenu && (
								<Collapse in={marketingOpen} timeout="auto" unmountOnExit>
									{item.submenu.map((subitem, subindex) => (
										<ListItemButton
											key={subindex}
											href={subitem.href}
											sx={{
												pl: 4,
												paddingTop: 0,
												paddingBottom: 0,
												marginBottom: "5px",
											}}
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="20"
												height="20"
												style={{display: "flex", alignItems: "center"}}
											>
												<image href={subitem.icon} width="20" height="20" alt={subitem.label}/>
											</svg>
											<ListItemText
												primary={subitem.label}
												primaryTypographyProps={{
													fontFamily: "Poppins-Medium",
													color: "#4A47A3",
													marginLeft: "20px",
													fontSize: "14.5px",
													alignItems: "center",
												}}
											/>
										</ListItemButton>
									))}
								</Collapse>
							)}
						</React.Fragment>
					))}
			</Box>

			{/* Footer Section */}
			<Box sx={{paddingBottom: 0, marginTop: "auto"}}>
				{[
					{href: "/Upgrade", icon: UpgradeIcon, label: "Upgrade"},
					{href: "/Settings", icon: SettingIcon, label: "Settings"},
				].map((item, index) => (
					<ListItemButton key={index} href={item.href} variant="outlined">
						<Box sx={{display: "flex", alignItems: "center", width: "100%"}}>
							<svg width="20" height="20">
								<image href={item.icon} width="20" height="20" alt={item.label}/>
							</svg>
							{navOpen && (
								<ListItemText
									primary={item.label}
									primaryTypographyProps={{
										fontFamily: "Poppins-Medium",
										color: name === item.label ? "#0260FE" : "#171663",
										marginLeft: "20px",
										fontSize: "15.5px",
									}}
								/>
							)}
						</Box>
					</ListItemButton>
				))}

				<LogoutButton navOpen={navOpen}/>
			</Box>
		</Box>
	);
};

export default NavBarItems;