import {Button, Dialog, DialogActions, DialogContent, DialogTitle,} from "@mui/material";

import {SettingsAccountPanel} from "../components/settings-account-panel.js";
import {SettingsSubscriptionPanel} from "../components/settings-subscriptions-panel.js";
import {SettingsOtherPanel} from "../components/settings-other-panel.js";
import Navbar from "./navbar.js";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {styled} from "@mui/system";
import {ApiServiceClass} from "../components/api-service.js";
import {toast} from "react-toastify";
import Getcookies from "../components/getcookies";
import LoadingPage from "./loadingpage";

export default function Settings() {
	let navigate = useNavigate();
	const cookies = Getcookies();
	const [loading, setLoading] = useState(true);
	const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const handleDeleteOpen = () => setDeleteDialogOpen(true);
	const handleDeleteClose = () => setDeleteDialogOpen(false);

	const [isCancelDialogOpen, setCancelDialogOpen] = useState(false);
	const handleCancelOpen = () => setCancelDialogOpen(true);
	const handleCancelClose = () => setCancelDialogOpen(false);
	const [subscriptionId, setSubscriptionId] = useState("");
	const [subscriptionName, setSubscriptionName] = useState("");
	const [reloadPage, setReloadPage] = useState(false);
	const [subscriptionType, setSubscriptionType] = useState("");
	const [hasBeenCancelled, setHasBeenCancelled] = useState("");
	const [SubscriptionEndDateTime, setSubscriptionEndDateTime] = useState("");
	const [IsSubscriptionActive, setIsSubscriptionActive] = useState("");
	const [LastInvoiceDate, setLastInvoiceDate] = useState("");
	const CenteredDialogActions = styled(DialogActions)({
		display: "flex",
		justifyContent: "center",
	});

	const handleDelete = async () => {
		console.log("Handling delete account");
		var requestBody = {
			UserId: cookies.loginData.UserId,
		};
		console.log(requestBody);
		try {
			var response = await ApiServiceClass.DeleteAccount(requestBody);
			console.log(response); // Log the response for debugging
			if (response.status === 200) {
				toast.success("Account deleted");
				ApiServiceClass.logout("", "", false);
			} else {
				toast.error(`Error deleting account: ${response.body}`);
			}
		} catch (error) {
			console.error("Error deleting account:", error);
		}
	};

	const handleCancelYes = async () => {
		try {
			var requestBody = {
				CompanyId: cookies.companyData.Id,
			};
			console.log("Cancelling subscription");
			const response = await ApiServiceClass.CancelSubscription(requestBody);
			setCancelDialogOpen(false);
			if (response.status === 200) {
				toast.success("Subscription cancelled", {
					autoClose: 2500,
				});
				setReloadPage(true);
			} else {
				toast.error("Error cancelling subscription");
			}
		} catch (error) {
			// Consolidated error handling
			const statusCode = error.response?.status || error.status || "Unknown";
			const errorMessage = `Error Publishing App: ${statusCode}`;

			console.error(errorMessage, error);
			toast.error(errorMessage, {autoClose: 1500});
		}
	};

	const GetSubscription = async () => {
		try {
			const requestBody = {
				CompanyId: cookies.companyData.Id,
			};
			const response = await ApiServiceClass.GetSubscription(requestBody);
			if (response.status === 200) {
				setSubscriptionName(response.body.SubscriptionTypeName);
				setSubscriptionType(response.body.SubscriptionType);
				setIsSubscriptionActive(response.body.IsSubscriptionActive);
				setHasBeenCancelled(response.body.HasBeenCancelled);
				setSubscriptionEndDateTime(response.body.SubscriptionEndDateTime);
				setLastInvoiceDate(response.body.LastInvoiceDate);
				setSubscriptionId(response.body.StripeSubscriptionId);
				setLoading(false);
			} else {
				toast.error("Error fetching settings information");
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	useEffect(() => {
		GetSubscription();
	}, [setHasBeenCancelled, setSubscriptionId, reloadPage]);


	const handlePasswordChange = () => {
		// Navigate to the internal page when the button is clicked
		navigate("/reset"); // Replace "/your-internal-page" with the actual path of your internal page
	};

	const handleClickDelete = () => {
		console.log("Handle click delete");
		setDeleteDialogOpen(true);
	};

	const handleCancelSubscription = () => {
		console.log("Handle cancel subscription");
		setCancelDialogOpen(true);
	};

	const handleUpgradeClick = () => {
		console.log("Upgrade");
		navigate("/upgrade");
	};

	if (loading) {
		return <LoadingPage></LoadingPage>
	}

	return (
		<Navbar name="Settings">
			<div style={{padding: "2%", width: "98%", minWidth: "500px"}}>
				<SettingsAccountPanel
					title={"Account Settings"}
					subheader={"Manage your account"}
					emailAddress={cookies.loginData.EmailAddress}
					handlePasswordChange={handlePasswordChange}
					handleClickDelete={handleClickDelete}
				></SettingsAccountPanel>
				<SettingsSubscriptionPanel
					title={"Subscription Settings"}
					subheader={"Manage your subscription"}
					handleUpgradeClick={handleUpgradeClick}
					handleCancelSubscription={handleCancelSubscription}
					SubscriptionType={subscriptionType}
					SubscriptionPlan={subscriptionName}
					hasBeenCancelled={hasBeenCancelled}
					SubscriptionEndDateTime={SubscriptionEndDateTime}
					IsSubscriptionActive={IsSubscriptionActive}
					LastInvoiceDate={LastInvoiceDate}
				></SettingsSubscriptionPanel>
				<SettingsOtherPanel
					title={"Other Settings"}
					subheader={"Manage any other settings"}
				></SettingsOtherPanel>
				<Dialog
					open={isDeleteDialogOpen}
					onClose={handleDeleteClose}
					PaperProps={{style: {borderRadius: "5px", width: "400px"}}}
				>
					<DialogTitle style={{paddingBottom: "5px", textAlign: "center"}}>
						Warning!
					</DialogTitle>
					<DialogContent
						style={{
							paddingBottom: "15px",
							textAlign: "center", // Aligns text horizontally in the center
						}}
					>
						Are you sure you want to delete your account?
						<br/>
						<br></br>
						<b>This action cannot be undone</b>
					</DialogContent>
					<CenteredDialogActions
						style={{
							paddingBottom: "25px",
							alignItems: "center",
							justifyContent: "center",
							textAlign: "center", // Aligns text horizontally in the center
						}}
					>
						<Button
							onClick={handleDelete}
							color="primary"
							style={{width: "30%", marginRight: "35px", borderRadius: "5px"}}
						>
							Yes
						</Button>
						<Button
							onClick={handleDeleteClose}
							color="primary"
							style={{width: "30%", borderRadius: "5px"}}
						>
							No
						</Button>
					</CenteredDialogActions>
				</Dialog>

				<Dialog
					open={isCancelDialogOpen}
					onClose={handleCancelClose}
					PaperProps={{style: {borderRadius: "5px", width: "400px"}}}
				>
					<DialogTitle style={{paddingBottom: "5px", textAlign: "center"}}>
						Are you sure?
					</DialogTitle>
					<DialogContent
						style={{
							paddingBottom: "15px",
							textAlign: "center", // Aligns text horizontally in the center
						}}
					>
						Are you sure you want to cancel your subscription?
						<br/>
					</DialogContent>
					<CenteredDialogActions
						style={{
							paddingBottom: "25px",
							alignItems: "center",
							justifyContent: "center",
							textAlign: "center", // Aligns text horizontally in the center
						}}
					>
						<Button
							onClick={handleCancelYes}
							color="primary"
							style={{width: "30%", marginRight: "35px", borderRadius: "5px"}}
						>
							Yes
						</Button>
						<Button
							onClick={handleCancelClose}
							color="primary"
							style={{width: "30%", borderRadius: "5px"}}
						>
							No
						</Button>
					</CenteredDialogActions>
				</Dialog>
			</div>
		</Navbar>
	);
}
