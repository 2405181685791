import { Box, CircularProgress } from "@mui/material";
import React from "react";
import Cookies from "js-cookie";

const LoadingPage = () => {
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				height: "100vh",
			}}
		>
			<CircularProgress />
		</Box>
	);
};

export default LoadingPage;
