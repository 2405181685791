export const prices = [
	{
		title: "Lite",
		monthlyPrice: "£49.99",
		id: "price_1PKpS6KYFd22hVPvNbACrIWy",
		description: [
			"Custom iOS & Android app",
			"Employee Management system",
			"Up to 1000 users",
			"Basic support (email)",
			"Customer data",
		],
		buttonText: "Confirm",
	},
	{
		title: "Professional",
		subheader: "Most Popular",
		id: "price_1PKpRyKYFd22hVPvKj5SKG5q",
		monthlyPrice: "£99.99",
		description: [
			"Custom iOS & Android app",
			"Employee Management system",
			"Unlimited users",
			"Full support (email, phone)",
			"Customer data",
			"Voucher management",
			"Push notifications",
			"Email Marketing",
		],
		buttonText: "Confirm",
	},
	{
		title: "Premium",
		monthlyPrice: "£149.99",
		id: "price_1PKSc1KYFd22hVPvRq3O9aDq",


		description: [
			"Custom iOS & Android app",
			"Employee Management system",
			"Unlimited users",
			"Full support (email, phone)",
			"Customer data",
			"Voucher management",
			"Push notifications",
			"Email Marketing",
			"SMS",
			"Dedicated account manager",
		],
		buttonText: "Confirm",
	},
];

export default prices;
