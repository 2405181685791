const Styles = {
	panel: {
		display: "flex",
		flexDirection: "column",
		height: "95vh", // Full viewport height
	},
	contentWrapper: {
		flex: "auto",
		overflowY: "auto",
		paddingBottom: "120px",
		// backgroundColor: "#b01025",
	},
	content: {
		display: "flex",
		flexDirection: "column",
		padding: "1% 5% 1% 5%", //top, right , bottom, left
		//backgroundColor: "#000000",
		marginTop: "10px",
		color: "#000000",
		fontSize: "15px",
	},
	contentGrid: {
		display: "grid",
		gridTemplateColumns: "1fr 1fr", // 2 columns of equal width
		gap: "0px", // Gap between grid items
		flexDirection: "column",
		padding: "0.5% 5% 1% 5%", //top, right , bottom, left
		//backgroundColor: "#000000",
		marginTop: "10px",
		color: "#000000",
		fontSize: "15px",
	},
	gridItemLeft: {
		display: "flex",
		flexDirection: "column", // Aligns text above the component
		width: "100%",
		padding: "0% 5% 1% 0%", //top, right , bottom, left
		marginBottom: "2%",
		color: "#000000",
		borderRadius: "8px",
		fontFamily: "Poppins-Medium",
	},
	gridItemRight: {
		display: "flex",
		flexDirection: "column", // Aligns text above the component
		width: "100%",
		padding: "0% 5% 1% 0%", //top, right , bottom, left
		marginLeft: "7%",
		marginBottom: "2%",
		color: "#000000",
		borderRadius: "8px",
		fontFamily: "Poppins-Medium",
	},
	gridItemFull: {
		display: "flex",
		flexDirection: "column", // Aligns text above the component
		width: "100%",
		padding: "0% 5% 1% 0%", //top, right , bottom, left
		marginBottom: "2%",
		color: "#000000",
		borderRadius: "8px",
		fontFamily: "Poppins-Medium",
	},
	sectionTitle: {
		color: "#333333", // Dark gray for a softer and more modern appearance
		display: "flex",
		justifyContent: "space-between", // Distribute content evenly
		alignItems: "center", // Vertically center content
		backgroundColor: "#E4E4E4", // Very light gray/blue tone for a cooler contrast
		padding: "5px 0 5px 5%", // Padding with more top and bottom space
		fontSize: "16px", // Moderate size for better readability
		fontFamily: "Poppins-Medium",
		boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
		height: "auto", // Allow dynamic height for content
	},
	colorBox: {
		width: "90%",
		height: "60px", // Ensure parent height is set
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
		marginBottom: "3%", // Margin outside the box
		marginTop: "2%", // Margin outside the box
		borderRadius: "8px",
		position: "relative",
		padding: "1%",
		color: "white",
		border: "2px solid #000000",
		flexShrink: 0, // Prevents shrinking in flexbox layout
		boxSizing: "border-box", // Ensures padding is included in the width and height
	},
	numberBox: {
		width: "90%",
		height: "70px", // Ensure parent height is set
		display: "flex",
		flexDirection: "column",
		marginBottom: "3%", // Margin outside the box
		borderRadius: "8px",
		position: "relative",
		padding: "0%",
		paddingTop: "2%",
		flexShrink: 0, // Prevents shrinking in flexbox layout
		boxSizing: "border-box", // Ensures padding is included in the width and height
	},
	stampBox: {
		width: "90%",
		height: "150px", // Ensure parent height is set
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center", // Center content vertically
		marginBottom: "3%", // Margin outside the box
		marginTop: "2%", // Margin outside the box
		borderRadius: "8px",
		backgroundColor: "rgba(217, 217, 217, 0.3)",
		border: "4px solid #E4E4E4",
		position: "relative",
		padding: "1%",
		flexShrink: 0, // Prevents shrinking in flexbox layout
		boxSizing: "border-box", // Ensures padding is included in the width and height
	},
	stampColors: {
		width: "90%",
		height: "110px", // Ensure parent height is set
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center", // Center content vertically
		marginBottom: "3%", // Margin outside the box
		marginTop: "1%", // Margin outside the box
		borderRadius: "12px",
		position: "relative",
		padding: "1%",
		flexShrink: 0, // Prevents shrinking in flexbox layout
		boxSizing: "border-box", // Ensures padding is included in the width and height
	},
	pickerWrapper: {
		position: "absolute",
		right: "1230px", // Adjust this value based on the ChromePicker's width
		top: "80px",
		zIndex: 2, // Ensure the picker is above other elements
	},
	colorText: {
		color: "#000000",
	},
	colorCode: {
		fontSize: "14px",
		color: "#555",
	},
	penIcon: {
		position: "absolute",
		top: "5px",
		right: "10px",
	},
	penIcon2: {
		position: "absolute",
		top: "-5px",
		left: "120px",
	},
	actions: {
		display: "flex",
		justifyContent: "space-between",
		padding: "2%",
		borderTop: "1px solid #ccc",
		backgroundColor: "#f9f9f9",
		position: "sticky",
		bottom: 0,
		paddingLeft: "4.8%",
		paddingRight: "4.8%",
		minHeight: "58px",
		marginBottom: "20px"
	},
	normalBtn: {
		backgroundColor: "#0260FE",
		color: "white",
		border: "none",
		padding: "10px 10px",
		cursor: "pointer",
		borderRadius: "5px",
		width: "35%",
	},
	resetBtn: {
		backgroundColor: "#dc3545",
		color: "white",
		border: "none",
		padding: "10px 20px",
		cursor: "pointer",
		borderRadius: "5px",
		width: "20%",
		display: "inline-flex", // To ensure content is aligned inline
		alignItems: "center", // Vertically center the icon and text
		justifyContent: "center", // Centers the text and icon horizontally
		fontFamily: "Poppins-Medium",
	},
	applyBtn: {
		backgroundColor: "#28a745",
		color: "white",
		border: "none",
		padding: "10px 20px",
		cursor: "pointer",
		borderRadius: "5px",
		width: "20%",
		fontFamily: "Poppins-Medium",
		"&:disabled": {
			backgroundColor: "gray", // Disabled state background
			color: "lightgray", // Disabled state color
		},
	},
};

export default Styles;
