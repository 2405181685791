import PropTypes from "prop-types";
import {Box, Card, CardContent, Divider, Typography,} from "@mui/material";
// import { Chart } from "../components/chart.js";
import {styled, useTheme} from "@mui/material/styles";
import ApexChart from "react-apexcharts";

export const Chart = styled(ApexChart)``;

const useChartOptions = (xAxisCategories) => {
	const theme = useTheme();
	let columnWidth = xAxisCategories.length < 20 ? "60px" : "30px";

	return {
		chart: {
			background: "transparent",
			stacked: false,
			toolbar: {
				show: false,
			},
		},
		colors: [
			theme.palette.primary.main,
			theme.palette.secondary.main, // Change to secondary color
		],
		dataLabels: {
			enabled: false,
		},
		fill: {
			opacity: 1,
			type: "solid",
		},
		grid: {
			borderColor: theme.palette.divider,
			strokeDashArray: 1,
			xaxis: {
				lines: {
					show: false,
				},
			},
			yaxis: {
				lines: {
					show: true,
				},
			},
		},
		legend: {
			show: false,
		},
		plotOptions: {
			bar: {
				columnWidth: columnWidth,
			},
		},
		theme: {
			mode: theme.palette.mode,
		},
		xaxis: {
			axisBorder: {
				color: theme.palette.divider,
				show: true,
			},
			axisTicks: {
				color: theme.palette.divider,
				show: true,
			},
			categories: xAxisCategories,
			labels: {
				offsetY: 1,
				style: {
					colors: theme.palette.text.secondary,
				},
			},
		},
		yaxis: {
			labels: {
				formatter: (value) => {
					if (value === Infinity) {
						return "na";
					} else if (value >= 10000) {
						return `${Math.floor(value / 1000)}K`;
					} else {
						return `${value}`;
					}
				},
				offsetX: -10,
				style: {
					colors: theme.palette.text.secondary,
				},
			},
		},
	};
};

export const OverviewGraph = (props) => {
	const theme = useTheme();
	const {graphName, xAxisCategories, chartSeries, sx} = props;
	const chartOptions = useChartOptions(xAxisCategories);

	const hasData = chartSeries.some((series) =>
		series.data.some((value) => value !== 0)
	);

	return (
		<Card
			sx={{
				...sx,
				minHeight: "380px",
				width: "100%",
				//backgroundColor: "#429042",
			}}
		>
			<CardContent>
				<Typography
					variant="outlined"
					sx={{
						paddingLeft: "6px",
						paddingTop: "1px",
					}}
				>
					{graphName}
				</Typography>
				<Box position="relative">
					<Chart
						height={400}
						options={chartOptions}
						series={chartSeries}
						type="bar"
						width="100%"
					/>

					{!hasData && (
						<Typography
							variant="body1"
							color="textSecondary"
							sx={{
								position: "absolute",
								top: "50%",
								left: "50%",
								transform: "translate(-50%, -50%)",
								textAlign: "center",
							}}
						>
							No data
						</Typography>
					)}
				</Box>
			</CardContent>
			<Divider/>
		</Card>
	);
};

OverviewGraph.propTypes = {
	graphName: PropTypes.string,
	xAxisCategories: PropTypes.array,
	chartSeries: PropTypes.array.isRequired,
	sx: PropTypes.object,
};
