import React, {useEffect, useState} from "react";
import {SvgIcon} from "@mui/material";
import Navbar from "./navbar.js";
import {ApiServiceClass} from "../components/api-service.js";
import NewsSection from "../components/news-section.js";
import NewsContent from "../components/news-content";
import DashboardCard from "../components/dashboard-card.js";
import DoneIcon from "@mui/icons-material/Done";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import AndroidIcon from "../tools/icons/android-store.png";
import AppleIcon from "../tools/icons/ios-store.png";
import MailIcon from "../tools/icons/envelope.png";
import InvoiceIcon from "../tools/icons/invoice.png";
import ProgressBar from "@ramonak/react-progress-bar";
import LoadingPage from "./loadingpage";
import Getcookies from "../components/getcookies.js";
// Status enums
const STATUS_ENUMS = {
	IOS: {
		0: "None", 1: "Live", 2: "Down", 3: "Testing"
	},
	ANDROID: {
		0: "None", 1: "Live", 2: "Down", 3: "Testing"
	},
	SUBSCRIPTION: {
		0: "None", 1: "Lite", 2: "Standard", 3: "Professional"
	}
};

export default function Dashboard() {
	const [dashboard, setDashboard] = useState([]);
	const [newsItems, setNewsItems] = useState([]);
	const [loading, setLoading] = useState(true);
	const cookies = Getcookies();

	// Style constants
	const containerStyles = {
		main: {
			display: "flex",
			flexDirection: "column",
			gap: "2rem",
			height: "85vh", // Ensure it takes full height
			overflow: "hidden", // Prevent unnecessary scrollbars
			fontFamily: "Poppins-Medium",
		},
		topCards: {
			display: "flex",
			gap: "1%",
			justifyContent: "space-between",
			flexWrap: "wrap",
			marginLeft: "-10px",
		},
		contentArea: {
			display: "flex",
			gap: "6%",
			height: "60vh",
			minHeight: 400
		},
		actionBox: {
			flex: 1,
			minWidth: 300,
			background: "#fff",
			borderRadius: 12,
			padding: 15,
			boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
		},
		newsBox: {
			flex: 1,
			minWidth: 300,
			background: "#fff",
			borderRadius: 12,
			padding: 15,
			overflowY: "auto",
			boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
		}
	};

	useEffect(() => {
		setNewsItems(NewsContent.getNewsItems());
		const fetchData = async () => {
			try {
				const requestBody = {
					CompanyId: cookies.companyData.Id,
				};
				const response = await ApiServiceClass.GetDashboardInformation(requestBody);
				if (response.status === 200) {
					setDashboard(response.body);
					setLoading(false);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();
	}, []);


	const StatusItem = ({href, icon, title, description, isLast}) => {
		const IconComponent = icon === DoneIcon ? DoneIcon : ErrorOutlineIcon;

		return (
			<a href={href} style={listItemStyle(isLast)}>
				<SvgIcon
					component={IconComponent}
					style={{
						color: icon === DoneIcon ? "green" : "orange",
						fontSize: 24,
						marginRight: 8,
						paddingBottom: 2
					}}
				/>
				<div style={{overflow: "hidden"}}>
					<div style={{fontSize: "16px"}}>{title}</div>
					<div style={descriptionStyle}>{description}</div>
				</div>
			</a>
		);
	};

	if (loading) {
		return <LoadingPage></LoadingPage>
	}

	return (
		<Navbar name="Dashboard" title="Dashboard">
			<div style={{padding: "3%"}}>
				<div style={containerStyles.main}>
					<div style={containerStyles.topCards}>
						<DashboardCard
							title={`iOS Version: ${dashboard.IOSVersion}`}
							title={`iOS Version: ${dashboard.IOSVersion}`}
							subtext={`Status: ${STATUS_ENUMS.IOS[dashboard.IOSStatus]}`}
							iconSrc={AppleIcon}
							buttonText="Publish Center"
							buttonColour="#3464eb"
							imageSize={42}
							buttonHref="/Publish"
						/>
						<DashboardCard
							title={`Android Version: ${dashboard.AndroidVersion}`}
							subtext={`Status: ${STATUS_ENUMS.ANDROID[dashboard.AndroidStatus]}`}
							iconSrc={AndroidIcon}
							buttonText="Publish Center"
							buttonColour="#e6453e"
							imageSize={42}
							buttonHref="/Publish"
						/>
						<DashboardCard
							title="Email us"
							subtext="Got a question or suggestion?"
							iconSrc={MailIcon}
							buttonText="Get in touch"
							buttonColour="#21807e"
							imageSize={46}
							buttonHref="/contact"
						/>
						<DashboardCard
							title="My Subscription"
							subtext={`Your Subscription: ${STATUS_ENUMS.SUBSCRIPTION[dashboard.SubscriptionType]}`}
							iconSrc={InvoiceIcon}
							buttonText="Manage my subscription"
							buttonColour="#9f59cf"
							imageSize={46}
							buttonHref="/upgrade"
						/>
					</div>

					{/* Main Content Area */}
					<div style={containerStyles.contentArea}>
						{/* Actions Box */}
						<div style={containerStyles.actionBox}>
							<div style={{fontSize: "18px", marginBottom: "5px"}}>Actions completed</div>
							<ProgressBar
								completed={dashboard.ActionsPercent || 0}
								bgColor="#2D62F9"
								height={18}
								labelSize={12}
							/>
							<div style={{marginBottom: "10px"}}></div>
							<StatusItem
								href="/myapp"
								icon={dashboard.RewardSaved ? DoneIcon : ErrorOutlineIcon}
								title="Create your own design"
								description="Head to the design page and make edits to your own mobile app"
							/>
							<StatusItem
								href="/upgrade"
								icon={dashboard.SubscriptionUpgraded ? DoneIcon : ErrorOutlineIcon}
								title="Setup a subscription"
								description="Get started on one of our plans and grow your business."
							/>
							<StatusItem
								href="/publish"
								icon={dashboard.AndroidStatus > 0 ? DoneIcon : ErrorOutlineIcon}
								title="Publish your app"
								description="Get your app published to the iOS and Android stores."
							/>
							<StatusItem
								href="/statistics"
								icon={dashboard.firstPushNotification ? DoneIcon : ErrorOutlineIcon}
								title="Send a push notification"
								description="Send your first notification to app users."
								isLast
							/>
						</div>

						{/* News Box */}
						<div style={containerStyles.newsBox}>
							<NewsSection newsItems={newsItems}/>
						</div>
					</div>
				</div>
			</div>
		</Navbar>
	);
}

// Style constants moved outside component
const listItemStyle = (isLast) => ({
	display: "flex",
	alignItems:
		"center",
	padding:
		12,
	borderBottom:
		isLast ? "none" : "1px solid #ddd",
	textDecoration:
		"none",
	color:
		"inherit",
	transition:
		"background-color 0.3s",
	minHeight:
		60,
	"&:hover":
		{backgroundColor: "#f0f0f0"}
});

const descriptionStyle = {
	color: "#666",
	display:
		"-webkit-box",
	WebkitLineClamp:
		2,
	WebkitBoxOrient:
		"vertical",
	overflow:
		"hidden",
	fontSize:
		"15px"
};