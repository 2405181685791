import {useState} from "react";
import SidePanelCard from "./sidepanelcard";
import LogoContent from "./cards/LogoContent"; // Import the unique components
import SplashScreenContent from "./cards/SplashScreenContent";
import ColorsContent from "./cards/ColorsContent";
import FontsContent from "./cards/FontsContent";
import ButtonsContent from "./cards/ButtonsContent";
import NavigationContent from "./cards/NavigationContent";
import MiscellaneousContent from "./cards/MiscellaneousContent";
import LoyaltyContent from "./cards/LoyaltyContent";
import RewardContent from "./cards/RewardContent";
import ThemesContent from "./cards/ThemesContent";
import WarningBox from "./cards/sharedcomponents/WarningBox";
import {Button} from "@mui/material";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import ResetWarning from "./cards/sharedcomponents/ResetWarning";

function DesignSidePanel({
							 setActiveTab,
							 fonts,
							 onWidthChange,
							 designManager,
							 id,
							 selectedCard,
							 onCardSelect
						 }) {
	const [width, setWidth] = useState("30%"); // State to control panel width
	const [companyLogoLocked, setcompanyLogoLocked] = useState(false);
	const [launchScreenLocked, setlaunchScreenLocked] = useState(false);
	const [coloursLocked, setcoloursLocked] = useState(false);
	const [fontsLocked, setfontsLocked] = useState(false);
	const [buttonsLocked, setbuttonsLocked] = useState(false);
	const [navigationMenuLocked, setnavigationMenuLocked] = useState(false);
	const [loyaltyCardLocked, setloyaltyCardLockedLocked] = useState(false);
	const [rewardSchemeLocked, setrewardSchemeLocked] = useState(false);
	const [autoNavigate, setAutoNavigate] = useState(true);
	const [isWarningOpen, setWarningOpen] = useState(false); // State for WarningBox
	const [isResetDesignOpen, setResetDesignOpen] = useState(false);

	const handleAutoNavigateChange = (event) => {
		setAutoNavigate(event.target.checked);
	};

	const togglePanelToMin = () => {
		setWidth("4%");
		onWidthChange("4%");
	};

	const togglePanelToDefault = () => {
		setWidth("30%");
		onWidthChange("30%");
	};

	const contentStyle = {
		padding: "20px",
		display: "grid",
		gridTemplateColumns: "repeat(2, 1fr)",
		gridGap: "15px",
		height: "auto",
		//backgroundColor: "red"
	};


	const wholeSidePanel = {
		position: "fixed",
		minWidth: "260px",
		right: 0,
		width: width,
		height: "100vh",
		backgroundColor: "#f7f9fc",
		//backgroundColor: "#000000",
		boxShadow: "-2px 0 5px rgba(0,0,0,0.1)",
		transition: "width 0.1s",
		display: "flex",
		flexDirection: "column",
	};

	const sidepanelHeader = {
		width: "100%",
		maxHeight: "6%",
		minHeight: "6%",
		borderBottom: "1px solid #e0e0e0",
		display: "flex",
		flexDirection: "row", // Align children in a row
		justifyContent: "space-between", // Space between the child containers
		alignItems: "center", // Center items vertically
		//backgroundColor: "#000000"
	};

	const leftContainerStyle = {
		width: "15%",
		//backgroundColor: "red",
		zIndex: 10,
		marginLeft: "-12%",
	};
	const rightContainerStyle = {
		width: "100%",
		//backgroundColor: "pink",
		marginLeft: "-25%",
	};

	const backButtonStyle = {
		color: "#007bff",
		cursor: "pointer",
		//fontWeight: "bold",
		backgroundColor: "#0260FE", // Background color for visibility
		color: "#ffffff",
		borderRadius: "6px",
		fontSize: "14px",
		paddingLeft: "20%",
		paddingTop: "8%",
		minHeight: "34px",
		minWidth: "30px",
		marginLeft: "20%",
		alignItems: "center", // Center vertically
		fontFamily: "Poppins-Medium",
	};

	const selectCard = (card) => {
		console.log("Select card: ", card);
		switch (card) {
			case "Theme Library":
				if (companyLogoLocked) {
					console.log("Theme Library is locked.");
					return; // Exit the function if the card is locked
				}
				break;
			case "Company Logo":
				if (companyLogoLocked) {
					console.log("Company Logo is locked.");
					return; // Exit the function if the card is locked
				}
				setActiveTab("login");
				break;
			case "Splash screen":
				if (launchScreenLocked) {
					console.log("Splash screen is locked.");
					return;
				}
				setActiveTab("splash");
				break;
			case "Colours":
				if (coloursLocked) {
					console.log("Colors are locked.");
					return;
				}
				break;
			case "Fonts":
				if (fontsLocked) {
					console.log("Fonts are locked.");
					return;
				}
				break;
			case "Buttons":
				if (buttonsLocked) {
					console.log("Buttons Menu is locked.");
					return;
				}
				setActiveTab("login");
				break;
			case "Navigation Menu":
				if (navigationMenuLocked) {
					console.log("Navigation Menu is locked.");
					return;
				}
				break;
			case "Digital Loyalty Card":
				if (loyaltyCardLocked) {
					console.log("Digital Loyalty Card is locked.");
					return;
				}
				setActiveTab("card");
				break;
			case "Reward Scheme":
				if (rewardSchemeLocked) {
					console.log("Reward Scheme is locked.");
					return;
				}
				setActiveTab("home");
				break;
			case "Miscellaneous":
				//setActiveTab("misc");
				break;
			case "Reset":
				setResetDesignOpen(true);
				console.log(isResetDesignOpen);
				return;
			default:
				alert("Unknown card selected.");
				return;
		}

		onCardSelect(card); // Call the parent's handler instead of setting state directly
	};

	const handleBack = () => {
		console.log("Back clicked");
		if (designManager.hasUnsavedChanges()) {
			setWarningOpen(true);
		} else {
			onCardSelect(null);
		}
	};

	const handleBackWarningYes = () => {
		designManager.resetUnsavedChanges();
		onCardSelect(null); // Reset the selected card
		setWarningOpen(false); // Close the warning box
	};

	const closeBackWarning = () => {
		setWarningOpen(false); // Close WarningBox dialog
	};

	const handleResetPanel = () => {
		console.log("Panel reset clicked");
	};

	const handleResetDesignYes = () => {
		console.log("Design reset clicked");
		setResetDesignOpen(false); // Close the warning box
	};

	const closeResetDesign = () => {
		setResetDesignOpen(false);
	};

	const handleSave = () => {
		console.log("Save clicked");
	};

	const renderSelectedCard = () => {
		switch (selectedCard) {
			case "Theme Library":
				return (
					<ThemesContent
						fonts={fonts}
						designManager={designManager}
						onCancel={handleResetPanel}
						onSave={handleSave}
					/>
				);
			case "Company Logo":
				return (
					<LogoContent
						designManager={designManager}
						onCancel={handleResetPanel}
						onSave={handleSave}
					/>
				);
			case "Splash screen":
				return (
					<SplashScreenContent
						designManager={designManager}
						onCancel={handleResetPanel}
						onSave={handleSave}
					/>
				);
			case "Colours":
				return <ColorsContent designManager={designManager}/>;
			case "Fonts":
				return (
					<FontsContent
						fonts={fonts}
						designManager={designManager}
						onCancel={handleResetPanel}
						onSave={handleSave}
					/>
				);
			case "Buttons":
				return (
					<ButtonsContent
						fonts={fonts}
						designManager={designManager}
						onCancel={handleResetPanel}
						onSave={handleSave}
					/>
				);
			case "Navigation Menu":
				return <NavigationContent designManager={designManager}/>;
			case "Digital Loyalty Card":
				return (
					<LoyaltyContent
						designManager={designManager}
						onCancel={handleResetPanel}
						onSave={handleSave}
					/>
				);
			case "Reward Scheme":
				return (
					<RewardContent
						designManager={designManager}
						onCancel={handleResetPanel}
						onSave={handleSave}
					/>
				);
			case "Miscellaneous":
				return (
					<MiscellaneousContent
						designManager={designManager}
						onCancel={handleResetPanel}
						onSave={handleSave}
					/>
				);
			default:
				return null;
		}
	};

	const getHeaderText = () => {
		switch (selectedCard) {
			case "Theme Library":
				return "Theme Library";
			case "Company Logo":
				return "Company Logo";
			case "Splash screen":
				return "Splash screen";
			case "Colours":
				return "Colours";
			case "Fonts":
				return "Fonts";
			case "Buttons":
				return "Buttons";
			case "Navigation Menu":
				return "Navigation Menu";
			case "Digital Loyalty Card":
				return "Digital Loyalty Card";
			case "Reward Scheme":
				return "Reward Scheme";
			case "Miscellaneous":
				return "Miscellaneous";
			default:
				return "Design Your App";
		}
	}

	const renderMinimizedPanel = () => {
		return <div
			style={{
				position: "fixed",
				right: 0,
				marginTop: "10px",
				width: "4.5%",
				height: "60%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				transition: "width 0.1s",
				minWidth: "70px",
			}}
		>
			<Button
				style={{
					...toggleButton,
					marginTop: "-10px", // add height property
				}}
				onClick={togglePanelToDefault}
			>
				<ChevronLeft style={{fontSize: "30px"}}/>
			</Button>

			<SidePanelCard
				icon="themes"
				iconWidth="35px"
				iconHeight="30px"
				onClick={() => {
					togglePanelToDefault();
					selectCard("Theme Library");
				}}
			/>
			<SidePanelCard
				icon="icon"
				iconWidth="35px"
				iconHeight="30px"
				onClick={() => {
					togglePanelToDefault();
					selectCard("Company Logo");
				}}
			/>
			<SidePanelCard
				icon="launch"
				iconWidth="35px"
				iconHeight="30px"
				onClick={() => {
					togglePanelToDefault();
					selectCard("Splash screen");
				}}
			/>
			<SidePanelCard
				icon="paint"
				iconWidth="35px"
				iconHeight="30px"
				onClick={() => {
					togglePanelToDefault();
					selectCard("Colours");
				}}
			/>
			<SidePanelCard
				icon="fonts"
				iconWidth="35px"
				iconHeight="30px"
				onClick={() => {
					togglePanelToDefault();
					selectCard("Fonts");
				}}
			/>
			<SidePanelCard
				icon="button"
				iconWidth="35px"
				iconHeight="30px"
				onClick={() => {
					togglePanelToDefault();
					selectCard("Buttons");
				}}
			/>

			<SidePanelCard
				icon="navigation"
				iconWidth="35px"
				iconHeight="30px"
				onClick={() => {
					togglePanelToDefault();
					selectCard("Navigation Menu");
				}}
			/>
			<SidePanelCard
				icon="scanner"
				iconWidth="35px"
				iconHeight="30px"
				onClick={() => {
					togglePanelToDefault();
					selectCard("Digital Loyalty Card");
				}}
			/>
			<SidePanelCard
				icon="gift"
				iconWidth="35px"
				iconHeight="30px"
				onClick={() => {
					togglePanelToDefault();
					selectCard("Reward Scheme");
				}}
			/>
			<SidePanelCard
				icon="misc"
				iconWidth="35px"
				iconHeight="30px"
				isLocked={false}
				onClick={() => {
					togglePanelToDefault();
					selectCard("Miscellaneous");
				}}
			/>
		</div>
	}

	const renderOpenPanel = () => {
		if (selectedCard === null) {
			return (
				<div id={id} style={contentStyle}>
					<SidePanelCard
						icon="themes"
						iconWidth="70px"
						iconHeight="70px"
						title="Theme Library"
						onClick={() => selectCard("Theme Library")}
					/>
					<SidePanelCard
						icon="icon"
						iconWidth="70px"
						iconHeight="70px"
						title="Company Logo"
						onClick={() => selectCard("Company Logo")}
					/>
					<SidePanelCard
						icon="launch"
						iconWidth="70px"
						iconHeight="70px"
						title="Splash screen"
						isLocked={launchScreenLocked}
						onClick={() => selectCard("Splash screen")}
					/>
					<SidePanelCard
						icon="paint"
						iconWidth="70px"
						iconHeight="70px"
						title="Colours"
						isLocked={coloursLocked}
						onClick={() => selectCard("Colours")}
					/>
					<SidePanelCard
						icon="fonts"
						iconWidth="70px"
						iconHeight="70px"
						title="Fonts"
						isLocked={coloursLocked}
						onClick={() => selectCard("Fonts")}
					/>
					<SidePanelCard
						icon="button"
						iconWidth="70px"
						iconHeight="70px"
						title="Buttons"
						isLocked={buttonsLocked}
						onClick={() => selectCard("Buttons")}
					/>
					<SidePanelCard
						icon="navigation"
						iconWidth="70px"
						iconHeight="70px"
						title="Navigation Menu"
						isLocked={navigationMenuLocked}
						onClick={() => selectCard("Navigation Menu")}
					/>
					<SidePanelCard
						icon="scanner"
						iconWidth="70px"
						iconHeight="70px"
						title="Digital Loyalty Card"
						isLocked={loyaltyCardLocked}
						onClick={() => selectCard("Digital Loyalty Card")}
					/>
					<SidePanelCard
						icon="gift"
						iconWidth="70px"
						iconHeight="70px"
						title="Reward Scheme"
						isLocked={rewardSchemeLocked}
						onClick={() => selectCard("Reward Scheme")}
					/>
					<SidePanelCard
						icon="misc"
						iconWidth="70px"
						iconHeight="70px"
						isLocked="true"
						title="Miscellaneous"
						isLocked={false}
						onClick={() => selectCard("Miscellaneous")}
					/>
				</div>
			);
		}

		return renderSelectedCard();
	};

	const buttonStyleOpen = {
		position: "relative",
		left: "-7%",
		top: "-16px",
		height: "0px", // Set to a non-zero value
		width: "30px",
		zIndex: 10,
		backgroundColor: "#0260FE",
		color: "white",
		cursor: "pointer",
		borderRadius: "20px",
	};

	const buttonStyleClosed = {
		position: "relative",
		left: "0%",
		top: "0px",
		height: "30px",
		width: "30px",
		zIndex: 10,
		color: "white",
		border: "none",
		cursor: "pointer",
	};

	const header = {
		height: "60px",
		borderBottom: "1px solid #e0e0e0",
		display: "flex",
		alignItems: "center",
		padding: "0 16px",
		position: "relative",
	};

	const headerLeft = {
		width: "15%",
		display: "flex",
		alignItems: "center",
	};
	const headerTextStyle = {
		flex: 1,
		color: "#171663",
		fontSize: "18px",
		fontFamily: "Poppins-Medium",
		textAlign: "center",
	};
	const headerTextSelected = {
		flex: 1,
		height: "60px",
		color: "#171663",
		fontSize: "18px",
		fontFamily: "Poppins-Medium",
		display: "flex", // Enable flexbox
		alignItems: "center", // Center vertically
		justifyContent: "center", // Center horizontally
		textAlign: "center", // Center text within the div
		marginRight: "0%", // Compensate for the headerLeft width
	};

	const toggleButton = {
		position: "absolute",
		left: "-50px",
		top: "12px",
		minWidth: "36px",
		height: "36px",
		padding: 0,
		backgroundColor: "#0260FE",
		borderRadius: "8px",
		color: "#ffffff",
		zIndex: 10,
	};

	const toggleButtonMinimized = {
		position: "absolute",
		left: "-50px",
		top: "12px",
		minWidth: "36px",
		height: "36px",
		padding: 0,
		backgroundColor: "#0260FE",
		borderRadius: "8px",
		color: "#ffffff",
		zIndex: 10,
	};

	const backButton = {
		height: "36px",
		padding: "0 16px",
		backgroundColor: "#0260FE",
		color: "#ffffff",
		borderRadius: "6px",
		fontSize: "14px",
		fontFamily: "Poppins-Medium",
		border: "none",
		cursor: "pointer",
		marginLeft: "10px",
	};

	const buttonStyle = width === "30%" ? buttonStyleOpen : buttonStyleClosed;

	const renderContent = () => {
		return (
			<div id={id}>
				{isResetDesignOpen && (
					<ResetWarning
						isOpen={isResetDesignOpen}
						onYes={handleResetDesignYes}
						onClose={closeResetDesign}
					/>
				)}
				{width === "30%" ? (
						<div style={wholeSidePanel}>
							<Button style={toggleButton} onClick={togglePanelToMin}>
								<ChevronRight style={{fontSize: "30px"}}/>
							</Button>
							<div style={header}>
								{selectedCard ? (
									<>
										<div style={headerLeft}>
											<button style={backButton} onClick={handleBack}>
												Back
											</button>
											<WarningBox
												isOpen={isWarningOpen}
												onYes={handleBackWarningYes}
												onClose={closeBackWarning}
											/>
										</div>
										<div style={headerTextSelected}>{getHeaderText()}</div>
										<div style={headerLeft}></div>

									</>
								) : (
									<div style={headerTextStyle}>Design Your App</div>
								)}
							</div>
							<div overlay-step="2" id="design-panel">{renderOpenPanel()}</div>
							<div
								style={{

									marginTop: "auto",
									padding: "10px 4%",
								}}
							>
								App demo code:{" "}
								<span id="demo-code"
									  overlay-step="4" style={{fontWeight: "bold"}}>
              {designManager.demoCode}
            </span>
							</div>
						</div>
					) :
					(renderMinimizedPanel())
				}
			</div>
		);
	};

	return renderContent();
}

export default DesignSidePanel;
