import React from "react";
import {Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import {styled} from "@mui/system";
import CloseIcon from "@mui/icons-material/Close"; // Import the X icon

const CenteredDialogActions = styled("div")({
	display: "flex",
	justifyContent: "center",
});

const CustomDialogTitle = styled(DialogTitle)({
	paddingBottom: "5px",
	textAlign: "center",
	position: "relative", // Make the title relative to position the X button
});

const CloseButton = styled(IconButton)({
	position: "absolute",
	top: "8px",
	right: "8px",
});

const VideoGuide = ({isOpen, onClose}) => {
	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				style: {
					borderRadius: "5px",
					width: "1280px", // Match the video width
					maxWidth: "1280px", // Ensure dialog doesn't exceed this width
				},
			}}
		>
			<CustomDialogTitle>
				Video Guide
				{/* X (Close) Button */}
				<CloseButton onClick={onClose}>
					<CloseIcon style={{fontSize: "28px"}}/>
				</CloseButton>
			</CustomDialogTitle>
			<DialogContent
				style={{
					paddingBottom: "15px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					textAlign: "center",
				}}
			>
				{/* Embedded YouTube Video */}
				<iframe
					width="1280"
					height="720"
					src="https://www.youtube.com/embed/dQw4w9WgXcQ" // Replace with your YouTube video URL
					title="YouTube Video Guide"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				></iframe>
			</DialogContent>
		</Dialog>
	);
};

export default VideoGuide;
