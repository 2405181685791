import React, {useCallback} from "react";
import {loadStripe} from "@stripe/stripe-js";
import {EmbeddedCheckout, EmbeddedCheckoutProvider,} from "@stripe/react-stripe-js";
import {ApiServiceClass} from "../components/api-service.js";
import Getcookies from "../components/getcookies";
import {toast} from "react-toastify";

const stripePromise = loadStripe(
	"pk_test_51Oc7yxKYFd22hVPvVglZgEhzzn3OB7A5K8cPrsV051daN4dxG6rIkNdGI32quSINOJ280o0VXn7iLQ5hZm7tXhmu00kO3f7KHF"
);

const GetCheckoutSessionToken = async (selectedPrice) => {
	try {
		const cookies = Getcookies();
		const requestBody = {
			ProductPriceId: selectedPrice,
			CompanyId: cookies.companyData.Id
		};
		const response = await ApiServiceClass.CreateCheckoutSession(requestBody);
		if (!response.body.ClientSecret) {
			toast.error("Error during checkout, please try again");
			throw new Error("Missing clientSecret in response data");
		}
		return response.body;
	} catch (error) {
		throw error;
	}
};

const CheckoutForm = (selectedPrice) => {
	const fetchClientSecret = useCallback(async () => {
		try {
			const sessionData = await GetCheckoutSessionToken(selectedPrice.selectedPrice);
			return sessionData.ClientSecret;
		} catch (error) {
			console.error("Error fetching client secret:", error);
			throw error; // Rethrow the error to be caught by the caller
		}
	}, [selectedPrice.selectedPrice]);

	const options = {fetchClientSecret};

	return (
		<div id="checkout">
			<EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
				<EmbeddedCheckout/>
			</EmbeddedCheckoutProvider>
		</div>
	);
};

const Checkout = (selectedPrice) => {
	//console.log("checkout selectedPrice: " + selectedPrice.selectedPrice);
	return (
		<div>
			<CheckoutForm selectedPrice={selectedPrice.selectedPrice}/>
		</div>
	);
};


export default Checkout;
