import PropTypes from 'prop-types';
import {
	Box,
	Button,
	Card,
	Dialog,
	DialogContent,
	DialogTitle,
	MenuItem,
	Select,
	Stack,
	SvgIcon,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TableRow,
	TextField,
	Typography
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import {DeleteEmployee} from "../components/delete-employee";
import CircularProgress from "@mui/material/CircularProgress";


export const EmployeesTable = (props) => {
	const {
		items = [],
		getRoleById,
		handleEditOpen,
		requesterId,
		isUserAdmin,
		fetchData,
	} = props;

	return (
		<Card sx={{marginLeft: 0,}}>
			<Box sx={{minWidth: 800}}>
				<Table>
					<TableHead>
						<TableRow>
							{isUserAdmin && (<TableCell>Actions</TableCell>)}
							<TableCell>Name</TableCell>
							<TableCell>Email</TableCell>
							<TableCell>Status</TableCell>
							<TableCell>Role</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{items.map((customer) => (
							<TableRow hover key={customer.UserId}>
								{isUserAdmin && (<TableCell style={{width: '20%'}}>
									<div style={{display: 'flex'}}>
										<Button
											startIcon={
												<SvgIcon fontSize="small">
													<EditIcon/>
												</SvgIcon>
											}
											variant="contained"
											size="small"
											onClick={() => handleEditOpen(customer)}
											style={{cursor: 'pointer', marginRight: '8px'}}
										>
											Edit
										</Button>

										{(customer.Role === 1) && (
											<DeleteEmployee
												userId={customer.UserId}
												requesterId={requesterId}
												fetchData={fetchData}
											/>)}
									</div>
								</TableCell>)}
								<TableCell>
									<Stack alignItems="center" direction="row" spacing={2}>
										<Typography variant="subtitle2">
											{`${customer.FirstName} ${customer.LastName}`}
										</Typography>
									</Stack>
								</TableCell>
								<TableCell>{customer.EmailAddress}</TableCell>
								<TableCell style={{color: customer.AccountApproved ? 'green' : 'red'}}>
									{customer.AccountApproved ? "Active" : "Invite Pending"}
								</TableCell>
								<TableCell>{getRoleById(customer.Role)}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>

			</Box>
		</Card>
	);
};


export const AddEmployee = (props) => {
	const {
		setFirstName,
		setLastName,
		setEmail,
		addOpen,
		handleAddClose,
		onSendInvite,
		buttonDisabled,
	} = props;

	return (
		<Dialog
			open={addOpen}
			onClose={handleAddClose}
			sx={{
				"& .MuiDialog-container": {
					"& .MuiPaper-root": {
						width: "100%",
						maxWidth: "1500px",
					},
				},
			}}
		>
			<DialogTitle style={{width: '80%'}}>
				New Employee
				<CloseIcon
					onClick={() => handleAddClose()}
					style={{cursor: 'pointer', position: 'absolute', top: '8px', right: '8px'}}
				/>
			</DialogTitle>
			<DialogContent style={{paddingBottom: '15px'}}>
				{/* Add a new employee to your account */}
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>First Name</TableCell>
							<TableCell>Last Name</TableCell>
							<TableCell>Email</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow hover>
							<TableCell>
								<TextField
									variant="outlined"
									label="First Name"
									disabled={buttonDisabled}
									fullWidth
									id="firstname"
									onChange={(e) => setFirstName(e.target.value)}
								/>
							</TableCell>
							<TableCell>
								<TextField
									variant="outlined"
									label="Last Name"
									disabled={buttonDisabled}
									fullWidth
									id="lastName"
									onChange={(e) => setLastName(e.target.value)}
								/>
							</TableCell>
							<TableCell>
								<TextField
									variant="outlined"
									label="Email"
									fullWidth
									disabled={buttonDisabled}
									id="email"
									onChange={(e) => setEmail(e.target.value)}
								/>
							</TableCell>
						</TableRow>
					</TableBody>
					<TableFooter>
						<TableRow>
							<TableCell colSpan={4} style={{textAlign: 'center'}}>
								<Button
									startIcon={<SaveIcon/>}
									variant="contained"
									onClick={() => onSendInvite()}
									disabled={buttonDisabled}
									style={{cursor: 'pointer', marginRight: '8px'}}
								>
									Send Invite
									{buttonDisabled && (
										<CircularProgress
											size={24}
											style={{
												position: 'absolute',
												left: '50%',
												top: '50%',
												marginLeft: '-12px',
												marginTop: '-12px'
											}}
										/>
									)}
								</Button>
							</TableCell>
						</TableRow>
					</TableFooter>
				</Table>
			</DialogContent>
		</Dialog>
	);
};


export const EditEmployee = (props) => {
	const {
		firstName,
		setFirstName,
		lastName,
		setLastName,
		email,
		setEmail,
		roleId,
		setRoleId,
		editOpen,
		handleEditClose,
		onEditSave,
		buttonDisabled,
	} = props;

	return (
		<Dialog
			open={editOpen}
			onClose={handleEditClose}
			sx={{
				"& .MuiDialog-container": {
					"& .MuiPaper-root": {
						width: "100%",
						maxWidth: "1500px",  // Set your width here
					},
				},
			}}
		>
			<DialogTitle style={{width: '80%'}}>
				Edit Employee
				<CloseIcon
					onClick={() => handleEditClose()}
					style={{cursor: 'pointer', position: 'absolute', top: '8px', right: '8px'}}
				/>
			</DialogTitle>
			<DialogContent style={{paddingBottom: '15px'}}>
				{/* Add a new employee to your account */}
				{/* <Box> */}
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>First Name</TableCell>
							<TableCell>Last Name</TableCell>
							<TableCell>Email</TableCell>
							<TableCell>Role</TableCell>
							{/* <TableCell>Password</TableCell> */}
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow hover>
							<TableCell>
								<TextField
									variant="outlined"
									label="First Name"
									fullWidth
									disabled={buttonDisabled}
									id="firstname"
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
								/>
							</TableCell>
							<TableCell>
								<TextField
									variant="outlined"
									label="Last Name"
									disabled={buttonDisabled}
									fullWidth
									id="lastName"
									value={lastName}
									onChange={(e) => setLastName(e.target.value)}
								/>
							</TableCell>
							<TableCell>
								<TextField
									variant="outlined"
									label="Email"
									fullWidth
									id="email"
									value={email}
									InputProps={{
										readOnly: true,
									}}
									disabled
								/>
							</TableCell>
							<TableCell>
								<Select
									value={roleId}
									onChange={(e) => setRoleId(e.target.value)}
									displayEmpty
									inputProps={{'aria-label': 'Select Role'}}
								>
									<MenuItem value={1}>Employee</MenuItem>
									<MenuItem value={2}>Admin</MenuItem>
								</Select>
							</TableCell>
						</TableRow>
					</TableBody>
					<TableFooter>
						<TableRow>
							<TableCell colSpan={5} style={{textAlign: 'center'}}>
								<Button
									startIcon={<SaveIcon/>}
									variant="contained"
									onClick={() => onEditSave()}
									disabled={buttonDisabled}
									style={{cursor: 'pointer', marginRight: '8px'}}
								>
									Save Changes
									{buttonDisabled && (
										<CircularProgress
											size={24}
											style={{
												position: 'absolute',
												left: '50%',
												top: '50%',
												marginLeft: '-12px',
												marginTop: '-12px'
											}}
										/>
									)}
								</Button>
							</TableCell>
						</TableRow>
					</TableFooter>
				</Table>
				{/* </Box> */}
			</DialogContent>
		</Dialog>
	);
};


EmployeesTable.propTypes = {
	items: PropTypes.array,
	getRoleById: PropTypes.func,
	handleEditOpen: PropTypes.func,
	handleDelete: PropTypes.func,
	onPageChange: PropTypes.func,
	onRowsPerPageChange: PropTypes.func,
	page: PropTypes.number,
	rowsPerPage: PropTypes.number,
	fetchData: PropTypes.func,
	isAdmin: PropTypes.func,
};


AddEmployee.propTypes = {
	setFirstName: PropTypes.func,
	setLastName: PropTypes.func,
	setEmail: PropTypes.func,
	handleAddClose: PropTypes.func,
	onSendInvite: PropTypes.func,
};


EditEmployee.propTypes = {
	setFirstName: PropTypes.func,
	setLastName: PropTypes.func,
	setEmail: PropTypes.func,
	setRoleId: PropTypes.func,
	handleEditClose: PropTypes.func,
	onEditSave: PropTypes.func,
};
