import MyTooltip from "./my-tooltip";
import React, {useEffect, useRef} from "react";
import {ChromePicker} from "react-color";


const MyColor = ({name, tooltip, color, setColor, showColorPicker, setShowColorPicker}) => {
	const colorPickerRef = useRef(null);

	useEffect(() => {
		function handleClickOutside(event) {
			if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
				setShowColorPicker(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [colorPickerRef, setShowColorPicker]);


	return (
		<div>
			<div style={{display: 'flex', justifyContent: 'left', alignItems: 'center',}}>
				<div>{name}</div>
				<MyTooltip title={tooltip}/>
			</div>
			<div style={{
				position: "relative",
				border: "1px solid black",
				borderRadius: "5px",
				padding: "5px",
				width: '230px'
				// minWidth: '170px',
				// maxWidth: '230px'
			}}>
				<div style={{position: "relative", display: 'flex', alignItems: 'center'}}>
					{/* This as a button is too wide but as a div doesnt close clicking on the box */}
					<div
						style={{
							backgroundColor: color,
							width: "40px",
							height: "40px",
							border: "1px solid black",
							marginRight: "10px",
							borderRadius: "5px",
							cursor: 'pointer',
						}}
						onClick={() => !showColorPicker && setShowColorPicker(true)}
						disabled={showColorPicker}
					/>
					{color}
					{showColorPicker && (
						<div
							ref={colorPickerRef}
							style={{
								position: "absolute",
								top: "50px",
								left: "0",
								zIndex: 9999,
							}}
						>
							<ChromePicker
								color={color}
								onChange={(color) => setColor(color.hex)}
								disableAlpha
								styles={{default: {picker: {boxShadow: "none"}}}}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default MyColor
