import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  TextField,
} from "@mui/material";
import { ApiServiceClass } from "../components/api-service.js";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logoSvg from "../tools/icons/logo.svg";
import { Copyright } from "../components/copyright.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../theme/index.css";
import ReCAPTCHA from "react-google-recaptcha";
import Cookies from "js-cookie";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const location = useLocation();
  const [captchaValue, setCaptchaValue] = useState(null);
  const recaptchaRef = useRef(null);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  useEffect(() => {
    const isFromRegister = location?.state?.fromRegister;
    if (isFromRegister) {
      toast.success("Welcome! You have successfully registered.");
    }
  }, [location]);

  const verifyCaptcha = async (event) => {
    try {
      const request = {
        captcha: captchaValue,
      };

      const response = await ApiServiceClass.VerifyCaptcha(request);
      setLoading(false);
      if (response.status === 200) {
        return true;
      } else {
        toast.error("Captcha verification failed");
        return false;
      }
    } catch (error) {
      console.error("Error during captcha verification:", error);
      toast.error("Error during captcha verification");
      return false;
    }
  };

  const handleLogin = async () => {
	var captcha = await verifyCaptcha();
	if (captcha) {
	  try {
		setLoading(true);
		const loginRequest = {
		  EmailAddress: email,
		  Password: password,
		};
		const loginResponse = await ApiServiceClass.Login(loginRequest);
		if (loginResponse.status === 200) {
		  Cookies.set(
			"reward-space-login-data",
			JSON.stringify(loginResponse.body),
			{
			  expires: 100,
			  secure: true,
			  sameSite: "Strict",
			}
		  );
		  const companyDetailsRequest = {
			CompanyId: loginResponse.body.CompanyId,
			RequesterUserId: loginResponse.body.UserId,
		  };
		  const companyDetailsResponse =
			await ApiServiceClass.GetCompanyDetails(companyDetailsRequest);
		  if (companyDetailsResponse.status === 200) {
			Cookies.set(
			  "reward-space-company-data",
			  JSON.stringify(companyDetailsResponse.body),
			  {
				expires: 100,
				secure: true,
				sameSite: "Strict",
			  }
			);
			navigate("/Dashboard");
		  }
		} else {
		  throw new Error("Invalid username or password");
		}
	  } catch (error) {
		console.log(error);
		setLoading(false);
		setPassword("");
		toast.error("Invalid username or password");
		recaptchaRef.current.reset(); // Reset reCAPTCHA
		setCaptchaValue(null); // Clear the captcha value to disable the button
	  }
	}
  };
  

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    handleLogin();
  };

  return (
    <Container
      component="main"
      maxWidth="100%"
      className="background-container-wave"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "2%",
      }}
    >
      <CssBaseline />

      {/* Top Left Logo */}
      <Link
        to="/home"
        style={{
          position: "absolute",
          top: "20px",
          left: "20px",
          display: "flex",
          alignItems: "center",
          textDecoration: "none",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="45"
          height="45"
          style={{ marginRight: "10px" }}
        >
          <image href={logoSvg} width="45" height="45" alt="Logo" />
        </svg>
        <span
          style={{
            fontFamily: "Poppins-SemiBold",
            fontSize: "25px",
            color: "#0260FE",
            whiteSpace: "nowrap",
          }}
        >
          Reward Space
        </span>
      </Link>

      {/* Centered Login Box */}
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "550px",
            bgcolor: "#FFFFFF",
            border: "1px solid #2C3E50",
            borderRadius: "15px",
            padding: "2rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontFamily: "Poppins-Medium",
              fontSize: "17px",
              marginBottom: "1rem",
            }}
          >
            Log in to RewardSpace
          </span>

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
              width: "80%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
              value={email}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="password"
              label="Password"
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LcGsgwqAAAAAK28rAWl89KR5j7xBHdBOQvkNpJW"
                onChange={handleCaptchaChange}
              />
            </div>
            <Button
              type="submit"
              fontFamily="Poppins-Medium"
              onClick={handleSubmit}
              disabled={!captchaValue}
              variant="contained"
              sx={{
                mt: 2,
                mb: 2,
                width: "80%",
              }}
            >
              Log In
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "black",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                gap: "100px",
                marginTop: "10px",
              }}
            >
          
			  
			  <Link
                to="/Register"
                style={{
                  fontFamily: "Poppins-Medium",           
                  color: "inherit",
                }}
              >
               Sign Up
              </Link>
			  
			  
			  {" "}
            
              <Link
                to="/Reset"
                style={{
                  fontFamily: "Poppins-Medium",
                  color: "inherit",
                }}
              >
                Forgot password?
              </Link>
            </div>
          </Box>
        </Box>
      </Box>

      {/* Bottom Copyright */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <Copyright color="#FFFFFF" fontFamily="Poppins-Medium" />
      </Box>
    </Container>
  );
}
