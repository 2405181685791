import React, {useState} from 'react';
import Navbar from "./navbar.js";

const FAQs = () => {
	const [expandedIndex, setExpandedIndex] = useState(null);

	const faqData = [
		{
			question: "What is Reward Space?",
			answer: "Reward-Space.co.uk is a comprehensive platform designed to help businesses of all sizes grow their customer base and increase loyalty through personalized mobile applications. Our platform enables you to design and build your own mobile app without any coding knowledge required.<br>" +
				"<br>With Reward Space, you can:<br>• Create a professional, branded mobile app experience" +
				"<br>• Reach customers directly with targeted push notifications<br>• Implement digital loyalty programs that replace physical stamp cards" +
				"<br>• Gather valuable customer insights and analytics<br>• Process mobile orders and payments seamlessly" +
				"<br>Our platform is trusted by businesses across the UK, from local cafes to national retail chains."
		},
		{
			question: "How do I get started?",
			answer: "Getting started with Reward Space is quick and straightforward:" +
				"<br><br>1. <strong>Build your app</strong> - Visit our <a href='/myapp'>My App</a> page where our intuitive app builder will guide you through the process. " +
				"Choose from dozens of templates or start from scratch." +
				"<br><br>2. <strong>Customize your app</strong> - Add your logo, brand colors, product information, and configure features like loyalty programs and push notifications" +
				"<br><br>3. <strong>Test your app</strong> - Preview your app in real-time to ensure everything works as expected before publication." +
				"<br><br>4. <strong>Publish</strong> - Once you're satisfied, visit the publish center to submit your app to the iOS App Store and Google Play Store." +
				"<br><br>Our detailed Getting Started guide and video tutorials will walk you through each step of the process."
		},
		{
			question: "Where is Reward-Space.co.uk based?",
			answer: "Reward-Space.co.uk is based in the UK. As a UK-based company, we comply with all UK data protection regulations and GDPR requirements, " +
				"ensuring your business and customer data is always handled securely and in accordance with UK law.<br><br>Our development team, customer support, and account management are all based in the UK, providing local expertise and support during UK business hours. This local presence allows us to better understand the unique challenges and opportunities facing UK businesses."
		},
		{
			question: "How long will it take for me to get my own app available on the iOS or Android app store?",
			answer: "The typical timeline from account setup to having your app live on the app stores is approximately 1 week."
		},
		{
			question: "What are the pricing options?",
			answer: "We offer flexible pricing plans designed to accommodate businesses of all sizes. Visit our " +
				"<a href='/upgrade'>upgrade</a> page to view our plans."
		},
		{
			question: "How can I get in touch?",
			answer: "We're always happy to assist you and provide support through multiple channels. " +
				"<br><br><strong>Email us on:</strong> support@reward-space.co.uk<br>" + "<br> Businesses signed up on our premium plan will recieve priority support."
		},
		{
			question: "How secure is the Reward Space platform?",
			answer: "Security is our top priority at Reward Space. Our platform employs enterprise-grade security measures to protect your business and customer data:" +
				"<br><br>• <strong>Data Encryption:</strong> All sensitive data is encrypted" +
				"<br>• <strong>Regular Security Audits:</strong> Our systems undergo regular security assessments" +
				"<br>• <strong>GDPR Compliance:</strong> Our platform is fully GDPR-compliant, with built-in tools to help you meet your data protection obligations" +
				"<br>• <strong>PCI DSS Compliance:</strong> Our payment processor stripe ensures your payment details are fully secure. It uses encryption and tokenization to protect your card information, and is PCI DSS compliant. " +
				"Plus, Stripe’s fraud detection keeps your transactions safe. You can buy with confidence knowing your data is secure." +
				"<br>• <strong>Regular Backups:</strong> Automated backups " +
				"<br><br>Additionally, we never sell your data or your customers' data to third parties. You maintain full ownership and control of all data collected through your app."
		},
		{
			question: "What kind of support is available after my app is published?",
			answer: "We provide comprehensive ongoing support to ensure your app continues to perform optimally:" +
				"<br><br><strong>Technical Support:</strong>" +
				"<br>• In-app issue resolution and troubleshooting<br>• Platform updates and maintenance" +
				"<br>• App store compliance management<br>• Operating system compatibility updates" +
				"<br><br><strong>Business Support:</strong>" +
				"<br>• Usage analytics and optimization recommendations<br>• Campaign performance analysis" +
				"<br>• Loyalty program optimization<br><br><strong>Learning Resources:</strong><br>• Knowledge base with step-by-step guides" +
				"<br>• Video tutorials<br>" +
				"<br>We can also provide optional add-on services such as campaign design assistance, custom feature development, " +
				"and marketing strategy consultations for businesses that need additional support. Please contact us to find out more"
		}
	];


	const toggleFAQ = (index) => {
		setExpandedIndex(expandedIndex === index ? null : index);
	};

	const handleLinkClick = (e) => {
		e.stopPropagation();
	};

	const containerStyle = {
		maxWidth: '90%', // Increased from 800px to 95% width
		margin: '0 auto',
		padding: 3,
		marginTop: "3%",
	};

	const faqListStyle = {
		display: 'flex',
		flexDirection: 'column',
		gap: '0px',
		spacing: "-5px"
	};

	return (
		<Navbar>
			<div style={containerStyle}>
				<div style={faqListStyle}>
					{faqData.map((faq, index) => (
						<FAQItem
							key={index}
							question={faq.question}
							answer={faq.answer}
							isOpen={expandedIndex === index}
							onClick={() => toggleFAQ(index)}
							onLinkClick={handleLinkClick}
						/>
					))}
				</div>
			</div>
		</Navbar>
	);
};

const FAQItem = ({question, answer, isOpen, onClick, onLinkClick}) => {
	const itemStyle = {
		backgroundColor: 'white',
		borderRadius: '8px',
		boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
		overflow: 'hidden',
		border: '1px solid #e2e8f0',
		marginBottom: "30px",
		width: '100%', // Ensures the item takes full width of container
	};

	const buttonStyle = {
		width: '100%',
		padding: '16px 20px', // Slightly more horizontal padding
		textAlign: 'left',
		fontWeight: '500',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		backgroundColor: 'transparent',
		border: 'none',
		cursor: 'pointer',
		outline: 'none'
	};

	const questionTextStyle = {
		fontSize: '18px',
		color: '#2d3748'
	};

	const iconStyle = {
		fontSize: '18px',
		color: isOpen ? '#3b82f6' : '#718096',
		marginLeft: '12px' // Adds some space between question and icon
	};

	const answerContainerStyle = {
		padding: '16px 20px', // Matched to button padding
		backgroundColor: '#f8fafc',
		borderTop: '1px solid #e2e8f0',
	};

	const answerStyle = {
		fontSize: '16px',
		lineHeight: '1.6',
		color: '#4a5568',
	};

	return (
		<div style={itemStyle}>
			<button
				style={buttonStyle}
				onClick={onClick}
			>
				<span style={questionTextStyle}>{question}</span>
				<span style={iconStyle}>
          {isOpen ? '−' : '+'}
        </span>
			</button>

			{isOpen && (
				<div
					style={answerContainerStyle}
					onClick={(e) => e.stopPropagation()}
				>
					<div
						style={answerStyle}
						dangerouslySetInnerHTML={{__html: answer}}
						onClick={onLinkClick}
					/>
				</div>
			)}
		</div>
	);
};

export default FAQs;