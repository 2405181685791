import React, {useEffect, useState} from "react";
import {Check} from "lucide-react";

const ThemeSelector = ({onThemeSelect, selectedTheme, sampleThemes}) => {
	const [selectedCard, setSelectedCard] = useState();

	useEffect(() => {
		if (selectedTheme == null) {
			setSelectedCard(null);
		}
	}, [selectedTheme]);

	const handleCardSelect = (id) => {
		setSelectedCard(id);
		onThemeSelect(id);
	};

	const gridStyle = {
		display: "grid",
		gridTemplateColumns: "repeat(2, 1fr)",
		gap: "15px",
		padding: "0px",
	};

	// Generate card data using sampleThemes
	const cardData = [
		{
			id: 0,
			name: sampleThemes[0].Name,
			subtitle: sampleThemes[0].RewardSubTitle,
			primaryColor: sampleThemes[0].PrimaryBrandBackgroundColour,
			secondaryColor: sampleThemes[0].NavigationMenuBackgroundColour,
			logo: sampleThemes[0].CompanyLogo,
			accentColor: sampleThemes[0].RewardSchemeStampBackgroundColour,
			fontFamily: sampleThemes[0].PrimaryBrandFont,
			primaryFontColor: sampleThemes[0].PrimaryBrandFontColour,
			secondaryFontColor: sampleThemes[0].ButtonFontColour,
		},
		{
			id: 1,
			name: sampleThemes[1].Name,
			subtitle: sampleThemes[1].RewardSubTitle,
			primaryColor: sampleThemes[1].PrimaryBrandBackgroundColour,
			secondaryColor: sampleThemes[1].NavigationMenuBackgroundColour,
			logo: sampleThemes[1].CompanyLogo,
			accentColor: sampleThemes[1].RewardSchemeStampBackgroundColour,
			fontFamily: sampleThemes[1].PrimaryBrandFont,
			primaryFontColor: sampleThemes[1].PrimaryBrandFontColour,
			secondaryFontColor: sampleThemes[1].ButtonFontColour,
		},
		{
			id: 2,
			name: sampleThemes[2].Name,
			subtitle: sampleThemes[2].RewardSubTitle,
			primaryColor: sampleThemes[2].PrimaryBrandBackgroundColour,
			secondaryColor: sampleThemes[2].NavigationMenuBackgroundColour,
			logo: sampleThemes[2].CompanyLogo,
			accentColor: sampleThemes[2].RewardSchemeStampBackgroundColour,
			fontFamily: sampleThemes[2].PrimaryBrandFont,
			primaryFontColor: sampleThemes[2].PrimaryBrandFontColour,
			secondaryFontColor: sampleThemes[2].ButtonFontColour,
		},
		{
			id: 3,
			name: sampleThemes[3].Name,
			subtitle: sampleThemes[3].RewardSubTitle,
			primaryColor: sampleThemes[3].PrimaryBrandBackgroundColour,
			secondaryColor: sampleThemes[3].NavigationMenuBackgroundColour,
			logo: sampleThemes[3].CompanyLogo,
			accentColor: sampleThemes[3].RewardSchemeStampBackgroundColour,
			fontFamily: sampleThemes[3].PrimaryBrandFont,
			primaryFontColor: sampleThemes[3].PrimaryBrandFontColour,
			secondaryFontColor: sampleThemes[3].ButtonFontColour,
		},
		{
			id: 4,
			name: sampleThemes[4].Name,
			subtitle: sampleThemes[4].RewardSubTitle,
			primaryColor: sampleThemes[4].PrimaryBrandBackgroundColour,
			secondaryColor: sampleThemes[4].NavigationMenuBackgroundColour,
			logo: sampleThemes[4].CompanyLogo,
			accentColor: sampleThemes[4].RewardSchemeStampBackgroundColour,
			fontFamily: sampleThemes[4].PrimaryBrandFont,
			primaryFontColor: sampleThemes[4].PrimaryBrandFontColour,
			secondaryFontColor: sampleThemes[4].ButtonFontColour,
		},
		{
			id: 5,
			name: sampleThemes[5].Name,
			subtitle: sampleThemes[5].RewardSubTitle,
			primaryColor: sampleThemes[5].PrimaryBrandBackgroundColour,
			secondaryColor: sampleThemes[5].NavigationMenuBackgroundColour,
			logo: sampleThemes[5].CompanyLogo,
			accentColor: sampleThemes[5].RewardSchemeStampBackgroundColour,
			fontFamily: sampleThemes[5].PrimaryBrandFont,
			primaryFontColor: sampleThemes[5].PrimaryBrandFontColour,
			secondaryFontColor: sampleThemes[5].ButtonFontColour,
		},
		{
			id: 6,
			name: sampleThemes[6].Name,
			subtitle: sampleThemes[6].RewardSubTitle,
			primaryColor: sampleThemes[6].PrimaryBrandBackgroundColour,
			secondaryColor: sampleThemes[6].NavigationMenuBackgroundColour,
			logo: sampleThemes[6].CompanyLogo,
			accentColor: sampleThemes[6].RewardSchemeStampBackgroundColour,
			fontFamily: sampleThemes[6].PrimaryBrandFont,
			primaryFontColor: sampleThemes[6].PrimaryBrandFontColour,
			secondaryFontColor: sampleThemes[6].ButtonFontColour,
		},
		{
			id: 7,
			name: sampleThemes[7].Name,
			subtitle: sampleThemes[7].RewardSubTitle,
			primaryColor: sampleThemes[7].PrimaryBrandBackgroundColour,
			secondaryColor: sampleThemes[7].NavigationMenuBackgroundColour,
			logo: sampleThemes[7].CompanyLogo,
			accentColor: sampleThemes[7].RewardSchemeStampBackgroundColour,
			fontFamily: sampleThemes[7].PrimaryBrandFont,
			primaryFontColor: sampleThemes[7].PrimaryBrandFontColour,
			secondaryFontColor: sampleThemes[7].ButtonFontColour,
		},
	];

	return (
		<div style={gridStyle}>
			{cardData.map((card) => (
				<Card
					key={card.id}
					{...card}
					isSelected={selectedCard === card.id}
					onSelect={handleCardSelect}
				/>
			))}
		</div>
	);
};

const Card = ({
				  id,
				  name,
				  subtitle,
				  logo,
				  primaryColor,
				  secondaryColor,
				  accentColor,
				  primaryFontColor,
				  secondaryFontColor,
				  fontFamily,
				  isSelected,
				  onSelect,
			  }) => {
	const highlightColor = "#00cc00";

	const cardStyle = {
		width: "95%",
		height: "160px",
		backgroundColor: primaryColor,
		display: "flex",
		flexDirection: "column",
		padding: "10px",
		borderRadius: "12px",
		boxSizing: "border-box",
		position: "relative",
		cursor: "pointer",
		outline: isSelected ? `4px solid ${accentColor}` : "none",
		fontFamily: fontFamily,
		//transition: "all 0.1s ease-in-out",
		//boxShadow: `0 4px 6px -1px ${accentColor}20, 0 2px 4px -1px ${accentColor}10`,
		//border: `2px solid ${primaryFontColor}`,
		draggable: false,
	};

	const contentWrapperStyle = {
		flex: 1,
		display: "grid",
		gridTemplateRows: "45px 30px 25px 25px 1fr",
		alignItems: "center",
		gap: "4px",
	};

	const logoContainerStyle = (color) => ({
		backgroundColor: color,
		borderRadius: "10px",
		padding: "6px",
		height: "40px",
		width: "40px",
		margin: "0 auto",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	});

	const logoStyle = {
		width: "34px",
		height: "34px",
	};

	const titleStyle = {
		fontSize: "20px",
		fontWeight: "bold",
		color: primaryFontColor,
		fontFamily: fontFamily,
		textAlign: "center",
		margin: "0",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	};

	const subtitleStyle = {
		fontSize: "14px",
		color: primaryFontColor,
		fontFamily: fontFamily,
		textAlign: "center",
		marginTop: "-10px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	};

	const colorBoxesStyle = {
		display: "flex",
		justifyContent: "center",
		gap: "6px",
		padding: "4px",
		backgroundColor: "rgba(255, 255, 255, 1.0)",
		borderRadius: "20px",
		marginTop: "-5px",
		width: "50%",
		marginLeft: "25%",
	};

	const colorBoxStyle = {
		minWidth: "20px",
		minHeight: "20px",
		border: "2px solid rgba(0, 0, 0, 1.0)",
		borderRadius: "50%",
	};

	return (
		<div style={cardStyle} onClick={() => onSelect(id)}>
			{isSelected && (
				<Check
					style={{
						position: "absolute",
						top: "10px",
						right: "10px",
						color: highlightColor,
						size: 30,
					}}
				/>
			)}
			<div style={contentWrapperStyle}>
				<div style={logoContainerStyle(accentColor)}>
					<img
						src={`data:image/svg+xml;base64,${logo}`}
						alt={`${name} logo`}
						style={logoStyle}
					/>
				</div>
				<div style={titleStyle}>{name}</div>
				<p style={subtitleStyle}>{subtitle}</p>
				<div style={colorBoxesStyle}>
					<div
						style={{
							...colorBoxStyle,
							backgroundColor: primaryColor,
							title: "Primary Color",
						}}
					/>
					<div
						style={{
							...colorBoxStyle,
							backgroundColor: secondaryColor,
							title: "Secondary Color",
						}}
					/>
					<div
						style={{
							...colorBoxStyle,
							backgroundColor: accentColor,
							title: "Accent Color",
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default ThemeSelector;
