import React, {useState} from "react";
import Styles from "../resources/styles";
import ThemeSelector from "../cards/ThemeSelector";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import {toast} from "react-toastify";
import {ApiServiceClass} from "../../../components/api-service";

function ThemesContent({fonts, designManager}) {
	const [saveDisabled, setSaveDisabled] = useState(true);

	const [selectedTheme, setSelectedTheme] = useState();
	const [themeContent, setThemeContent] = useState({});
	const sampleThemesResponse = designManager.getAllSampleThemes();
	console.log("All sample themes: ", sampleThemesResponse);

	const handleThemeSelect = (themeId) => {
		setSelectedTheme(themeId);
		console.log("Selected Theme ID:", themeId);
		const theme = designManager.getSampleTheme(themeId);
		if (theme != null) {
			const themeMappings = {
				appHeaderBackgroundColor: theme.AppHeaderBackgroundColour,
				appHeaderFont: theme.AppHeaderFont,
				appHeaderFontColor: theme.AppHeaderFontColour,

				buttonBackgroundColor: theme.ButtonBackgroundColour,
				buttonFontColor: theme.ButtonFontColour,
				buttonsFont: theme.ButtonFont,

				companyLogo: theme.CompanyLogo,
				companyLogoFileType: 1,

				loyaltyCardBackgroundColor: theme.LoyaltyCardBackgroundColour,
				loyaltyCardTextColor: theme.LoyaltyCardTextColour,

				navBarBackgroundColor: theme.NavigationMenuBackgroundColour,
				navBarDeselectedColor: theme.NavigationMenuDeselectedColour,
				navBarSelectedColor: theme.NavigationMenuSelectedColour,

				primaryBackgroundColor: theme.PrimaryBrandBackgroundColour,
				primaryFontColor: theme.PrimaryBrandFontColour,
				primaryThemeFont: theme.PrimaryBrandFont,

				appHeaderFontSize:  theme.HeaderFontSize,
				primaryThemeFontSize:  theme.PrimaryFontSize,
				buttonsFontSize:  theme.ButtonsFontSize,

				rewardSchemePanelBackgroundColor:
				theme.RewardSchemePanelBackgroundColour,
				rewardSchemePurchasesRequired: theme.PurchasesRequired,
				rewardSchemeStampBackgroundColor:
				theme.RewardSchemeStampBackgroundColour,
				rewardSchemeStampIcon: theme.RewardStamp,
				rewardSchemeSubtitle: theme.RewardSubTitle,
				rewardSchemeTextColor: theme.RewardSchemeTextColor,
				rewardSchemeTitle: theme.RewardTitle,

				splashScreenBackgroundColor: theme.BackgroundSplashColour,
				splashScreenLogo: theme.SplashLogo,

				loadingSpinnerColor: theme.NavigationMenuSelectedColour,
			};
			// Apply all theme properties
			Object.entries(themeMappings).forEach(
				([contentProperty, newProperty]) => {
					designManager.setUnsavedChanges({[contentProperty]: newProperty});
					setThemeContent((prevContent) => ({
						...prevContent,
						[contentProperty]: newProperty,
					}));
				}
			);
		}
		setSaveDisabled(false);
	};

	const getFontIdByTechnicalName = (technicalName) => {
		const font = fonts.find((font) => font.FontTechnicalName === technicalName);
		return font ? font.Id : null; // Return the Id if found, otherwise return null
	};

	const handleReset = () => {
		console.log("Resetting design");
		designManager.resetUnsavedChanges();
		setSelectedTheme(null);
		setSaveDisabled(true);
	};

	const handleSave = async () => {
		try {
			if (!designManager.hasUnsavedChanges()) {
				console.log("No changes to save.");
				return;
			}
			const updatedDesign = designManager.getUnsavedDesign();
			const requestBody = {
				RequesterUserId: `${designManager.getUserId()}`, // User ID in quotes
				CompanyId: designManager.getCompanyId(),
				AppHeaderBackgroundColour: updatedDesign.appHeaderBackgroundColor,
				AppHeaderFontColour: updatedDesign.appHeaderFontColor,
				AppHeaderFontStyle: getFontIdByTechnicalName(
					updatedDesign.appHeaderFont
				),
				PrimaryBrandBackgroundColour: updatedDesign.primaryBackgroundColor,
				PrimaryBrandFontColour: updatedDesign.primaryFontColor,
				ButtonsBackgroundColour: updatedDesign.buttonBackgroundColor,
				ButtonsFontColour: updatedDesign.buttonFontColor,
				ButtonsFontStyle: getFontIdByTechnicalName(updatedDesign.buttonsFont),
				NavigationMenuSelectedColour: updatedDesign.navBarSelectedColor,
				NavigationMenuDeselectedColour: updatedDesign.navBarDeselectedColor,
				NavigationMenuBackgroundColour: updatedDesign.navBarBackgroundColor,
				LoyaltyCardBackgroundColour: updatedDesign.loyaltyCardBackgroundColor,
				LoyaltyCardTextColour: updatedDesign.loyaltyCardTextColor,
				PrimaryBrandFontStyle: getFontIdByTechnicalName(
					updatedDesign.primaryThemeFont
				),
				HeaderFontSize: updatedDesign.appHeaderFontSize,
				PrimaryFontSize: updatedDesign.primaryThemeFontSize,
				ButtonsFontSize: updatedDesign.buttonsFontSize,
				ProgressBarColor: updatedDesign.NavigationMenuSelectedColour,
				RewardSchemePanelBackgroundColour:
				updatedDesign.rewardSchemePanelBackgroundColor,
				RewardSchemeStampBorderColour:
				updatedDesign.rewardSchemeStampBackgroundColor,
				RewardSchemeTextColour: updatedDesign.rewardSchemeTextColor,
				CompanyLogo: updatedDesign.companyLogo,
				CompanyLogoFileType: 1,
				SplashLogo: updatedDesign.splashScreenLogo,
				SplashLogoFileType: 1,
				SplashScreenBackgroundColour: updatedDesign.splashScreenBackgroundColor,
				RewardStamp: updatedDesign.rewardSchemeStampIcon,
				RewardTitle: updatedDesign.rewardSchemeTitle,
				RewardSubTitle: updatedDesign.rewardSchemeSubtitle,
				NumberOfPurchasesRequired: updatedDesign.rewardSchemePurchasesRequired,
				FromWeb: true,
			};
			console.log("Calling SaveFullTheme API:", requestBody);
			setSaveDisabled(true);
			const response = await ApiServiceClass.SaveFullTheme(requestBody);
			if (response.status >= 400) {
				throw {
					status: response.status,
					message: `HTTP Error: ${response.status}`,
				};
			}
			designManager.updateDesign(updatedDesign);
			toast.success("Company Theme Saved", {autoClose: 1500});
		} catch (error) {
			// Consolidated error handling
			const statusCode = error.response?.status || error.status || "Unknown";
			const errorMessage = `Error Saving Company Theme: ${statusCode}`;

			console.error(errorMessage, error);
			toast.error(errorMessage, {autoClose: 1500});
		}
	};

	return (
		<>
			<div>
				<div style={Styles.panel}>
					<div style={Styles.contentWrapper}>
						<div style={Styles.content}>
							<ThemeSelector
								selectedTheme={selectedTheme}
								onThemeSelect={handleThemeSelect}
								sampleThemes={sampleThemesResponse}
							/>
						</div>
					</div>
				</div>
			</div>
			<div style={Styles.actions}>
				<button style={Styles.resetBtn} onClick={handleReset}>
					Reset
					<Tooltip
						title={
							<span style={{display: "block", textAlign: "center"}}>
                This will reset all content to the last saved values
              </span>
						}
						arrow
					>
						<HelpOutlineIcon
							style={{
								marginLeft: "4px",
								fontSize: "17px",
								cursor: "pointer",
							}}
						/>
					</Tooltip>
				</button>
				<button
					style={{
						...Styles.applyBtn,
						backgroundColor: saveDisabled ? "gray" : "#28a745",
						opacity: saveDisabled ? 0.5 : 1,
						cursor: saveDisabled ? "not-allowed" : "pointer",
					}}
					disabled={saveDisabled} // Only disable if no unsaved changes
					onClick={handleSave}
				>
					Save
				</button>
			</div>
		</>
	);
}

export default ThemesContent;
