import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const MiscRenderContent = ({designManager}) => {
	return (
		<div
			style={{
				height: "650px",
				draggable: "false",
				display: "flex",
				zIndex: 0,
				marginTop: "-30px",
				flexDirection: "column",
				backgroundColor: designManager.getFieldValue(
					"splashScreenBackgroundColor"
				),
			}}
		>
			<div
				style={{
					flexDirection: "column",
					alignItems: "center", // Centers items horizontally
					justifyContent: "center", // Centers items vertically
					textAlign: "center",
				}}
			>
				<div
					style={{
						paddingTop: "310px",
					}}
				>
					<CircularProgress
						disableShrink={true}
						thickness={3}
						sx={{
							color: designManager.getFieldValue("loadingSpinnerColor"),
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default MiscRenderContent;
