import React from "react";
import {FormControl, MenuItem, Select} from "@mui/material";

const FontBox = ({selectedFont, fonts = [], onFontChange}) => {
	// Handle font selection change
	const handleChangeComplete = (event) => {
		const newFont = event.target.value; // Get the font selected by the user
		onFontChange(newFont); // Notify the parent about the font change
	};

	return (
		<div>
			<FormControl
				style={{width: "95%", marginBottom: "10px", marginTop: "5px"}}
			>
				<Select
					id="font-select"
					value={selectedFont} // Use selectedFont directly
					style={{backgroundColor: "#fff"}}
					onChange={handleChangeComplete} // Event handler for font change
				>
					{fonts.map((font) => (
						<MenuItem
							key={font.FontTechnicalName}
							value={font.FontTechnicalName} // Use FontTechnicalName as the value
							style={{fontFamily: font.FontTechnicalName}} // Display font using the font family
						>
							{font.FontFriendlyName}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default FontBox;
