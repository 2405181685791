import React, {useState} from "react";
import Styles from "../resources/styles";
import ColourBox from "./sharedcomponents/ColourBox";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import {ApiServiceClass} from "../../api-service";
import {toast} from "react-toastify";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

function MiscellaneousContent({designManager}) {
	const [saveDisabled, setSaveDisabled] = useState(true);
	const [miscContent, setMiscContent] = useState({
		loadingSpinnerColor: designManager.getCurrentDesign().loadingSpinnerColor,
	});

	const handleColorChange = (contentProperty, newProperty) => {
		console.log(`[UNSAVED] Changed '${contentProperty}' to:`, newProperty);
		designManager.setUnsavedChanges({[contentProperty]: newProperty});

		setMiscContent((prevContent) => ({
			...prevContent,
			[contentProperty]: newProperty, // Dynamically update the property
		}));
		setSaveDisabled(false);
	};

	const handleSave = async () => {
		try {
			if (!designManager.hasUnsavedChanges()) {
				console.log("No changes to save.");
				return;
			}
			const updatedDesign = designManager.getUnsavedDesign();
			const requestBody = {
				CompanyId: designManager.getCompanyId(),
				RequesterUserId: `${designManager.getUserId()}`, // User ID in quotes
				ProgressBarColor: updatedDesign.loadingSpinnerColor,
			};
			console.log("Calling MiscellaneousTheme API:", requestBody);
			setSaveDisabled(true);
			const response = await ApiServiceClass.SaveMiscTheme(requestBody);
			if (response.status >= 400) {
				throw {
					status: response.status,
					message: `HTTP Error: ${response.status}`,
				};
			}
			designManager.updateDesign(updatedDesign);
			toast.success("Miscellaneous Theme Saved", {autoClose: 1500});
		} catch (error) {
			// Consolidated error handling
			const statusCode = error.response?.status || error.status || "Unknown";
			const errorMessage = `Error Saving Miscellaneous Theme: ${statusCode}`;

			console.error(errorMessage, error);
			toast.error(errorMessage, {autoClose: 1500});
		}
	};

	const handleReset = () => {
		console.log("Resetting design");
		designManager.resetUnsavedChanges();

		setMiscContent({
			loadingSpinnerColor: designManager.getCurrentDesign().loadingSpinnerColor,
		});
		setSaveDisabled(true);
	};

	return (
		<div style={Styles.panel}>
			<div style={Styles.contentWrapper}>
				<div style={Styles.sectionTitle}>Miscellaneous</div>
				<div style={Styles.contentGrid}>
					<div style={Styles.gridItemLeft}>
						Loading Spinner Colour
						<ColourBox
							initColor={miscContent.loadingSpinnerColor} // Access from colors object
							onColorChange={(newColor) =>
								handleColorChange("loadingSpinnerColor", newColor)
							}
						/>
					</div>
					<div style={Styles.gridItemRight}>
						<Box
							sx={{
								marginTop: "30px",
								border: "2px dashed",
								borderRadius: "6px",
								backgroundColor: "grey.100", // Use the state here
								borderColor: "grey.300",
								height: "60px",
								width: "90%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Box
								sx={{
									p: 2,
									width: "100%",
									height: "100%",
									display: "flex",
									padding: "10px",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<CircularProgress
									disableShrink={true}
									thickness={3}
									sx={{
										color: designManager.getFieldValue("loadingSpinnerColor"),
									}}
								/>
							</Box>
						</Box>
					</div>
				</div>
			</div>
			<div style={Styles.actions}>
				<button style={Styles.resetBtn} onClick={handleReset}>
					Reset
					<Tooltip
						title={
							<span style={{display: "block", textAlign: "center"}}>
                This will reset all content to the last saved values
              </span>
						}
						arrow
					>
						<HelpOutlineIcon
							style={{
								marginLeft: "4px",
								fontSize: "17px",
								cursor: "pointer",
							}}
						/>
					</Tooltip>
				</button>
				<button
					style={{
						...Styles.applyBtn,
						backgroundColor: saveDisabled ? "gray" : "#28a745",
						opacity: saveDisabled ? 0.5 : 1,
						cursor: saveDisabled ? "not-allowed" : "pointer",
					}}
					disabled={saveDisabled} // Only disable if no unsaved changes
					onClick={handleSave}
				>
					Save
				</button>
			</div>
		</div>
	);
}

export default MiscellaneousContent;
