import React from "react";
import {List, ListItem, ListItemText} from "@mui/material";
import {ReactComponent as RightArrow} from "../../../tools/icons/rightarrow.svg";
import battery from "../resources/ios-battery-full-3.svg";
import wifi from "../resources/ios-wifi-2.svg";

const SettingsContent = ({designManager}) => {
	const listItemStyle = {
		display: "flex",
		justifyContent: "space-between",
		padding: "6px 12px",
		borderBottom: "0.2px solid #000",
		width: "95%",
		margin: "0 auto",
	};

	const firstItemStyle = {
		display: "flex",
		justifyContent: "space-between",
		padding: "6px 12px",
		borderBottom: "0.2px solid #000",
		width: "95%",
		margin: "0 auto",
		borderTop: "none", // Explicitly remove top border
	};

	const typographyStyle = {
		fontSize: designManager.getFieldValue("primaryThemeFontSize") - 1,
		fontWeight: 500,
		fontFamily: designManager.getFieldValue("primaryThemeFont"),
		color: designManager.getFieldValue("primaryFontColor"),
	};

	return (
		<div
			style={{
				width: "100%",
				height: "580px",
				backgroundColor: designManager.getFieldValue("primaryBackgroundColor"),
			}}
		>
			<div style={{position: "relative"}}>
				<img
					src={wifi}
					alt="wifi"
					style={{
						width: "18px",
						height: "18px",
						position: "absolute",
						top: "6px",
						right: "55px",
					}}
				/>
				<img
					src={battery}
					alt="battery"
					style={{
						width: "20px",
						height: "20px",
						position: "absolute",
						top: "6px",
						right: "28px",
					}}
				/>
			</div>
			<div
				style={{
					draggable: "false",
					width: "100%",
					height: "68px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					backgroundColor: designManager.getFieldValue(
						"appHeaderBackgroundColor"
					),
					marginTop: "5px",
					zIndex: 2,
				}}
			>
				<div style={{
					fontSize: designManager.getFieldValue("appHeaderFontSize")- 1,
					marginTop: "33px",
					fontFamily: designManager.getFieldValue("appHeaderFont"),
					color: designManager.getFieldValue("appHeaderFontColor"),
				}}>Settings
				</div>
			</div>

			{/* Settings List */}
			<List
				style={{
					width: "100%",
					paddingTop: 10,
				}}
			>
				{/* Change Password */}
				<ListItem button style={firstItemStyle}>
					<ListItemText
						primary={<div style={typographyStyle}>Change Password</div>}
					/>
					<RightArrow
						width="16px"
						height="16px"
						color={designManager.getFieldValue("primaryFontColor")}
					/>
				</ListItem>

				{/* Update Personal Information */}
				<ListItem button style={listItemStyle}>
					<ListItemText
						primary={
							<div style={typographyStyle}>Update Personal Information</div>
						}
					/>
					<RightArrow
						width="16px"
						height="16px"
						color={designManager.getFieldValue("primaryFontColor")}
					/>
				</ListItem>

				<ListItem button style={listItemStyle}>
					<ListItemText
						primary={
							<div style={typographyStyle}>
								Deactivate & delete personal data
							</div>
						}
					/>
					<RightArrow
						width="16px"
						height="16px"
						color={designManager.getFieldValue("primaryFontColor")}
					/>
				</ListItem>

				{/* Logout */}
				<ListItem button style={listItemStyle}>
					<ListItemText primary={<div style={typographyStyle}>Logout</div>}/>
					<RightArrow
						width="16px"
						height="16px"
						color={designManager.getFieldValue("primaryFontColor")}
					/>
				</ListItem>
			</List>

		</div>
	);
};

export default SettingsContent;
