import React from "react";
import Base64Image from "../cards/sharedcomponents/Base64Image";

const SplashContent = ({designManager}) => {
	return (
		<div
			style={{
				height: "670px",
				draggable: "false",
				display: "flex",
				zIndex: 0,
				marginTop: "-30px",
				flexDirection: "column",
				backgroundColor: designManager.getFieldValue(
					"splashScreenBackgroundColor"
				),
			}}
		>
			<div
				style={{
					flexDirection: "column",
					alignItems: "center", // Centers items horizontally
					justifyContent: "center", // Centers items vertically
					textAlign: "center",
				}}
			>
				<div
					style={{
						paddingTop: "280px",
					}}
				>
					<Base64Image
						image={designManager.getFieldValue("splashScreenLogo")}
						fileType={designManager.getFieldValue("splashScreenLogoFileType")}
						style={{
							width: "100%",
							maxWidth: "350px",
							height: "auto", // Ensures the aspect ratio is maintained
							maxHeight: "130px", // Limit the maximum height
							objectFit: "contain", // Ensures the image scales properly within the bounds
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default SplashContent;
