import React from "react";
import {styled} from "@mui/system";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import logoSvg from "../tools/icons/logo.svg";
import lockSvg from "../tools/icons/lock-alt-regular.svg";
import {Box, Menu, MenuItem} from "@mui/material";
import {Link} from "react-router-dom";
import useIsMobile from "./mobile.js";


const loginButtonStyle = {
	backgroundColor: "#673AB7",
	color: "#FFFFFF",
	borderRadius: 10,
	width: "100px",
	height: "40px",
	mt: 4,
	mr: 14,
	textTransform: "none",
	fontFamily: "Poppins-Medium",
	"&:hover": {
		backgroundColor: "rgba(106, 61, 180, 1)",
		borderColor: "white",
		borderWidth: 2,
	},
};

const customToolBarStyle = {
	width: "100%",
	height: "70px",
	backgroundColor: "#FFFFFF",
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
};

const HomeToolBar = () => {
	const isMobile = useIsMobile();
	console.log("isMobile" + isMobile)

	return (
		<div>
			{isMobile ? (
				<Toolbar style={customToolBarStyle}>
					<div
						style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
						<Link to="/home" style={{textDecoration: "none", color: "inherit"}}>
							<Box
								sx={{
									padding: 3,
									width: "100%",
									maxWidth: "100%",
									display: "flex",
									flexDirection: "row",
									alignItems: "center", // Center vertically
								}}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="45"
									height="45"
									style={{marginRight: "10px"}} // Adjusted margin between the SVG and text
								>
									<image href={logoSvg} width="45" height="45" alt="Logo"/>
								</svg>

								<span
									style={{
										fontFamily: "Poppins-SemiBold",
										fontSize: "25px",
										color: "#0260FE",
										whiteSpace: "nowrap",
									}}
								>
                  Reward Space
                </span>
							</Box>
						</Link>

						<MobileMenu/>

					</div>
				</Toolbar>
			) : (
				<Toolbar style={customToolBarStyle}>
					<div style={{display: "flex", alignItems: "center"}}>
						<Link to="/home" style={{textDecoration: "none", color: "inherit"}}>
							<Box
								sx={{
									padding: 3,
									width: "100%",
									maxWidth: "100%",
									display: "flex",
									flexDirection: "row",
									alignItems: "center", // Center vertically
								}}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="45"
									height="45"
									style={{marginRight: "10px"}} // Adjusted margin between the SVG and text
								>
									<image href={logoSvg} width="45" height="45" alt="Logo"/>
								</svg>

								<span
									style={{
										fontFamily: "Poppins-SemiBold",
										fontSize: "25px",
										color: "#0260FE",
										whiteSpace: "nowrap",
									}}
								>
                  Reward Space
                </span>
							</Box>
						</Link>

						{getLink("Pricing")}
						{getLink("Features")}
						{getLink("Contact")}
					</div>


					<div style={{display: "flex", alignItems: "center"}}>
						<Link
							to="/Login"
							style={{textDecoration: "none", color: "inherit"}}
						>
							<Box
								sx={{
									padding: 3,
									width: "100%",
									maxWidth: "100%",
									display: "flex",
									flexDirection: "row",
									alignItems: "center", // Center vertically
								}}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="20"
									height="20"
									style={{
										marginRight: "10px",
										fill: "#FFFFFF"
									}} // Adjusted margin between the SVG and text
								>
									<image href={lockSvg} width="20" height="20" alt="Logo"/>
								</svg>

								<span
									style={{
										fontFamily: "Poppins-Regular",
										fontSize: "16px",
										color: "#0D0C54",
										whiteSpace: "nowrap",
									}}
								>
                  Login
                </span>
							</Box>
						</Link>

						<div style={{marginLeft: "10px"}}/>
						<CustomButton
							type="submit"
							href="/Register"
							style={{
								cursor: "pointer",
								marginRight: "45px",
								fontFamily: "Poppins-Regular",
								backgroundColor: "#0260FE",
							}}
						>
							Try Free
						</CustomButton>
					</div>
				</Toolbar>
			)}
		</div>
	);
};

const getLink = (text) => (
	<span
		style={{
			marginLeft: "40px",
			fontSize: "25px",
			color: "#000000",
			whiteSpace: "nowrap",
		}}
	>
    <a
		href={`/${text}`}
		style={{
			fontFamily: "Poppins-Regular",
			fontSize: "19px",
			color: "#0D0C54",
			textDecoration: "none",
			whiteSpace: "nowrap",
		}}
	>
      {text}
    </a>
  </span>
);

const CustomButton = styled(Button)(loginButtonStyle);

const MobileMenu = () => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<MenuIcon
				size="large"
				edge="start"
				color="inherit"
				cursor='pointer'
				aria-label="menu"
				onClick={handleMenuClick}
				sx={{color: "#000000"}}
			>
				<MenuIcon/>
			</MenuIcon>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem component={Link} to="/Pricing" onClick={handleClose}>
					Pricing
				</MenuItem>
				<MenuItem component={Link} to="/Features" onClick={handleClose}>
					Features
				</MenuItem>
				<MenuItem component={Link} to="/Contact" onClick={handleClose}>
					Contact
				</MenuItem>
				<MenuItem component={Link} to="/Login" onClick={handleClose}>
					Login
				</MenuItem>
				<MenuItem component={Link} to="/Register" onClick={handleClose}>
					Register
				</MenuItem>
				<MenuItem component={Link} to="/Dashboard" onClick={handleClose}>
					Dashboard
				</MenuItem>
			</Menu>
		</div>
	);
};

export {HomeToolBar};
