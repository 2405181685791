import React from "react";

const TextBubble = ({text, isLeft}) => {
	const bubbleStyle = {
		maxWidth: "60%",
		padding: "10px 20px",
		borderRadius: "20px",
		fontFamily: "Poppins-Regular, sans-serif",
		fontSize: "18px",
		lineHeight: "1.5",
		backgroundColor: isLeft ? "#007aff" : "#ffffff",
		color: isLeft ? "white" : "#171663",
		alignSelf: isLeft ? "flex-start" : "flex-end",
		marginBottom: "30px",
		marginLeft: "0px",
		display: "flex",
		justifyContent: isLeft ? "flex-start" : "flex-end",
		position: "relative",
	};

	const containerStyle = {
		display: "flex",
		justifyContent: isLeft ? "flex-start" : "flex-end",
	};

	const triangleStyle = {
		content: '""',
		position: "absolute",
		bottom: "-10px",
		width: 0,
		height: 0,
		borderStyle: "solid",
		borderWidth: isLeft ? "10px 10px 0 0" : "10px 0 0 10px",
		borderColor: isLeft
			? "#007aff transparent transparent transparent"
			: "#ffffff transparent transparent transparent",
		left: isLeft ? "20px" : "auto",
		right: isLeft ? "auto" : "20px",
	};

	const triangleShadowStyle = {
		content: '""',
		position: "absolute",
		bottom: "-11px",
		width: 0,
		height: 0,
		borderStyle: "solid",
		borderWidth: isLeft ? "11px 11px 0 0" : "11px 0 0 11px",
		borderColor: isLeft
			? "#007aff transparent transparent transparent"
			: "#ffffff transparent transparent transparent",
		left: isLeft ? "20px" : "auto",
		right: isLeft ? "auto" : "20px",
		filter: "blur(1px)",
	};

	return (
		<div style={containerStyle}>
			<div style={bubbleStyle}>
				{text}
				<span style={triangleShadowStyle}></span>
				<span style={triangleStyle}></span>
			</div>
		</div>
	);
};

export default TextBubble;
