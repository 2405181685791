import React, {useState} from "react";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const NewsSection = ({newsItems}) => {
	const [expanded, setExpanded] = useState({});

	const handleExpandClick = (index) => {
		setExpanded((prev) => ({...prev, [index]: !prev[index]}));
	};

	const renderNewsItem = (item, index) => {
		const isExpanded = expanded[index] || false;
		const words = item.content.split(" ");
		const previewText = words.slice(0, 40).join(" ");
		const remainingText = words.slice(40).join(" ");

		return (
			<div key={index} style={{padding: "12px 0"}}>
				<div style={{
					marginBottom: "12px",
					fontSize: "12px",
					display: "flex",
					alignItems: "center",
					gap: "8px",
				}}>
                    <span style={{
						backgroundColor: item.category === "News" ? "rgba(33, 128, 126, 0.9)" : "rgba(230, 69, 62, 0.9)",
						color: "#ffffff",
						borderRadius: "10px",
						padding: "2px 10px",
					}}>
                        {item.category}
                    </span>
					<span style={{
						color: "rgba(153, 159, 159, 0.8)",
						fontSize: "12px",
					}}>
                        {item.date}
                    </span>
				</div>
				<div style={{
					marginBottom: "10px",
					fontSize: "16px",
					color: "#171663",

				}}>
					{item.title}
				</div>
				<div style={{
					marginBottom: "5px",
					fontSize: "14px",
					color: "#000000",
					lineHeight: "1.5",
					fontWeight: "normal",
				}}>
					{previewText}
					{isExpanded && remainingText}
					{!isExpanded && remainingText && "..."}
					{remainingText && (
						<span>
                            <a href="#"
							   onClick={(e) => {
								   e.preventDefault();
								   handleExpandClick(index);
							   }}
							   style={{
								   color: "#2d62f9",
								   marginLeft: "8px",
								   textDecoration: "none",
								   cursor: "pointer"
							   }}
							>
                                {isExpanded ? "Read less" : "Read more"}
                            </a>
                            <IconButton
								onClick={() => handleExpandClick(index)}
								style={{padding: 0, verticalAlign: "middle"}}
								size="small"
							>
                                {isExpanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                            </IconButton>
                        </span>
					)}
				</div>
			</div>
		);
	};

	const sortedNewsItems = [...newsItems].reverse();

	return (
		<div style={{
			color: "#171663",
			fontSize: "16px",
			padding: "5px",
			marginBottom: "16px",
			paddingBottom: "8px",
			borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
		}}>
			What's new?

			<div style={{overflowY: "auto"}}>
				{sortedNewsItems.map((item, index) => (
					<div key={index}>
						{renderNewsItem(item, index)}
						{index < sortedNewsItems.length - 1 && (
							<div style={{
								borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
								margin: "12px 0"
							}}/>
						)}
					</div>
				))}
			</div>
		</div>
	);
};

export default NewsSection;