import Box from "@mui/material/Box";
import List from "@mui/material/List";
import MuiDrawer from "@mui/material/Drawer";
import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import NavBarItems from '../components/navbaritems';
import "../theme/styles/font.css";

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open" && prop !== "drawerWidth",
})(({theme, open, drawerWidth}) => ({
	"& .MuiDrawer-paper": {
		position: "relative",
		whiteSpace: "nowrap",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: "border-box",
		display: "flex",
		flexDirection: "column",
		height: "100vh", // Full viewport height
		overflow: "hidden", // Prevent overflow
		...(!open && {
			overflowX: "hidden",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: theme.spacing(7),
			[theme.breakpoints.up("sm")]: {
				width: theme.spacing(9),
			},
		}),
	},
}));

export const Navbar = ({name, title = "", children}) => {
	const [drawerWidth, setDrawerWidth] = useState(240);
	const [navOpen, setNavOpen] = useState(true);

	const toggleDrawer = () => {
		setNavOpen((prev) => !prev);
		setDrawerWidth(prevWidth => prevWidth === 240 ? 55 : 240);
	};

	return (
		<Box component="main" sx={{display: "flex", width: "100%", height: "100vh"}}>
			<link rel="stylesheet" href="../theme/styles/font.css" type="text/css"/>
			<Drawer variant="permanent" open={navOpen} drawerWidth={drawerWidth}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						height: "100%", // Full height of the Drawer
						overflow: "hidden", // Prevent overflow
						borderRight: "1px solid #e3e6f0", // Adds a border with a light gray color
					}}

				>
					<List component="nav" sx={{flexGrow: 1, overflowY: "auto"}}>
						<NavBarItems name={name} navOpen={navOpen} toggleDrawer={toggleDrawer}/>
					</List>
				</Box>
			</Drawer>

			<Box
				sx={{
					flex: 1,
					display: "flex",
					flexDirection: "column",
					overflow: "auto",
					backgroundColor: "#000000",
				}}
			>
				<Box
					sx={{
						fontFamily: "Poppins-Medium",
						fontSize: "18px",
					}}
				>
					{/* Optional header content */}
				</Box>
				<Box sx={{backgroundColor: "rewardspace.background"}}>
					<div style={{minHeight: "100vh", overflow: "hidden", height: "auto"}}>
						{children}
				</div>
			</Box>
		</Box>
</Box>
)
	;
};

export default Navbar;