import React, {useState} from "react";
import {FormControl, IconButton, InputAdornment, TextField,} from "@mui/material";
import {Add, Remove} from "@mui/icons-material";
import Styles from "../../resources/styles";

function NumberBox({defaultValue, maxValue, minValue = 0, onChange}) {
	const [numberValue, setNumberValue] = useState(
		defaultValue !== undefined ? defaultValue : 8
	);

	const handleChange = (e) => {
		const value = e.target.value;
		if (!isNaN(value) && value !== "") {
			const numericValue = Number(value);
			if (numericValue <= maxValue && numericValue >= minValue) {
				setNumberValue(numericValue);
				onChange(numericValue);
			} else if (numericValue > maxValue) {
				setNumberValue(maxValue);
				onChange(maxValue);
			} else {
				setNumberValue(minValue);
				onChange(minValue);
			}
		}
	};

	const handleIncrement = () => {
		setNumberValue((prevValue) => {
			const newValue = Math.min(prevValue + 1, maxValue);
			if (newValue !== prevValue) {
				onChange(newValue);
				return newValue;
			}
			return prevValue;
		});
	};

	const handleDecrement = () => {
		setNumberValue((prevValue) => {
			const newValue = Math.max(prevValue - 1, minValue);
			if (newValue !== prevValue) {
				onChange(newValue);
				return newValue;
			}
			return prevValue;
		});
	};

	return (
		<FormControl>
			<div style={Styles.numberBox}>
				<TextField
					id="number-input"
					value={numberValue}
					onChange={handleChange}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<IconButton
									onClick={handleDecrement}
									style={{padding: 0, marginRight: "8px"}}
								>
									<Remove/>
								</IconButton>
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									onClick={handleIncrement}
									style={{padding: 0, marginLeft: "8px"}}
								>
									<Add/>
								</IconButton>
							</InputAdornment>
						),
						style: {fontSize: "16px", textAlign: "center"},
					}}
					variant="outlined"
					inputProps={{
						style: {
							textAlign: "center",
						},
					}}
				/>
			</div>
		</FormControl>
	);
}

export default NumberBox;
