import React from "react";

const DashboardCard = ({
					 title = "title",
					 subtext = "Not published",
					 iconSrc = "/path/to/pwa-logo.png",
					 buttonText = "Publish your PWA",
					 buttonColour = "#16B07AE6",
					 imageWidth = "40px",
					 imageHeight = "40px",
					 buttonHref = "#",
				 }) => {
	const cardStyle = {
		border: "1px solid #e0e0e0",
		borderRadius: 10,
		padding: 22,
		minWidth: 220,
		width: "20%",
		height: 210,
		boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
		margin: "0 10px",
		background: "#fff",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-between",
	};

	const ButtonComponent = buttonHref ? "a" : "button";

	return (
		<div style={cardStyle}>
			<img src={iconSrc} alt="icon" style={{width: imageWidth, height: imageHeight}}/>

			<div style={{textAlign: "center", gap: 6}}>
				<h3 style={{margin: 0, fontSize: 16}}>{title}</h3>
				<p style={{margin: 0, color: "#888", fontSize: 15}}>{subtext}</p>
			</div>

			<ButtonComponent
				href={buttonHref}
				style={{
					background: buttonColour,
					color: "white",
					border: "none",
					borderRadius: 16,
					padding: "8px 12px",
					width: 190,
					fontSize: 13,
					cursor: "pointer",
					textAlign: "center",
					textDecoration: "none",
				}}
			>
				{buttonText}
			</ButtonComponent>
		</div>
	);
};

export default DashboardCard;