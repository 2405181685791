import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import React, {useState} from "react";
import {InputAdornment, OutlinedInput, SvgIcon,} from "@mui/material";

const CustomersSearch = ({onSearchChange, listCount}) => {
	const [searchText, setSearchText] = useState("");

	const handleInputChange = (event) => {
		const value = event.target.value;
		setSearchText(value);
		onSearchChange(value);
	};

	return (
		<div style={{display: "flex", flexDirection: "column"}}>
			<div style={{display: "flex", alignItems: "center"}}>
				<OutlinedInput
					maxWidth="100px"
					placeholder="Search for a customer"
					startAdornment={
						<InputAdornment position="start">
							<SvgIcon color="action" fontSize="small">
								<MagnifyingGlassIcon/>
							</SvgIcon>
						</InputAdornment>
					}
					onChange={handleInputChange}
					sx={{maxWidth: 500, minWidth: 200, backgroundColor: "#ffffff"}}
				/>
			</div>
			{/*
      <Typography variant="body2" sx={{ marginLeft: "5px", marginTop: "10px" }}>
        Total Customers: {listCount}
      </Typography> */}
		</div>
	);
};

export default CustomersSearch;
