// BrandingLeftPanel.js
import React from "react";
import {Box,} from "@mui/material";
import appImage642x349 from "../tools/pngs/Branding613x305.png";
// import IconTable from "../components/icon-table.js"


export const RewardsLeftPanel = ({
									 stamp,
									 stampName,
									 stampColor,
									 stampBackgroundColor,
									 backgroundColor,
									 rewardName,
									 rewardSubtitle,
									 noPurchases,
								 }) => {
	const rewardNameHeight = 10; // Update with the actual height based on your styling

	return (
		<Box
			sx={{
				position: "relative",
				width: "305px",
				height: "614px",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<img
				src={appImage642x349}
				alt="App Background"
				style={{
					width: "100%",
					height: "100%",
					objectFit: "cover",
					position: "absolute",
				}}
			/>

			{/* reward */}
			{/* <IconTable
          title={rewardName}
          subtitle={rewardSubtitle}
          icon={stamp}
          iconColor={stampColor}
          count={noPurchases}
          stampBackgroundColor={stampBackgroundColor}
          backgroundColor={backgroundColor}
        /> */}

			{/* <Button
          type="submit"
          variant="contained"
          sx={{
            top: "20%",
            mb: 2,
            maxWidth: "md",
            // to be button color defined in design home page
            // color: appFontColor,
            // backgroundColor: appActionColor,
          }}
        >
          Your Button
        </Button> */}
		</Box>
	);
};

export default RewardsLeftPanel;
