import React, {useState} from "react";
import {Box, Button, Table, TableBody, TableCell, TableRow, TextField,} from "@mui/material";
import MyColor from "../components/my-color";


export const RewardsRightPanel = ({
									  stamps,
									  stampName,
									  setStampName,
									  stampColor,
									  setStampColor,
									  rewardName,
									  setRewardName,
									  rewardSubtitle,
									  setRewardSubtitle,
									  backgroundColor,
									  setBackgroundColor,
									  stampBackgroundColor,
									  setStampBackgroundColor,
									  noPurchases,
									  setNoPurchases,
									  saveReward,
								  }) => {
	const [showStampColor, setShowStampColor] = useState(false);
	const [showStampBackgroundColor, setShowStampBackgroundColor] = useState(false);
	const [showBackgroundColor, setShowBackgroundColor] = useState(false);

	return (
		<Box
			sx={{
				// padding: '10px',
				borderRadius: "25px",
				flexDirection: "column",
				border: "none",
				width: '100%',
			}}
		>
			<Box sx={{display: 'flex', justifyContent: 'flex-start', marginBottom: '20px'}}>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell sx={{border: "none", width: '50%'}}>
								<TextField
									variant="outlined"
									label="Reward Title"
									fullWidth
									id="rewardName"
									value={rewardName}
									onChange={setRewardName}
								/>
							</TableCell>
							<TableCell sx={{border: "none", width: '50%'}}>
								<TextField
									variant="outlined"
									label="Reward Subtitle"
									fullWidth
									id="rewardSubtitle"
									value={rewardSubtitle}
									onChange={setRewardSubtitle}
								/>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</Box>

			<Box sx={{display: 'flex', justifyContent: 'flex-start', marginBottom: '20px'}}>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell sx={{border: "none"}}>
								<label htmlFor="iconSelector">Reward Stamp: </label>
								<select
									id="iconSelector"
									value={stampName}
									onChange={setStampName}
								>
									{Object.keys(stamps).map((stampName) => (
										<option key={stampName} value={stampName}>
											{stampName}
										</option>
									))}
								</select>
							</TableCell>
							<TableCell sx={{border: "none"}}>
								<TextField
									variant="outlined"
									label="Purchases Required"
									id="noPurchases"
									fullWidth
									value={noPurchases}
									onChange={(event) => {
										let value = parseInt(event.target.value, 10);
										// Cap the value at 20
										value = isNaN(value) ? 0 : Math.min(value, 12);
										setNoPurchases(value)
									}}
								/>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</Box>

			<Box sx={{display: 'flex', justifyContent: 'flex-start', marginBottom: '20px'}}>
				<Table>
					<TableBody>
						<TableRow>
							<TableCell>
								<MyColor
									name="Stamp Colour"
									tooltip="Stamp Colour"
									color={stampColor}
									setColor={setStampColor}
									showColorPicker={showStampColor}
									setShowColorPicker={setShowStampColor}
								/>
							</TableCell>

							<TableCell>
								<MyColor
									name="Stamp Background Colour"
									tooltip="Stamp Background Colour"
									color={stampBackgroundColor}
									setColor={setStampBackgroundColor}
									showColorPicker={showStampBackgroundColor}
									setShowColorPicker={setShowStampBackgroundColor}
								/>
							</TableCell>

							<TableCell>
								<MyColor
									name="Background Colour"
									tooltip="Background Colour"
									color={backgroundColor}
									setColor={setBackgroundColor}
									showColorPicker={showBackgroundColor}
									setShowColorPicker={setShowBackgroundColor}
								/>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</Box>

			<Box
				sx={{
					padding: 5,
					bgcolor: "test.4",
					borderRadius: "10px",
					flexDirection: "column",
					display: "flex",
					alignItems: "center",
				}}
			>
				<Button
					type="submit"
					onClick={saveReward}
					variant="contained"
					sx={{
						mb: 2,
						maxWidth: "md",
					}}
				>
					Save Changes
				</Button>
			</Box>
		</Box>
	);
};

export default RewardsRightPanel;
