/*eslint-env es6*/
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, {useEffect} from "react";
import {HomeToolBar} from "../components/home-toolbar.js";
import lottie from "lottie-web";
import BarChart from "../tools/animation/bar-chart.json";
import QRCode from "../tools/animation/qr-code.json";
import Colors from "../tools/animation/colors.json";
import Gift from "../tools/animation/gift.json";
import Lightbulb from "../tools/animation/lightbulb.json";
import Notification from "../tools/animation/notification.json";
import TextBubble from "../components/textbubble.js";
import Code from "../tools/animation/code.json";
import Button from "@mui/material/Button";


const Feature = ({title, text, color, animation, animationSide}) => {
	return (
		<div
			style={{
				width: "100%",
				background: color,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				transform: "rotate(-2deg)",
				transition: "all 0.5s ease",
				boxSizing: "border-box",
				paddingTop: "35px",
			}}
		>
			<Box
				sx={{
					width: "80%",
					maxWidth: "1100px",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					margin: "0 auto",
					transform: "rotate(2deg)",
				}}
			>
				<Grid
					container
					spacing={2}
					direction={animationSide === "left" ? "row-reverse" : "row"}
					alignItems="center"
					justifyContent="center"
					style={{marginBottom: "20px"}}
				>
					<Grid
						item
						xs={12}
						md={6}
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
							paddingLeft: animationSide === "right" ? "20px" : "0",
							paddingRight: animationSide === "left" ? "20px" : "0",
						}}
					>
						<Typography
							variant="h5"
							style={{
								fontFamily: "Poppins-Semibold",
								color: "#171663",
								marginBottom: "10px",
								textAlign: animationSide === "left",  // ? "right" : "left",
							}}
						>
							{title}
						</Typography>
						<Typography
							style={{
								fontFamily: "Poppins-Regular",
								color: "#171663",
								fontSize: "16px",
								textAlign: animationSide === "left",  // ? "right" : "left",
							}}
						>
							{text}
						</Typography>
						<br/>
					</Grid>

					<Grid
						item
						xs={12}
						md={6}
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Box
							id={animation}
							sx={{
								width: "250px",
								height: "250px",
								marginTop: "0px",
								marginBottom: "0px",
								marginLeft: animationSide === "left" ? "50px" : "0",
								marginRight: animationSide === "right" ? "50px" : "0",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</div>
	);
};


export default function Features() {
	const messages = [
		{
			// text: "Customer loyalty isn't just a concept — it's a tangible asset that shapes your business",
			text: "Customer loyalty plays an important role in the long-term success of small and medium sized enterprises and is important for maintaining market share.",
			isLeft: true,
		},
		{
			// text: "Reward Space offers an all in one solution for business in a digital first world",
			text: "Reward Space offers an all in one solution designed to address the unique needs of business in a digital first world. Customer loyalty isn't just a concept — it's a tangible asset driving your business towards unprecedented success.",      // text: "Reward Space offers an all in one solution for business in a digital first world",
			isLeft: false,
		},
	];

	useEffect(() => {
		const loadAnimation = (containerId, animationData) => {
			lottie.loadAnimation({
				container: document.getElementById(containerId),
				renderer: "svg",
				loop: false,
				autoplay: true,
				animationData: animationData,
			});
		};


		const observerCallback = (entries, observer) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					const containerId = entry.target.id;
					let animationData;

					switch (containerId) {
						case "lottie-container-barchart":
							animationData = BarChart;
							break;
						case "lottie-container-qrcode":
							animationData = QRCode;
							break;
						case "lottie-container-colors":
							animationData = Colors;
							break;
						case "lottie-container-gift":
							animationData = Gift;
							break;
						case "lottie-container-notification":
							animationData = Notification;
							break;
						case "lottie-container-code":
							animationData = Code;
							break;
						case "lottie-container-lightbulb":
							animationData = Lightbulb;
							break;
						default:
							return;
					}

					loadAnimation(containerId, animationData);
					observer.unobserve(entry.target);
				}
			});
		};

		const observerOptions = {
			root: null, // Use the viewport as the root
			rootMargin: "0px",
			threshold: 0.1, // Trigger when 10% of the element is visible
		};

		const observer = new IntersectionObserver(
			observerCallback,
			observerOptions
		);

		const containerIds = [
			"lottie-container-barchart",
			"lottie-container-qrcode",
			"lottie-container-colors",
			"lottie-container-gift",
			"lottie-container-notification",
			"lottie-container-code",
			"lottie-container-lightbulb",
		];

		containerIds.forEach((id) => {
			const element = document.getElementById(id);
			if (element) {
				observer.observe(element);
			}
		});

		return () => {
			observer.disconnect();
		};
	}, []);

	return (
		<div
			style={{
				width: "100%",
				overflowX: "hidden",
				// maxWidth: "100%",
				//background: "#000000",
			}}
		>
			<HomeToolBar/>

			<Box
				sx={{
					width: "100%", // Center content and limit width to 50%
					flexDirection: "column",
					alignItems: "center",
					overflow: "hidden",
				}}
			>
				<div
					style={{
						width: "101%", // Set to 100% to fit page width
						minHeight: "51vh",
						background: "linear-gradient(to bottom right, #EDF0FA , #EDF0FA)",
						backgroundSize: "101% 101%", // Ensure background covers the entire area
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						transform: "rotate(-2deg)",
						transition: "all 0.5s ease",
						boxSizing: "border-box", // Ensure box-sizing
						marginTop: 50,
						paddingTop: 5,
						marginBottom: 35,
						paddingBottom: 30,
					}}
				>
					<Typography
						variant="h1"
						style={{
							transform: "rotate(2deg)",
							padding: "40px",
							paddingTop: "64px",
							// background: "#FFC107",
							WebkitBackgroundClip: "text",
							color: "#171663",
							fontFamily: "Poppins-Medium",
							textAlign: "start",
							fontSize: "45px",
						}}
					>
						How <span style={{color: "#0260FE"}}>Reward Space </span> can help{" "}
						<span style={{color: "#0260FE"}}> grow </span> your business
					</Typography>

					<Box
						sx={{
							width: "90%",
							maxWidth: "1100px",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<div
							style={{
								transform: "rotate(2deg)",
								paddingTop: "10px",
							}}
						>
							{messages.map((msg, index) => (
								<TextBubble key={index} text={msg.text} isLeft={msg.isLeft}/>
							))}
						</div>
					</Box>
				</div>
			</Box>


			{/* Title */}
			<Box
				sx={{
					width: "100%",
					flexDirection: "column",
					alignItems: "center",
					display: "flex",
					overflow: "hidden",
				}}>
				<Box
					sx={{
						width: "90%",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						margin: "0 auto", // Center the box horizontally
					}}>
					<Typography
						variant="h4"
						style={{
							color: "#171663",
							fontFamily: "Poppins-Semibold",
							fontSize: "35px",
							marginTop: "20px",
							// paddingBottom: "20px",
						}}
					>
						Our Features
					</Typography>
				</Box>
			</Box>


			{/* First 2  */}
			<Feature
				title="Loyalty schemes, gone digital"
				text="Create your own custom rewards programme that suits your
                business. Whether it's a free haircut or coffee, you can choose
                what's right for you and keep customers coming back for more."
				color="#ffffff"
				animationSide='right'
				animation="lottie-container-gift"/>

			<Feature
				title="Build your own app, no coding required!"
				text="Use our online tool to build and customize your own mobile
              app. We handle everything else and get it on the iOS and
              Android app store."
				color="#ffffff"
				animationSide='left'
				animation="lottie-container-colors"/>

			<Feature
				title="Data and analytics dashboard"
				text="We analyse and build useful data insights about your
                    customers. Drive your business growth through data."
				color="#EDF0FA"
				animationSide='right'
				animation="lottie-container-barchart"/>

			<Feature
				title="Reach out to your customers"
				text="Setup and send custom push notifications, email or SMS
                    messages directly to your customers."
				color="#EDF0FA"
				animationSide='left'
				animation="lottie-container-notification"/>

			<Feature
				title="Your mobile app, our expertise"
				text="We handle all technical aspects and will ensure your mobile
                  app is available, updated and secure."
				color="#ffffff"
				animationSide='right'
				animation="lottie-container-code"/>

			<Feature
				title="More coming soon!"
				text="We are always working hard to deliver more features, get in touch if you have an idea!"
				color="#ffffff"
				animationSide='left'
				animation="lottie-container-lightbulb"/>


			<Box
				id={`lottie-container-rocket`}
				sx={{
					width: "90px",
					height: "90px",
					marginTop: "10px",
				}}>
			</Box>

			<Box
				sx={{
					display: "flex",
					backgroundColor: "#EDF0FA",
					flexDirection: "column", // Change flex direction to "column"
					alignItems: "center",
					justifyContent: "center", // Center the content horizontally
				}}
			>
				<div
					style={{
						width: "100%", // Adjusted width
						height: "23vh",
						minHeight: "300px",
						display: "flex",
						flexDirection: "column", // Change flex direction to "column"
						alignItems: "center",
						justifyContent: "center", // Center the content horizontally
						textAlign: "center", // Center text horizontally within the box
						transition: "all 0.5s ease",
						boxSizing: "border-box", // Ensure box-sizing
						margin: 0, // Reset margin
						padding: 0, // Reset padding
						backgroundSize: "cover",
					}}
				>
					<Typography
						style={{
							fontFamily: "Poppins-Semibold",
							textAlign: "center",
							color: "#171663",
							fontSize: "30px",
						}}
					>
						Get in touch!
						<br/>
					</Typography>

					<Button
						type="submit"
						href="/Register"
						variant="contained"
						sx={{
							fontFamily: "Poppins-SemiBold",
							mt: 2,
							mb: 1,
							height: "60px",
							width: "30%",
							minWidth: "200px"
						}}
					>
						sign up for a free demo account
					</Button>
				</div>
			</Box>
		</div>
	);
}
