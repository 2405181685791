import React, {useState} from "react";
import {FormControl, TextField} from "@mui/material";

function TextBox({text, defaultText = "Enter your reward name", onChange}) {
	const [textValue, setTextValue] = useState(text); // Start with an empty string
	const [isFocused, setIsFocused] = useState(false);

	const handleChange = (e) => {
		const newValue = e.target.value;
		if (newValue.length <= 28) {
			// Restrict to a max of 25 characters
			setTextValue(newValue);
			onChange(newValue);
		}
	};

	const handleFocus = () => {
		setIsFocused(true);
	};

	const handleBlur = () => {
		setIsFocused(false);
	};

	return (
		<div>
			<FormControl
				style={{width: "90%", marginBottom: "3%", marginTop: "1%"}}
			>
				<TextField
					id="font-input"
					value={textValue}
					onChange={handleChange}
					onFocus={handleFocus}
					onBlur={handleBlur}
					placeholder={defaultText} // Placeholder text when input is empty
					InputProps={{
						style: {
							textAlign: "center", // Center the text in the input
							color: textValue ? "black" : "rgba(0, 0, 0, 0.5)", // Faded color for placeholder text
						},
					}}
					variant="outlined" // Optional: to ensure borders are properly aligned
				/>
			</FormControl>
		</div>
	);
}

export default TextBox;
