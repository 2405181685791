import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import Navbar from "./navbar";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Confetti from "../tools/animation/confetti.json";
import lottie from "lottie-web";
import Button from "@mui/material/Button";
import ReactConfetti from "react-confetti";
import Getcookies from "../components/getcookies";

export default function SubscriptionComplete() {
	const cookies = Getcookies();
	const [isConfettiActive, setIsConfettiActive] = useState(false);
	const lottieContainer = useRef(null);

	useEffect(() => {
			const anim = lottie.loadAnimation({
				container: lottieContainer.current,
				renderer: "svg",
				loop: false,
				autoplay: true,
				animationData: Confetti,
			});
			setIsConfettiActive(true);
			const timer = setTimeout(() => setIsConfettiActive(false), 5000);

			return () => {
				clearTimeout(timer);
				anim.destroy(); // Clean up the animation
			};
	}, []);

	return (
		<Navbar
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				minHeight: "100vh",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					p: 2,
					marginTop: "5%"
				}}
			>
				<Box
					ref={lottieContainer}
					sx={{
						width: "15%",
						height: "15%",
					}}
				></Box>

				<Typography
					variant="h4"
					style={{
						background: "#FFC107",
						WebkitBackgroundClip: "text",
						color: "#0260FE",
						fontFamily: "Poppins-Medium",
						textAlign: "start",
						fontSize: "18px",
						marginTop: "16px",
					}}
				>
					Your subscription has been activated
				</Typography>

				<Typography
					variant="h4"
					style={{
						background: "#FFC107",
						WebkitBackgroundClip: "text",
						color: "#171663",
						fontFamily: "Poppins-Medium",
						textAlign: "start",
						fontSize: "28px",
						marginTop: "16px",
					}}
				>
					Thank you and welcome to Reward Space
				</Typography>

				<Button
					type="submit"
					href="/publish"
					fontSize="20px"
					variant="contained"
					sx={{
						fontFamily: "Poppins-SemiBold",
						mt: 4,
						mb: 4,
						height: "50px",
						width: "20%",
					}}
				>
					Publish your app!
				</Button>

				{isConfettiActive && <ReactConfetti/>}
			</Box>
		</Navbar>
	);
}
