import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle,} from "@mui/material";
import {styled} from "@mui/system";

const CenteredDialogActions = styled(DialogActions)({
	display: "flex",
	justifyContent: "center",
});

const ResetWarning = ({isOpen, onYes, onClose}) => {
	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			PaperProps={{style: {borderRadius: "5px", width: "460px"}}}
		>
			<DialogTitle style={{paddingBottom: "5px", textAlign: "center"}}>
				Warning
			</DialogTitle>
			<DialogContent
				style={{
					paddingBottom: "15px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					textAlign: "center", // Ensure text is centered
				}}
			>
				You are about to fully reset your app design. <br></br>This will clear
				all changes made by you and reset the design to the default values.
				<br></br>
				<br></br>Do you wish to reset your design?
			</DialogContent>
			<CenteredDialogActions
				style={{
					paddingBottom: "15px",
					alignItems: "center",
					justifyContent: "center",
					gap: "25%",
				}}
			>
				<Button
					onClick={onYes} // Trigger the callback for "Yes" click
					color="primary"
					style={{width: "30%", marginRight: "35px", borderRadius: "5px"}}
				>
					Yes
				</Button>
				<Button
					onClick={onClose}
					color="primary"
					style={{width: "30%", borderRadius: "5px"}}
				>
					No
				</Button>
			</CenteredDialogActions>
		</Dialog>
	);
};
export default ResetWarning;
