import React from "react";
import barcode from "../../../tools/pngs/barcode.png";
import Base64Image from "../cards/sharedcomponents/Base64Image";
import battery from "../resources/ios-battery-full-3.svg";
import wifi from "../resources/ios-wifi-2.svg";

const CardContent = ({designManager}) => {
	return (
		<div
			style={{
				width: "100%",
				height: "580px",
				backgroundColor: designManager.getFieldValue("primaryBackgroundColor"),
			}}
		>
			<div style={{position: "relative"}}>
				<img
					src={wifi}
					alt="wifi"
					style={{
						width: "18px",
						height: "18px",
						position: "absolute",
						top: "6px",
						right: "55px",
					}}
				/>
				<img
					src={battery}
					alt="battery"
					style={{
						width: "20px",
						height: "20px",
						position: "absolute",
						top: "6px",
						right: "28px",
					}}
				/>
			</div>

			<div
				style={{
					draggable: "false",
					width: "100%",
					height: "68px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					backgroundColor: designManager.getFieldValue(
						"appHeaderBackgroundColor"
					),
					marginTop: "5px",
					zIndex: 2,
				}}
			>
				<div style={{
					fontSize: designManager.getFieldValue("appHeaderFontSize") - 1,
					marginTop: "33px",
					fontFamily: designManager.getFieldValue("appHeaderFont"),
					color: designManager.getFieldValue("appHeaderFontColor"),
				}}>Loyalty
					Card
				</div>
			</div>

			<div
				style={{
					width: "91%",
					height: "170px",
					backgroundColor: designManager.getFieldValue(
						"loyaltyCardBackgroundColor"
					),
					borderRadius: "12px",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					marginTop: "25px",
					marginLeft: "10px",
					boxShadow: "0 6px 10px rgba(0, 0, 0, 0.70)", // Add box shadow here
					padding: 2
				}}
			>
				{/* New container for the icon and name */}
				<div
					style={{
						width: "90%",
						marginLeft: "-5px",
						marginTop: "10px",
						//backgroundColor: "#000000",
						paddingBottom: "3px",
					}}
				>
					<Base64Image
						image={designManager.getFieldValue("companyLogo")}
						fileType={designManager.getFieldValue("companyLogoFileType")}
						style={{
							width: "55px",
							height: "auto", // Ensures the aspect ratio is maintained
							maxHeight: "50px", // Limit the maximum height
							objectFit: "contain",
						}}
					/>
					<div
						style={{
							fontSize: designManager.getFieldValue("primaryThemeFontSize"),
							color: designManager.getFieldValue("loyaltyCardTextColor"),
							zIndex: 34,
							marginLeft: "3px",
							height: "18px",
							fontFamily: designManager.getFieldValue("primaryThemeFont"),
						}}
					>
						John Doe
					</div>
				</div>

				<div
					style={{
						width: "92%",
						marginTop: "5px", // Adjusted for spacing
						display: "flex",
						flexDirection: "column",
						alignItems: "center", // Center vertically
						backgroundColor: "#ffffff", // White background behind barcode + number
						borderRadius: "8px",
						padding: "4px 2px", // Reduced padding to make it shorter
						zIndex: 2,
					}}
				>
					<img
						draggable={false}
						src={barcode}
						alt="Barcode"
						style={{
							width: "92%",
							height: "auto",
						}}
					/>
				</div>
				<div
					style={{
						fontSize: designManager.getFieldValue("primaryThemeFontSize") - 2,
						color: designManager.getFieldValue("loyaltyCardTextColor"),
						fontFamily: designManager.getFieldValue("primaryThemeFont"),
						textAlign: "center",
						height: "20px",
						marginBottom: "6px",
						marginTop: "2px",
						//backgroundColor: "red",
					}}
				>
					80837594791
				</div>
			</div>

			<div
				style={{
					marginTop: "20px",
					fontSize: designManager.getFieldValue("primaryThemeFontSize"),
					color: designManager.getFieldValue("primaryFontColor"),
					textAlign: "center",
					fontFamily: designManager.getFieldValue("primaryThemeFont"),
				}}
			>
				How to use my card
			</div>

		</div>
	);
};

export default CardContent;
