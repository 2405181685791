import React from "react";
import {ReactComponent as Icon} from "../resources/box_dialog.svg";
import battery from "../resources/ios-battery-full-3.svg";
import wifi from "../resources/ios-wifi-2.svg";

const OffersContent = ({designManager}) => {
	return (
		<div
			style={{
				width: "100%",
				height: "580px",
				backgroundColor: designManager.getFieldValue("primaryBackgroundColor"),
				color: designManager.getFieldValue("primaryFontColor"),
				fontFamily: designManager.getFieldValue("primaryThemeFont"),
				fontSize: designManager.getFieldValue("primaryThemeFontSize"),
			}}
		>
			<div style={{position: "relative"}}>
				<img
					src={wifi}
					alt="wifi"
					style={{
						width: "18px",
						height: "18px",
						position: "absolute",
						top: "6px",
						right: "55px",
					}}
				/>
				<img
					src={battery}
					alt="battery"
					style={{
						width: "20px",
						height: "20px",
						position: "absolute",
						top: "6px",
						right: "28px",
					}}
				/>
			</div>
			<div
				style={{
					draggable: "false",
					width: "100%",
					height: "68px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					backgroundColor: designManager.getFieldValue(
						"appHeaderBackgroundColor"
					),
					marginTop: "5px",
					zIndex: 2,
				}}
			>
				<div style={{
					fontSize: designManager.getFieldValue("appHeaderFontSize") - 1,
					marginTop: "33px",
					fontFamily: designManager.getFieldValue("appHeaderFont"),
					color: designManager.getFieldValue("appHeaderFontColor"),
				}}>Offers
				</div>
			</div>
			<div
				style={{
					display: "flex", // Flexbox for centering
					flexDirection: "column", // Arrange content vertically
					justifyContent: "center", // Center vertically
					alignItems: "center", // Center horizontally
					marginTop: "70%",
				}}
			>
				<Icon
					style={{
						width: "120px",
						height: "120px",
						marginBottom: "16px",
						marginTop: "-60px",
						color: designManager.getFieldValue("primaryFontColor"), // This sets the color
					}}
				/>

				<div
					style={{
						fontSize: designManager.getFieldValue("primaryThemeFontSize"),
						fontWeight: "bold",
						marginTop: "-20px",
						alignItems: "center", // Vertically center
						justifyContent: "center", // Horizontally center
						fontFamily: designManager.getFieldValue("primaryThemeFont"),
					}}
				>
					No offers yet
				</div>
				<div
					style={{
						fontSize: designManager.getFieldValue("primaryThemeFontSize") - 2,
						fontFamily: designManager.getFieldValue("primaryThemeFont"),
						textAlign: "center", // Vertically center
						display: "flex",
						padding: "5px",
						justifyContent: "center", // Horizontally center
					}}
				>
					Check back later for new offers
				</div>
			</div>
		</div>
	);
};

export default OffersContent;
