import React, {useState} from "react";
import Styles from "../resources/styles";
import ColourBox from "./sharedcomponents/ColourBox";
import TextBox from "./sharedcomponents/TextBox";
import NumberBox from "./sharedcomponents/NumberBox";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import {ApiServiceClass} from "../../api-service";
import {toast} from "react-toastify";
import NewSVG from "./sharedcomponents/NewSVG";

function RewardContent({designManager, onCancel, onSave}) {
	const [saveDisabled, setSaveDisabled] = useState(true);
	const [rewardContent, setRewardContent] = useState({
		rewardSchemeTitle: designManager.getCurrentDesign().rewardSchemeTitle,
		rewardSchemeSubtitle: designManager.getCurrentDesign().rewardSchemeSubtitle,
		rewardSchemePurchasesRequired:
		designManager.getCurrentDesign().rewardSchemePurchasesRequired,
		rewardSchemeTextColor:
		designManager.getCurrentDesign().rewardSchemeTextColor,
		rewardSchemeStampIcon:
		designManager.getCurrentDesign().rewardSchemeStampIcon,
		rewardSchemeStampBackgroundColor:
		designManager.getCurrentDesign().rewardSchemeStampBackgroundColor,
		rewardSchemePanelBackgroundColor:
		designManager.getCurrentDesign().rewardSchemePanelBackgroundColor,
	});

	const handleContentChange = (contentProperty, newProperty) => {
		console.log(`[UNSAVED] Changed '${contentProperty}' to:`, newProperty);
		designManager.setUnsavedChanges({[contentProperty]: newProperty});

		setRewardContent((prevContent) => ({
			...prevContent,
			[contentProperty]: newProperty, // Dynamically update the property
		}));
		setSaveDisabled(false);
	};

	const handleReset = () => {
		console.log("Resetting design");
		designManager.resetUnsavedChanges();

		setRewardContent({
			rewardSchemeTitle: designManager.getCurrentDesign().rewardSchemeTitle,
			rewardSchemeSubtitle:
			designManager.getCurrentDesign().rewardSchemeSubtitle,
			rewardSchemePurchasesRequired:
			designManager.getCurrentDesign().rewardSchemePurchasesRequired,
			rewardSchemeTextColor:
			designManager.getCurrentDesign().rewardSchemeTextColor,
			rewardSchemeStampIcon:
			designManager.getCurrentDesign().rewardSchemeStampIcon,
			rewardSchemeStampBackgroundColor:
			designManager.getCurrentDesign().rewardSchemeStampBackgroundColor,
			rewardSchemePanelBackgroundColor:
			designManager.getCurrentDesign().rewardSchemePanelBackgroundColor,
		});
		setSaveDisabled(true);
	};

	const [stampName, setStampName] = useState("scissors");
	const [stampColor, setStampColor] = useState("#0260fe");

	const handleSave = async () => {
		try {
			if (!designManager.hasUnsavedChanges()) {
				console.log("No changes to save.");
				return;
			}
			const updatedDesign = designManager.getUnsavedDesign();
			const requestBody = {
				CompanyId: designManager.getCompanyId(),
				RequesterUserId: `${designManager.getUserId()}`,
				Name: updatedDesign.rewardSchemeTitle,
				SubTitle: updatedDesign.rewardSchemeSubtitle,
				NumberOfPurchasesRequired: updatedDesign.rewardSchemePurchasesRequired,
				PanelBackgroundColor: updatedDesign.rewardSchemePanelBackgroundColor,
				StampBackgroundColor: updatedDesign.rewardSchemeStampBackgroundColor,
				StampTextColor: updatedDesign.rewardSchemeTextColor,
				StampSVG: updatedDesign.rewardSchemeStampIcon,
			};
			console.log("Calling SaveRewardScheme API:", requestBody);
			setSaveDisabled(true);
			const response = await ApiServiceClass.SaveRewardSchemeTheme(requestBody);
			if (response.status >= 400) {
				throw {
					status: response.status,
					message: `HTTP Error: ${response.status}`,
				};
			}
			designManager.updateDesign(updatedDesign);
			toast.success("Reward Theme Saved", {autoClose: 1500});
		} catch (error) {
			// Consolidated error handling
			const statusCode = error.response?.status || error.status || "Unknown";
			const errorMessage = `Error Saving Reward Theme: ${statusCode}`;

			console.error(errorMessage, error);
			toast.error(errorMessage, {autoClose: 1500});
		}
	};

	return (
		<div style={Styles.panel}>
			<div style={Styles.contentWrapper}>
				<div style={Styles.sectionTitle}>Reward Details</div>
				<div style={Styles.contentGrid}>
					<div style={Styles.gridItemLeft}>
						Title
						<TextBox
							defaultText="Enter title"
							text={rewardContent.rewardSchemeTitle}
							onChange={(text) =>
								handleContentChange("rewardSchemeTitle", text)
							}
						/>
					</div>
					<div style={Styles.gridItemRight}>
						Subtitle
						<TextBox
							defaultText="Enter subtitle"
							text={rewardContent.rewardSchemeSubtitle}
							onChange={(text) =>
								handleContentChange("rewardSchemeSubtitle", text)
							}
						/>
					</div>
					<div style={Styles.gridItemLeft}>
						Purchases Required
						<NumberBox
							defaultValue={rewardContent.rewardSchemePurchasesRequired}
							maxValue={12}
							minValue={1}
							onChange={(text) =>
								handleContentChange("rewardSchemePurchasesRequired", text)
							}
						/>
					</div>
				</div>
				<div style={{...Styles.contentGrid, marginTop: "-4%"}}>
					<div style={Styles.gridItemLeft}>
						Text Colour
						<ColourBox
							initColor={rewardContent.rewardSchemeTextColor} // Access from colors object
							onColorChange={(newColor) =>
								handleContentChange("rewardSchemeTextColor", newColor)
							}
						/>
					</div>
				</div>

				<div style={Styles.sectionTitle}>Stamp Details</div>
				<div style={Styles.contentGrid}>
					<div style={Styles.gridItemLeft}>
						Icon & Colours
						<NewSVG
							initialStamp={rewardContent.rewardSchemeStampIcon}
							onStampChange={(newStamp) =>
								handleContentChange("rewardSchemeStampIcon", newStamp)
							}
						/>
					</div>
				</div>

				<div style={Styles.contentGrid}>
					<div style={Styles.gridItemLeft}>
						Stamp Background Colour
						<ColourBox
							initColor={rewardContent.rewardSchemeStampBackgroundColor} // Access from colors object
							onColorChange={(newColor) =>
								handleContentChange(
									"rewardSchemeStampBackgroundColor",
									newColor
								)
							}
						/>
					</div>
					<div style={Styles.gridItemRight}>
						Panel Background Colour
						<ColourBox
							initColor={rewardContent.rewardSchemePanelBackgroundColor} // Access from colors object
							onColorChange={(newColor) =>
								handleContentChange(
									"rewardSchemePanelBackgroundColor",
									newColor
								)
							}
						/>
					</div>
				</div>
			</div>
			<div style={Styles.actions}>
				<button style={Styles.resetBtn} onClick={handleReset}>
					Reset
					<Tooltip
						title={
							<span style={{display: "block", textAlign: "center"}}>
                This will reset all content to the last saved values
              </span>
						}
						arrow
					>
						<HelpOutlineIcon
							style={{
								marginLeft: "4px",
								fontSize: "17px",
								cursor: "pointer",
							}}
						/>
					</Tooltip>
				</button>
				<button
					style={{
						...Styles.applyBtn,
						backgroundColor: saveDisabled ? "gray" : "#28a745",
						opacity: saveDisabled ? 0.5 : 1,
						cursor: saveDisabled ? "not-allowed" : "pointer",
					}}
					disabled={saveDisabled} // Only disable if no unsaved changes
					onClick={handleSave}
				>
					Save
				</button>
			</div>
		</div>
	);
}

export default RewardContent;
