import React from "react";
import Base64Image from "../cards/sharedcomponents/Base64Image";
import {Box, Button, ListItem, ListItemText} from "@mui/material";
import battery from "../resources/ios-battery-full-3.svg";
import wifi from "../resources/ios-wifi-2.svg";
import {ReactComponent as SwapIcon} from "../resources/swap.svg";

const HomeContent = ({designManager}) => {
	const circleDiameter = 42;
	const itemHW = 20;
	const indent = (circleDiameter - itemHW) / 2;
	const columns = 4; // Number of columns in a row
	const iconsArray = Array.from(
		{length: designManager.getFieldValue("rewardSchemePurchasesRequired")},
		(_, index) => index
	);

	const listItemStyle = {
		display: "flex",
		justifyContent: "space-between",
		padding: "6px 12px",
		borderBottom: "0.2px solid #000",
		width: "95%",
		margin: "0 auto",
	};

	const firstItemStyle = {
		display: "flex",
		justifyContent: "space-between",
		padding: "4px 4px",
		borderBottom: "0.2px solid #000",
		width: "100%",
		margin: "0 auto",
		borderTop: "none", // Explicitly remove top border
	};

	const secondItemStyle = {
		display: "flex",
		justifyContent: "space-between",
		padding: "4px 4px",
		borderBottom: "0.2px solid #000",
		width: "100%",
		margin: "0 auto",
		borderBottom: "none", // Explicitly remove top border
	};

	const typographyStyle = {
		fontSize: designManager.getFieldValue("primaryThemeFontSize") - 2,
		fontFamily: designManager.getFieldValue("primaryThemeFont"),
		color: designManager.getFieldValue("primaryFontColor"),
		height: "18px"
	};

	const decodeBase64SVG = (base64String) => {
		try {
			console.log("Decoding Stamp Icon:", base64String);
			return atob(base64String);
		} catch (error) {
			console.error("Error decoding base64 string:", error);
			return base64String;
		}
	};

	const StampCircle = React.memo(({circleDiameter}) => {
		// Get the base64 encoded SVG from the design manager
		const base64SVG = designManager.getFieldValue("rewardSchemeStampIcon");
		return (
			<div
				style={{
					position: "relative",
					width: circleDiameter,
					height: circleDiameter,
					draggable: false,
					userSelect: "none",
					display: "flex",
					alignItems: "center", // Centers the icon vertically inside its container
					justifyContent: "center", // Centers the icon horizontally inside its container
				}}
			>
				{/* Background Circle */}
				<svg
					width={circleDiameter}
					height={circleDiameter}
					style={{zIndex: 2}}
				>
					<circle
						cx={circleDiameter / 2}
						cy={circleDiameter / 2}
						r={circleDiameter / 2 - 1}
						fill={designManager.getFieldValue(
							"rewardSchemeStampBackgroundColor"
						)}
						strokeWidth="1"
						strokeDasharray="3,3"
					/>
				</svg>

				{/* Render the Base64 Encoded SVG as an Image */}
				{base64SVG ? (
					<img
						src={`data:image/svg+xml;base64,${base64SVG}`}
						width="76%" // Adjust width and height as needed
						height="76%"
						alt="Stamp Icon"
						draggable={false}
						style={{
							position: "absolute",
							top: "50%", // Move to the center vertically
							left: "50%", // Move to the center horizontally
							transform: "translate(-50%, -50%)", // Adjust to perfectly center the image
							zIndex: 22,
							objectFit: "contain", // Ensures the image fits within the container
							draggable: false,
							userSelect: "none",
						}}
					/>
				) : (
					<span>Your browser does not support SVG.</span> // Fallback content
				)}
			</div>
		);
	});

	return (
		<div
			style={{
				width: "100%",
				height: "580px",
				backgroundColor: designManager.getFieldValue("primaryBackgroundColor"),
				color: designManager.getFieldValue("primaryFontColor"),
				fontFamily: designManager.getFieldValue("primaryThemeFont"),
				fontSize: designManager.getFieldValue("primaryThemeFontSize"),
			}}
		>
			<div style={{position: "relative"}}>
				<img
					src={wifi}
					alt="wifi"
					style={{
						width: "18px",
						height: "18px",
						position: "absolute",
						top: "6px",
						right: "55px",
					}}
				/>
				<img
					src={battery}
					alt="battery"
					style={{
						width: "20px",
						height: "20px",
						position: "absolute",
						top: "6px",
						right: "28px",
					}}
				/>
			</div>

			<div
				style={{
					draggable: "false",
					width: "100%",
					height: "68px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					backgroundColor: designManager.getFieldValue(
						"appHeaderBackgroundColor"
					),
					marginTop: "5px",
					zIndex: 2,
				}}
			>
				<div style={{
					fontSize: designManager.getFieldValue("appHeaderFontSize") - 1,
					marginTop: "33px",
					fontFamily: designManager.getFieldValue("appHeaderFont"),
					color: designManager.getFieldValue("appHeaderFontColor"),
				}}>Good Morning, Demo
				</div>
			</div>
			<div
				style={{
					display: "grid",
					justifyItems: "end",
					alignItems: "start",
					position: "relative",
					marginTop: "5px",
				}}
			>
				<Box
					sx={{
						display: "inline-flex",
						alignItems: "center",
						justifyContent: "center",
						backgroundColor: designManager.getFieldValue(
							"rewardSchemePanelBackgroundColor"
						),
						borderRadius: "20px 0 0 20px", // Rounded on left top and bottom, straight on right
						padding: "8px",
						boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
						width: "35px",
						height: "25px",
					}}
				>
					<SwapIcon style={{
						color: designManager.getFieldValue("rewardSchemeTextColor"),
						width: "18px"
					}}></SwapIcon>
				</Box>
			</div>
			<div
				style={{
					backgroundColor: designManager.getFieldValue(
						"rewardSchemePanelBackgroundColor"
					),
					borderColor: designManager.getFieldValue("borderColor"),
					borderRadius: "5px",
					width: "80%",
					height: "53%",
					margin: "0 auto",
					padding: "5px",
					boxSizing: "border-box",
					marginTop: "15px",
					boxShadow: "0 4px 8px rgba(0, 0, 0, 0.70)", // Add box shadow here
				}}
			>
				<div
					style={{
						boxSizing: "border-box", // Include padding and border in the element's size
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						textAlign: "center",
						marginTop: "2px",
					}}
				>
					<Base64Image
						image={designManager.getFieldValue("companyLogo")}
						fileType={designManager.getFieldValue("companyLogoFileType")}
						style={{
							maxHeight: "65px",
							width: "100%",
							height: "auto", // Ensures the aspect ratio is maintained
							objectFit: "contain", // Ensures the image scales properly within the bounds
							userSelect: "none",
						}}
					/>

					<div
						style={{
							color: designManager.getFieldValue("rewardSchemeTextColor"),
							marginTop: "-5px",
							fontSize: designManager.getFieldValue("primaryThemeFontSize"),
							fontWeight: "bold",
							fontFamily: designManager.getFieldValue("primaryThemeFont"),
							height: "20px",
							//letterSpacing: "px"
							textAlign: "center",
						}}
					>
						{designManager.getFieldValue("rewardSchemeTitle")}
					</div>
					<div
						style={{
							color: designManager.getFieldValue("rewardSchemeTextColor"),
							marginTop: "0px",
							fontSize: designManager.getFieldValue("primaryThemeFontSize") - 1,
							height: "20px",
							fontFamily: designManager.getFieldValue("primaryThemeFont"),
							textAlign: "center",
						}}
					>
						{designManager.getFieldValue("rewardSchemeSubtitle")}
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							marginTop: "10px",
							height: "auto", // Ensures the content stretches to fill the container
						}}
					>
						{iconsArray
							.reduce((rows, _, index) => {
								if (index % columns === 0) rows.push([]);
								rows[rows.length - 1].push(index);
								return rows;
							}, [])
							.map((row, rowIndex) => (
								<div
									key={rowIndex}
									style={{
										display: "flex",
										justifyContent:
											row.length < columns ? "center" : "flex-start",
										alignItems: "center", // Centers items vertically
										gap: "6px", // Adds spacing between items
										marginBottom: "8px", // Adds spacing between rows
									}}
								>
									{row.map((iconIndex) => (
										<StampCircle
											iconIndex={iconIndex}
											circleDiameter={circleDiameter}
										/>
									))}
								</div>
							))}
					</div>
				</div>
			</div>

			<div style={{
				position: "absolute",
				width: "100%",
				left: "10%",
				//backgroundColor :"#000000"
			}}>
				<div
					style={{
						fontFamily: designManager.getFieldValue("primaryThemeFont"),
						color: designManager.getFieldValue("primaryFontColor"),
						fontSize: designManager.getFieldValue("primaryThemeFontSize") - 2,
						fontWeight: "bold",
						zIndex: 6,
						marginTop: "15px",
					}}
				>
					Recent Transactions

					<Button
						style={{
							height: "22px",
							marginLeft: "4%",
							backgroundColor: designManager.getFieldValue(
								"buttonBackgroundColor"
							),
							border: `1px solid ${designManager.getFieldValue(
								"buttonBackgroundColor"
							)}`,
							width: "40px",
							zIndex: 3,
							borderRadius: "10px",
							padding: 0,
						}}
					>
						<div
							style={{
								display: "flex",
								//marginTop: "-1px",
								justifyContent: "center",
								alignItems: "center",
								color: designManager.getFieldValue("buttonFontColor"),
								fontFamily: designManager.getFieldValue("buttonsFont"),
								fontSize: designManager.getFieldValue("buttonsFontSize") - 1
							}}
						>
							View all
						</div>
					</Button>
				</div>
				<div style={{marginTop: "0px"}}>
					<ListItem button style={firstItemStyle}>
						<StampCircle circleDiameter={34}/>
						<ListItemText
							primary={
								<div style={{...typographyStyle, paddingLeft: "8px"}}>
									You gained a stamp!
								</div>
							}
							secondary={
								<div style={{...typographyStyle, paddingLeft: "8px"}}>
									June 15, 2024
								</div>
							}
						/>
					</ListItem>

					{/* Update Personal Information */}
					<ListItem button style={secondItemStyle}>
						<StampCircle circleDiameter={34}/>
						<ListItemText
							primary={
								<div style={{...typographyStyle, paddingLeft: "8px"}}>
									You gained a stamp!
								</div>
							}
							secondary={
								<div style={{...typographyStyle, paddingLeft: "8px"}}>
									June 15, 2024
								</div>
							}
						/>
					</ListItem>
				</div>
			</div>
		</div>
	);
};

export default HomeContent;
